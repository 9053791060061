import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { GuideIntentType } from "pages/Guides/enums";

import { KitchenApplianceConfigFloorPlan } from "./config.floorPlan";
import { KitchenApplianceConfigImages } from "./config.images";
import { KitchenAppliancesConfigTexts } from "./config.texts";
import { KitchenApplianceTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_APPLIANCE,
  KitchenApplianceTypes
>;

const types: Config["types"] = [
  KitchenApplianceTypes.RANGE_OVEN,
  KitchenApplianceTypes.COOKTOP,
  KitchenApplianceTypes.RANGETOP,
  KitchenApplianceTypes.WALL_OVEN,
  KitchenApplianceTypes.KITCHEN_VENT,
  KitchenApplianceTypes.REFRIGERATOR_FREEZER,
  KitchenApplianceTypes.REFRIGERATED_DRAWER,
  KitchenApplianceTypes.FREEZER_DRAWER,
  KitchenApplianceTypes.DISHWASHER,
  KitchenApplianceTypes.WINE_COOLER,
  KitchenApplianceTypes.BEVERAGE_COOLER,
  KitchenApplianceTypes.WARMING_DRAWER,
  KitchenApplianceTypes.TRASH_COMPACTOR,
  KitchenApplianceTypes.ICE_MACHINE,
  KitchenApplianceTypes.MICROWAVE,
];

export const KitchenApplianceConfig: Config = {
  guideType: GuideType.KITCHEN_SPECIFIC,
  category: GuideItemTypeCategory.KITCHEN_APPLIANCE,
  types,
  typesOrder: getOrderedMap(types),
  texts: KitchenAppliancesConfigTexts,
  images: KitchenApplianceConfigImages,
  floorPlan: KitchenApplianceConfigFloorPlan,
  allowedInRooms: ["kitchen"],
  allowedIntents: [GuideIntentType.ADD],
} as const;
