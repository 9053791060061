import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HBQueryClient } from "api/HBQueryClient";
import { useResetRouterScroll } from "common/hooks/useResetRouterScroll";
import { useWindowsInnerHeight } from "common/hooks/useWindowsInnerHeight";
import { isTouchDevice } from "common/utils/isTouchDevice";
import { SentryReplayDevtools } from "components/SentryReplayController/SentryReplayControllerLazy";
import { IS_STAGING, SENTRY_REPLAY_DISABLED } from "constants/env";
import { GlobalStateProvider } from "core/state/global/GlobalStateProvider";
import { TelemetryProvider, useNavigationTelemetry } from "core/telemetry";
import { useEmbededPartnerConfig } from "integration/hooks/useEmbededPartnerConfig";
import { getEmbeddedGuidePartnerName } from "integration/TryItOut.embeded";
import { FC, PropsWithChildren } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { HelmetProvider } from "react-helmet-async";
import { useDidMount } from "rooks";

import { DefaultThemeProvider } from "./DefaultThemeProvider";
import { GlobalStateWrapper } from "./GlobalStateWrapper";
import { useIOSMeta } from "./hooks/useIOSMeta";

export const AppProviders: FC<PropsWithChildren> = ({ children }) => {
  useWindowsInnerHeight();
  useNavigationTelemetry();
  useResetRouterScroll();
  useIOSMeta();

  const dnBackend = isTouchDevice() ? TouchBackend : HTML5Backend;
  const embededConfig = useEmbededPartnerConfig(
    getEmbeddedGuidePartnerName(false)
  );
  useDidMount(() => {
    if (embededConfig?.theme) {
      document.body.classList.add(embededConfig.theme);
    }
  });

  return (
    <TelemetryProvider>
      <DefaultThemeProvider>
        <HelmetProvider>
          <QueryClientProvider client={HBQueryClient}>
            {process.env.NODE_ENV !== "production" && (
              <ReactQueryDevtools initialIsOpen={false} />
            )}
            {IS_STAGING && !SENTRY_REPLAY_DISABLED && <SentryReplayDevtools />}
            <DndProvider backend={dnBackend}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <GlobalStateProvider>
                  <GlobalStateWrapper>{children}</GlobalStateWrapper>
                </GlobalStateProvider>
              </LocalizationProvider>
            </DndProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </DefaultThemeProvider>
    </TelemetryProvider>
  );
};
