import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { KitchenCabinetTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_CABINET,
  KitchenCabinetTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [KitchenCabinetTypes.BASE_CABINET]: t("Base Cabinet"),
  [KitchenCabinetTypes.WALL_CABINET]: t("Wall Cabinet"),
  [KitchenCabinetTypes.TALL_PANTRY_CABINET]: t("Tall Pantry Cabinet"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [KitchenCabinetTypes.BASE_CABINET]: t("Base Cabinet"),
  [KitchenCabinetTypes.WALL_CABINET]: t("Wall Cabinet"),
  [KitchenCabinetTypes.TALL_PANTRY_CABINET]: t("Tall Pantry Cabinet"),
});

export const KitchenCabinetConfigTexts: Config = {
  categoryName: (t) => t("Kitchen Cabinets"),
  itemName: (t) => t("Kitchen Cabinet"),
  categoryDescription: (t) =>
    t(
      "Kitchen cabinets are the backbone of any kitchen, shaping both its style and functionality. They’re not just storage—they set the tone for the entire space, influencing how it looks, feels, and operates. No matter how stunning your countertops or appliances are, the kitchen won’t come together without cabinets that provide the right balance of design, organization, and ease of use."
    ),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
