import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { updateIntegrationResultState } from "api/homebase.api";
import { IntegrationResult } from "api/models/IntegrationResult";
import { PartnerLocationMember } from "api/models/PartnerLocationMember";
import { VENDOR_QUERIES } from "pages/Vendor/consts/VendorQueries.const";

type Variables = {
  data: IntegrationResult["state"];
  xEpoch: string | number;
};

export const createAssignedMap = (
  data: IntegrationResult["state"],
  hipsId: string,
  roomId: string,
  user: PartnerLocationMember
) => {
  return {
    ...data.assignedMap,
    [hipsId]: {
      ...data.assignedMap?.[hipsId],
      [roomId]: user,
    },
  };
};

export const getAssignedMapUser = (
  data: IntegrationResult["state"],
  hipsId: string,
  roomId: string
) => {
  return data.assignedMap?.[hipsId]?.[roomId];
};

export const useUpdateIntegrationResultState = (
  id: string | number,
  options: Omit<
    UseMutationOptions<string, Error, Variables, unknown>,
    "mutationFn"
  > = {}
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...rest } = options;
  return useMutation({
    mutationFn: (variables: Variables) => {
      return updateIntegrationResultState(id, variables.data, variables.xEpoch);
    },
    onSuccess: (data, variables: Variables, context) => {
      queryClient.setQueryData(
        [VENDOR_QUERIES.INTEGRATION_RESULT_BY_ID, Number(id)],
        (oldData: IntegrationResult) => {
          if (!oldData) {
            return oldData;
          }
          return {
            ...oldData,
            state: variables.data,
            state_epoch: Number(data),
          } as IntegrationResult;
        }
      );
      queryClient.invalidateQueries({
        queryKey: [VENDOR_QUERIES.INTEGRATION_RESULTS],
      });
      onSuccess?.(data, variables, context);
    },
    ...rest,
  });
};
