import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { KitchenApplianceTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_APPLIANCE,
  KitchenApplianceTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [KitchenApplianceTypes.RANGE_OVEN]: t("Range Oven"),
  [KitchenApplianceTypes.COOKTOP]: t("Cooktop"),
  [KitchenApplianceTypes.RANGETOP]: t("Rangetop"),
  [KitchenApplianceTypes.WALL_OVEN]: t("Wall Oven"),
  [KitchenApplianceTypes.KITCHEN_VENT]: t("Kitchen Vent"),
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: t("Refrigerator Freezer"),
  [KitchenApplianceTypes.REFRIGERATED_DRAWER]: t("Refrigerated Drawer"),
  [KitchenApplianceTypes.FREEZER_DRAWER]: t("Freezer Drawer"),
  [KitchenApplianceTypes.DISHWASHER]: t("Dishwasher"),
  [KitchenApplianceTypes.WINE_COOLER]: t("Wine Cooler"),
  [KitchenApplianceTypes.BEVERAGE_COOLER]: t("Beverage Cooler"),
  [KitchenApplianceTypes.WARMING_DRAWER]: t("Warming Drawer"),
  [KitchenApplianceTypes.TRASH_COMPACTOR]: t("Trash Compactor"),
  [KitchenApplianceTypes.ICE_MACHINE]: t("Ice Machine"),
  [KitchenApplianceTypes.MICROWAVE]: t("Microwave"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [KitchenApplianceTypes.RANGE_OVEN]: t("Range Oven"),
  [KitchenApplianceTypes.COOKTOP]: t("Cooktop"),
  [KitchenApplianceTypes.RANGETOP]: t("Rangetop"),
  [KitchenApplianceTypes.WALL_OVEN]: t("Wall Oven"),
  [KitchenApplianceTypes.KITCHEN_VENT]: t("Kitchen Vent"),
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: t("Refrigerator Freezer"),
  [KitchenApplianceTypes.REFRIGERATED_DRAWER]: t("Refrigerated Drawer"),
  [KitchenApplianceTypes.FREEZER_DRAWER]: t("Freezer Drawer"),
  [KitchenApplianceTypes.DISHWASHER]: t("Dishwasher"),
  [KitchenApplianceTypes.WINE_COOLER]: t("Wine Cooler"),
  [KitchenApplianceTypes.BEVERAGE_COOLER]: t("Beverage Cooler"),
  [KitchenApplianceTypes.WARMING_DRAWER]: t("Warming Drawer"),
  [KitchenApplianceTypes.TRASH_COMPACTOR]: t("Trash Compactor"),
  [KitchenApplianceTypes.ICE_MACHINE]: t("Ice Machine"),
  [KitchenApplianceTypes.MICROWAVE]: t("Microwave"),
});

const typeDescriptions: Config["typeDescriptions"] = (t) => ({
  [KitchenApplianceTypes.RANGE_OVEN]: t(
    "A range oven is a standalone appliance that combines a cooktop and an oven in one unit, offering a space-efficient cooking solution. It comes in gas, electric, or dual-fuel options, with various sizes, burner configurations, and cooking features to suit different needs."
  ),
  [KitchenApplianceTypes.COOKTOP]: t(
    "A cooktop is a sleek, built-in cooking surface that sits flush with the countertop, featuring gas, electric, or induction burners. It offers a streamlined look with controls on the surface, maximizing cabinet space below."
  ),
  [KitchenApplianceTypes.RANGETOP]: t(
    "A rangetop is a high-performance cooking appliance that installs into a countertop, offering multiple burners and optional features like griddles or grills. It provides the power and control of a professional range without the oven beneath."
  ),
  [KitchenApplianceTypes.WALL_OVEN]: t(
    "A built-in oven installed within cabinetry, offering a sleek, space-saving design without a cooktop. Available in single or double configurations, it provides advanced baking and roasting features with precise temperature control."
  ),
  [KitchenApplianceTypes.KITCHEN_VENT]: t(
    "An kitchen vent helps regulate heat and moisture by allowing air to escape, preventing condensation and improving cooking performance. Proper venting also reduces indoor air pollutants, minimizing smoke, odors, and excess humidity for a healthier kitchen environment."
  ),
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: t(
    "A refrigerator freezer is a combined appliance that stores fresh and frozen food in separate compartments, using cooling and freezing technology to preserve food quality. It comes in various configurations, each designed for different storage needs and kitchen layouts."
  ),
  [KitchenApplianceTypes.REFRIGERATED_DRAWER]: t(
    "A refrigerated drawer is a built-in cooling unit that provides easy-access, pull-out storage for perishable items. It offers adjustable temperature settings, making it ideal for fresh produce, beverages, or meal prep ingredients while blending seamlessly into cabinetry."
  ),
  [KitchenApplianceTypes.FREEZER_DRAWER]: t(
    "A freezer drawer is a standalone pull-out freezer unit that offers convenient access and organized storage, making it easy to sort and retrieve frozen items."
  ),
  [KitchenApplianceTypes.DISHWASHER]: t(
    "A dishwasher is an appliance designed to automate dish cleaning by using water jets, detergent, and heat to remove food residue. Available in built-in, portable, and drawer-style models, it offers multiple wash cycles, drying options, and energy-efficient features."
  ),
  [KitchenApplianceTypes.WINE_COOLER]: t(
    "A wine cooler is a temperature-controlled appliance designed to store and preserve wine at optimal conditions, maintaining proper humidity and preventing temperature fluctuations. It often includes multiple cooling zones for different wine types."
  ),
  [KitchenApplianceTypes.BEVERAGE_COOLER]: t(
    "A beverage cooler is a temperature-controlled appliance designed to chill and store drinks at optimal conditions, keeping them refreshingly cold and ready to serve. It often includes adjustable shelving and multiple cooling zones for different beverages."
  ),
  [KitchenApplianceTypes.WARMING_DRAWER]: t(
    "A warming drawer is a built-in appliance designed to keep food warm without overcooking, maintaining ideal serving temperatures. It often features adjustable heat settings, moisture control, and a sleek design that integrates seamlessly into kitchen cabinetry."
  ),
  [KitchenApplianceTypes.TRASH_COMPACTOR]: t(
    "A kitchen appliance that compresses waste into a smaller volume, reducing the need for frequent trash disposal. It helps minimize household waste and can handle general kitchen refuse with ease."
  ),
  [KitchenApplianceTypes.ICE_MACHINE]: t(
    "An ice machine is a dedicated appliance that produces a continuous supply of ice, ideal for kitchens, bars, or entertaining spaces."
  ),
  [KitchenApplianceTypes.MICROWAVE]: t(
    "A microwave is a kitchen appliance that uses electromagnetic waves to quickly heat, cook, and defrost food. It offers convenience with preset cooking functions, variable power levels, and sensor-based heating for even results."
  ),
});

export const KitchenAppliancesConfigTexts: Config = {
  categoryName: (t) => t("Kitchen Appliances"),
  itemName: (t) => t("Kitchen Appliance"),
  categoryDescription: (t) =>
    t(
      "Kitchen appliances are a key part of any kitchen setup, yet they’re often overshadowed by choices in countertops and cabinetry. No matter how great your kitchen design looks, it won’t function effectively until you choose appliances that fit the space and support your cooking needs."
    ),
  itemNames,
  typeNames,
  typeDescriptions,
};
