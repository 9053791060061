import { CoverPhotos } from "api/models/CoverPhotos";
import { PinnedPhotos } from "api/models/PinnedPhotos";
import { Property } from "api/models/Property";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemCompositeKey } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { PresetGroupId } from "constants/presets/PresetGroup.types";
import { PresetGroupItemId } from "constants/presets/PresetGroupItem.types";
import { SubCategory } from "constants/subcategories/subcategory.types";
import { useStableNavigate } from "core/state/hooks/useStableNavigate";
import {
  GuideItemsPrices,
  GuideLaborPrices,
} from "pages/Guides/budget/budget.types";
import { GuideIntentType } from "pages/Guides/enums";
import { InspirationUploadMapping } from "pages/Guides/tabs/GuideInspireTab/InspirationPanel/types";
import { BaseActiveGuideContext, GuideIntents } from "pages/Guides/types";
import {
  BaseImage,
  PropertyCardImage,
  PropertyCardImagesMappingPro,
} from "pages/Media/types";
import { RoomAndSpace } from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { TFunction } from "react-i18next";
import { EventObject } from "xstate/lib/types";

import { CONFIRMATION_WARNING_IDS } from "./confirmationWarning/consts";
import { GetEducatedContext } from "./getEducated/GetEducated.models";
import { IHIPsDashboardMachineContext } from "./hips/dashboard/models";

export interface OrchestratorEvent<T> extends EventObject {
  data: T;
}

interface OrchestratorGuideDoneHookParams {
  ctx: OrchestratorPropertyContext;
  event: OrchestratorEvent<OrchestratorGuideDoneEventData>;
  t: TFunction;
  navigate: ReturnType<typeof useStableNavigate>;
}

export interface OrchestratorGuideDoneCustomData {
  hooks: {
    before?: (params: OrchestratorGuideDoneHookParams) => void;
    navigate?: (params: OrchestratorGuideDoneHookParams) => void;
    after?: (params: OrchestratorGuideDoneHookParams) => void;
  };
}

export interface OrchestratorGuideDoneEventData {
  originalRoomId: string;
  hipId: string;
  result: BaseActiveGuideContext;
  custom?: OrchestratorGuideDoneCustomData;
}

export interface OrchestratorHIPSOnboardingContext {
  title?: string;
  budget?: number;
  reserve?: number;
  people: unknown[];
}

type OrchestratorHIPSDashboardContext = IHIPsDashboardMachineContext;

interface OrchestratorHIPsPlanningAreasContext {
  rooms: RoomAndSpace[];
}

export interface OrchestratorGuide {
  intents: GuideIntents;
  type: GuideType;
  itemType: GuideItemTypeCategory;
  subCategory: SubCategory;
  roomId: RoomAndSpace["id"];
  preset?: {
    presetId: PresetGroupId;
    presetItemId: PresetGroupItemId;
  };
}

export enum OrchestratorSOWGuideStatus {
  DONE = "DONE",
}

export interface OrchestratorSOWGuide extends OrchestratorGuide {
  id: string;
  completedIntents?: GuideIntentType[];
  status?: OrchestratorSOWGuideStatus;
}

interface OrchestratorHIPsScopeOfWorkContext {
  guides: OrchestratorSOWGuide[];
  fakeGuides: OrchestratorSOWGuide[];
}

type HIPsNotesOutput = Partial<
  Record<GuideItemTypeCategory, Partial<Record<GuideItemCompositeKey, string>>>
>;

interface OrchestratorHIPsOutput {
  notes: HIPsNotesOutput;
}

export interface OrchestratorHIPSContext {
  id: string;
  onboarding: OrchestratorHIPSOnboardingContext;
  dashboard: OrchestratorHIPSDashboardContext;
  planningAreas: OrchestratorHIPsPlanningAreasContext;
  scopeOfWork: OrchestratorHIPsScopeOfWorkContext;
  output: OrchestratorHIPsOutput;
  coverPhoto: BaseImage;
}

export interface OrchestratorMediaContext {
  propertyCardImage: PropertyCardImage;
  propertyCardImagesMappingPro: PropertyCardImagesMappingPro;
  coverPhotos: CoverPhotos;
  pinnedPhotos: PinnedPhotos;
}

interface OrchestratorInspirationContext {
  savedInspiration: InspirationUploadMapping[];
}

export interface OrchestratorPrices {
  labor: GuideLaborPrices;
  items: GuideItemsPrices;
}

export interface OrchestratorPropertyContext {
  property: null | Property;
  rooms: RoomAndSpace[];
  hips: OrchestratorHIPSContext[];
  media: OrchestratorMediaContext;
  activeGuides: BaseActiveGuideContext[];
  prices: OrchestratorPrices;
  inspiration: OrchestratorInspirationContext;
}

export type EphemeralSidebarType =
  | "help"
  | "bug"
  | "suggestion"
  | "subscribe"
  | null;

interface Ephemeral {
  sidebar: EphemeralSidebarType;
  isAnyModalOpen: boolean;
}

export interface OrchestratorStateContext {
  activePropertyId: Property["id"];
  getEducated: GetEducatedContext;
  hasSeenHipTour: boolean | undefined;
  hasSeenMediaHelperText: boolean | undefined;
  hasMyAlbumsSidebarOpened: boolean | undefined;
  hasSeenGuideCTA: boolean | undefined;
  hasFinishedOnboarding: boolean | undefined;
  hasSeenConfirmationWarning?: Partial<
    Record<keyof typeof CONFIRMATION_WARNING_IDS, boolean>
  >;
  isProOnboarding?: boolean | undefined;
  isMeasurementsShown?: boolean | undefined;
  isGuideBudgetBlockOpen?: boolean | undefined;
}

export interface OrchestratorMachineContext {
  properties: OrchestratorPropertyContext[];
  state: OrchestratorStateContext;
  ephemeral?: Ephemeral;
}

export interface OrchestratorSetActivePropertyEventData {
  propertyId: Property["id"];
}
