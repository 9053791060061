export enum GuideItemTypeCategory {
  ELECTRICAL_PANEL = "electrical_panel",
  LIGHTING_CONTROL = "lighting_control",
  LIGHTING_FIXTURE = "lighting_fixture",
  OUTLET = "outlet",
  CEILING_FAN = "ceiling_fan",
  CEILING_FAN_CONTROL = "ceiling_fan_control",
  INTERIOR_FLOOR_MATERIAL = "interior_floor_material",
  SMOKE_ALARM = "smoke_alarm",
  SPLIT_SYSTEM = "split_system",
  SPLIT_SYSTEM_EXTERIOR_UNIT = "split_system_exterior_unit",
  SPLIT_SYSTEM_INTERIOR_UNIT = "split_system_interior_unit",
  PACKAGED_SYSTEM = "packaged_system",
  MINISPLIT_SYSTEM = "minisplit_system",
  FURNACE = "furnace",
  BOILER = "boiler",
  WALL_MATERIAL = "wall",
  SHAPES = "shapes",
  WINDOWS = "windows",
  DOOR = "door",
  KITCHEN_LAYOUT = "kitchen_layout",
  KITCHEN_APPLIANCE = "kitchen_appliance",
  KITCHEN_FIXTURE = "kitchen_fixture",
  DOOR_CASING_TRIM = "door_casing_trim",
  ADJUSTMENTS = "adjustments",
  ROOM = "room",
  KITCHEN_CABINET = "kitchen_cabinet",
}
