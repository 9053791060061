import { ReactComponent as KitchenFixturesIcon } from "assets/icons/categories/kitchen_fixtures.svg";
import kitchen_fixturesFallback from "assets/images/categories/kitchen_fixtures.png";
import kitchen_fixtures from "assets/images/categories/kitchen_fixtures.webp";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import kitchenFaucetFallback from "./assets/kitchenFaucet.png";
import kitchenFaucet from "./assets/kitchenFaucet.webp";
import kitchenSinkFallback from "./assets/kitchenSink.png";
import kitchenSink from "./assets/kitchenSink.webp";
import kitchenWasteDisposalFallback from "./assets/kitchenWasteDisposal.png";
import kitchenWasteDisposal from "./assets/kitchenWasteDisposal.webp";
import kitchenWaterFilterFallback from "./assets/kitchenWaterFilter.png";
import kitchenWaterFilter from "./assets/kitchenWaterFilter.webp";
import { KitchenFixtureTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_FIXTURE,
  KitchenFixtureTypes
>["images"];

const icons: Config["icons"] = {
  [KitchenFixtureTypes.KITCHEN_SINK]: KitchenFixturesIcon,
  [KitchenFixtureTypes.KITCHEN_FAUCET]: KitchenFixturesIcon,
  [KitchenFixtureTypes.KITCHEN_WATER_FILTER]: KitchenFixturesIcon,
  [KitchenFixtureTypes.KITCHEN_WASTE_DISPOSAL]: KitchenFixturesIcon,
};

const images: Config["images"] = {
  [KitchenFixtureTypes.KITCHEN_SINK]: {
    src: kitchenSink,
    fallbackSrc: kitchenSinkFallback,
  },
  [KitchenFixtureTypes.KITCHEN_FAUCET]: {
    src: kitchenFaucet,
    fallbackSrc: kitchenFaucetFallback,
  },
  [KitchenFixtureTypes.KITCHEN_WATER_FILTER]: {
    src: kitchenWaterFilter,
    fallbackSrc: kitchenWaterFilterFallback,
  },
  [KitchenFixtureTypes.KITCHEN_WASTE_DISPOSAL]: {
    src: kitchenWasteDisposal,
    fallbackSrc: kitchenWasteDisposalFallback,
  },
};

export const KitchenFixtureConfigImages: Config = {
  categoryIcon: KitchenFixturesIcon,
  images,
  icons,
  defaultIcon: KitchenFixturesIcon,
  defaultImage: {
    src: kitchen_fixtures,
    fallbackSrc: kitchen_fixturesFallback,
  },
};
