import {
  EMBEDDED_GUIDE_PARTNER,
  getEmbeddedGuidePartnerName,
  getEmbededPartnerConfig,
} from "integration/TryItOut.embeded";
import { useMemo } from "react";

export const useEmbededPartnerConfig = (_partner?: EMBEDDED_GUIDE_PARTNER) => {
  const partner =
    _partner === undefined ? getEmbeddedGuidePartnerName() : _partner;
  const config = useMemo(() => getEmbededPartnerConfig(partner), [partner]);
  return config;
};
