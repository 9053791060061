import appliancesFallback from "assets/images/categories/appliances.png";
import appliances from "assets/images/categories/appliances.webp";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import beverageCoolerApplianceFallback from "./assets/beverageCoolerAppliance.png";
import beverageCoolerAppliance from "./assets/beverageCoolerAppliance.webp";
import cooktopApplianceFallback from "./assets/cooktopAppliance.png";
import cooktopAppliance from "./assets/cooktopAppliance.webp";
import dishwasherApplianceFallback from "./assets/dishwasherAppliance.png";
import dishwasherAppliance from "./assets/dishwasherAppliance.webp";
import freezerDrawerApplianceFallback from "./assets/freezerDrawerAppliance.png";
import freezerDrawerAppliance from "./assets/freezerDrawerAppliance.webp";
import iceMachineApplianceFallback from "./assets/iceMachineAppliance.png";
import iceMachineAppliance from "./assets/iceMachineAppliance.webp";
import { ReactComponent as KitchenApplianceIcon } from "./assets/kitchen_appliance_icon.svg";
import kitchenVentApplianceFallback from "./assets/kitchenVentAppliance.png";
import kitchenVentAppliance from "./assets/kitchenVentAppliance.webp";
import microwaveApplianceFallback from "./assets/microwaveAppliance.png";
import microwaveAppliance from "./assets/microwaveAppliance.webp";
import rangeOvenApplianceFallback from "./assets/rangeOvenAppliance.png";
import rangeOvenAppliance from "./assets/rangeOvenAppliance.webp";
import rangetopApplianceFallback from "./assets/rangetopAppliance.png";
import rangetopAppliance from "./assets/rangetopAppliance.webp";
import refrigeratorDrawerApplianceFallback from "./assets/refrigeratorDrawerAppliance.png";
import refrigeratorDrawerAppliance from "./assets/refrigeratorDrawerAppliance.webp";
import refrigeratorFreezerApplianceFallback from "./assets/refrigeratorFreezerAppliance.png";
import refrigeratorFreezerAppliance from "./assets/refrigeratorFreezerAppliance.webp";
import trashCompactorApplianceFallback from "./assets/trashCompactorAppliance.png";
import trashCompactorAppliance from "./assets/trashCompactorAppliance.webp";
import wallOvenApplianceFallback from "./assets/wallOvenAppliance.png";
import wallOvenAppliance from "./assets/wallOvenAppliance.webp";
import warmingDrawerApplianceFallback from "./assets/warmingDrawerAppliance.png";
import warmingDrawerAppliance from "./assets/warmingDrawerAppliance.webp";
import wineCoolerApplianceFallback from "./assets/wineCoolerAppliance.png";
import wineCoolerAppliance from "./assets/wineCoolerAppliance.webp";
import { KitchenApplianceTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_APPLIANCE,
  KitchenApplianceTypes
>["images"];

const icons: Config["icons"] = {
  [KitchenApplianceTypes.RANGE_OVEN]: KitchenApplianceIcon,
  [KitchenApplianceTypes.COOKTOP]: KitchenApplianceIcon,
  [KitchenApplianceTypes.RANGETOP]: KitchenApplianceIcon,
  [KitchenApplianceTypes.WALL_OVEN]: KitchenApplianceIcon,
  [KitchenApplianceTypes.KITCHEN_VENT]: KitchenApplianceIcon,
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: KitchenApplianceIcon,
  [KitchenApplianceTypes.REFRIGERATED_DRAWER]: KitchenApplianceIcon,
  [KitchenApplianceTypes.FREEZER_DRAWER]: KitchenApplianceIcon,
  [KitchenApplianceTypes.DISHWASHER]: KitchenApplianceIcon,
  [KitchenApplianceTypes.WINE_COOLER]: KitchenApplianceIcon,
  [KitchenApplianceTypes.BEVERAGE_COOLER]: KitchenApplianceIcon,
  [KitchenApplianceTypes.WARMING_DRAWER]: KitchenApplianceIcon,
  [KitchenApplianceTypes.TRASH_COMPACTOR]: KitchenApplianceIcon,
  [KitchenApplianceTypes.ICE_MACHINE]: KitchenApplianceIcon,
  [KitchenApplianceTypes.MICROWAVE]: KitchenApplianceIcon,
};

const images: Config["images"] = {
  [KitchenApplianceTypes.RANGE_OVEN]: {
    src: rangeOvenAppliance,
    fallbackSrc: rangeOvenApplianceFallback,
  },
  [KitchenApplianceTypes.COOKTOP]: {
    src: cooktopAppliance,
    fallbackSrc: cooktopApplianceFallback,
  },
  [KitchenApplianceTypes.RANGETOP]: {
    src: rangetopAppliance,
    fallbackSrc: rangetopApplianceFallback,
  },
  [KitchenApplianceTypes.WALL_OVEN]: {
    src: wallOvenAppliance,
    fallbackSrc: wallOvenApplianceFallback,
  },
  [KitchenApplianceTypes.KITCHEN_VENT]: {
    src: kitchenVentAppliance,
    fallbackSrc: kitchenVentApplianceFallback,
  },
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: {
    src: refrigeratorFreezerAppliance,
    fallbackSrc: refrigeratorFreezerApplianceFallback,
  },
  [KitchenApplianceTypes.REFRIGERATED_DRAWER]: {
    src: refrigeratorDrawerAppliance,
    fallbackSrc: refrigeratorDrawerApplianceFallback,
  },
  [KitchenApplianceTypes.DISHWASHER]: {
    src: dishwasherAppliance,
    fallbackSrc: dishwasherApplianceFallback,
  },
  [KitchenApplianceTypes.WINE_COOLER]: {
    src: wineCoolerAppliance,
    fallbackSrc: wineCoolerApplianceFallback,
  },
  [KitchenApplianceTypes.BEVERAGE_COOLER]: {
    src: beverageCoolerAppliance,
    fallbackSrc: beverageCoolerApplianceFallback,
  },
  [KitchenApplianceTypes.WARMING_DRAWER]: {
    src: warmingDrawerAppliance,
    fallbackSrc: warmingDrawerApplianceFallback,
  },
  [KitchenApplianceTypes.TRASH_COMPACTOR]: {
    src: trashCompactorAppliance,
    fallbackSrc: trashCompactorApplianceFallback,
  },
  [KitchenApplianceTypes.ICE_MACHINE]: {
    src: iceMachineAppliance,
    fallbackSrc: iceMachineApplianceFallback,
  },
  [KitchenApplianceTypes.MICROWAVE]: {
    src: microwaveAppliance,
    fallbackSrc: microwaveApplianceFallback,
  },
  [KitchenApplianceTypes.FREEZER_DRAWER]: {
    src: freezerDrawerAppliance,
    fallbackSrc: freezerDrawerApplianceFallback,
  },
};

export const KitchenApplianceConfigImages: Config = {
  categoryIcon: KitchenApplianceIcon,
  images,
  icons,
  defaultIcon: KitchenApplianceIcon,
  defaultImage: {
    src: appliances,
    fallbackSrc: appliancesFallback,
  },
};
