import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import baseKitchenCabinetFallback from "./assets/baseKitchenCabinet.png";
import baseKitchenCabinet from "./assets/baseKitchenCabinet.webp";
import { ReactComponent as KitchenCabinetIcon } from "./assets/kitchen_cabinet_icon.svg";
import tallPantryKitchenCabinetFallback from "./assets/tallPantryKitchenCabinet.png";
import tallPantryKitchenCabinet from "./assets/tallPantryKitchenCabinet.webp";
import wallKitchenCabinetFallback from "./assets/wallKitchenCabinet.png";
import wallKitchenCabinet from "./assets/wallKitchenCabinet.webp";
import { KitchenCabinetTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_CABINET,
  KitchenCabinetTypes
>["images"];

const icons: Config["icons"] = {
  [KitchenCabinetTypes.BASE_CABINET]: KitchenCabinetIcon,
  [KitchenCabinetTypes.WALL_CABINET]: KitchenCabinetIcon,
  [KitchenCabinetTypes.TALL_PANTRY_CABINET]: KitchenCabinetIcon,
};

const images: Config["images"] = {
  [KitchenCabinetTypes.BASE_CABINET]: {
    src: baseKitchenCabinet,
    fallbackSrc: baseKitchenCabinetFallback,
  },
  [KitchenCabinetTypes.WALL_CABINET]: {
    src: wallKitchenCabinet,
    fallbackSrc: wallKitchenCabinetFallback,
  },
  [KitchenCabinetTypes.TALL_PANTRY_CABINET]: {
    src: tallPantryKitchenCabinet,
    fallbackSrc: tallPantryKitchenCabinetFallback,
  },
};

export const KitchenCabinetConfigImages: Config = {
  categoryIcon: KitchenCabinetIcon,
  images,
  icons,
  defaultIcon: KitchenCabinetIcon,
  defaultImage: {
    src: baseKitchenCabinet,
    fallbackSrc: baseKitchenCabinetFallback,
  },
};
