import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";

import { KitchenFixtureTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_FIXTURE,
  KitchenFixtureTypes
>["texts"];

const itemNames: Config["itemNames"] = (t) => ({
  [KitchenFixtureTypes.KITCHEN_SINK]: t("Sink"),
  [KitchenFixtureTypes.KITCHEN_FAUCET]: t("Faucet"),
  [KitchenFixtureTypes.KITCHEN_WATER_FILTER]: t("Water Filter"),
  [KitchenFixtureTypes.KITCHEN_WASTE_DISPOSAL]: t("Waste Disposal"),
});

const typeNames: Config["typeNames"] = (t) => ({
  [KitchenFixtureTypes.KITCHEN_SINK]: t("Sink"),
  [KitchenFixtureTypes.KITCHEN_FAUCET]: t("Faucet"),
  [KitchenFixtureTypes.KITCHEN_WATER_FILTER]: t("Water Filter"),
  [KitchenFixtureTypes.KITCHEN_WASTE_DISPOSAL]: t("Waste Disposal"),
});

export const KitchenFixturesConfigTexts: Config = {
  categoryName: (t) => t("Kitchen Fixtures"),
  itemName: (t) => t("Kitchen Fixture"),
  categoryDescription: (t) =>
    t(
      "Kitchen fixtures are essential to both function and convenience, yet they’re often overlooked in favor of cabinets and countertops. The right choices make daily tasks easier, improving water flow, cleanup, and overall efficiency. Prioritizing durability, ease of use, and style ensures your kitchen works as well as it looks."
    ),
  itemNames,
  typeNames,
  typeDescriptions: undefined,
};
