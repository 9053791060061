import { assign } from "@xstate/immer";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  AbstractGuideItem,
  GuideItem,
} from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { SubCategory } from "constants/subcategories/subcategory.types";
import { getActivePropertyContext } from "core/state/global/OrchestratorMachine/actions.utils";
import {
  AddPresetGroupEventData,
  GuideSOWRoomEventData,
  MultipleGuideSOWRoomEventData,
  RemoveAllTasksEventData,
  RemoveCategoryGuideEventData,
  RemoveIntentFromSOWEventData,
  RemoveRoomFromPlanningAreaEventData,
  StartOverPresetGroupEventData,
} from "core/state/global/OrchestratorMachine/hips/Models";
import {
  OrchestratorEvent,
  OrchestratorMachineContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { find, uniq } from "lodash-es";
import { GUIDE_INTENTS_ORDER } from "pages/Guides/consts";
import { GuideIntentType } from "pages/Guides/enums";
import { GuideIntents, GuideResults } from "pages/Guides/types";
import { disconnectFromAll } from "pages/Guides/utils/utils.connections";
import { HIPsRoomRouteParams } from "router/models";
import { findBy } from "shared/util/findBy";
import { removeFromArray } from "shared/util/removeFromArray";
import { uuid } from "shared/util/uuid";

import {
  addFakeGuide,
  addSOWGuide,
  removeItemsFromResultsByGuideType,
  removeSOWGuide,
} from "./HIPsSOWMachine.utils";

export const getHIPsSOWMachineActions = () => {
  return {
    addGuideToScopeOfWork: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<GuideSOWRoomEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        addSOWGuide(ctx, event);
      }
    ),
    addFakeGuideToScopeOfWork: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<GuideSOWRoomEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        addFakeGuide(ctx, event);
      }
    ),
    removeGuideFromScopeOfWork: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<GuideSOWRoomEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        removeSOWGuide(ctx, event);
      }
    ),
    cleanUpActiveGuides: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<GuideSOWRoomEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { hipsId } = event.data;
        const hip = findBy(ctx.hips, "id", hipsId);
        const sowIds = hip.scopeOfWork.guides.map((guide) => guide.id);

        ctx.activeGuides = ctx.activeGuides.filter(
          (guide) =>
            !(guide._hipId === hipsId && !sowIds.includes(guide._SOWId))
        );
      }
    ),
    removeIntent: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<RemoveIntentFromSOWEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { guideId, hipsId, intent } = event.data;
        const hips = findBy(ctx.hips, "id", hipsId);
        const guide = findBy(hips.scopeOfWork.guides, "id", guideId);
        guide.intents = removeFromArray(guide.intents, intent) as GuideIntents;
        if (guide.intents.length === 0) {
          hips.scopeOfWork.guides = hips.scopeOfWork.guides.filter(
            (item) => item.id !== guideId
          );
        }
      }
    ),
    addMultipleGuidesToScopeOfWork: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<MultipleGuideSOWRoomEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { roomIds, ...data } = event.data;
        roomIds.forEach((roomId) => {
          addSOWGuide(ctx, { data: { ...data, roomId }, type: event.type });
        });
      }
    ),
    removeMultipleGuidesFromScopeOfWork: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<MultipleGuideSOWRoomEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { roomIds, ...data } = event.data;
        roomIds.forEach((roomId) => {
          removeSOWGuide(ctx, { data: { ...data, roomId }, type: event.type });
        });
      }
    ),
    removeFloorPlan: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<HIPsRoomRouteParams>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { hipsId, roomId } = event.data;
        const hips = findBy(ctx.hips, "id", hipsId);

        if (!roomId) {
          return;
        }

        // Clear results both in HIPs rooms and in common rooms.
        ctx.rooms = ctx.rooms.map((r) =>
          r.id === roomId
            ? { ...r, guideResults: undefined, roomPlan: undefined }
            : r
        );
        hips.planningAreas.rooms = hips.planningAreas.rooms.map((r) =>
          r.id === roomId
            ? { ...r, guideResults: undefined, roomPlan: undefined }
            : r
        );

        // Clear active guides related to that room
        ctx.activeGuides = ctx.activeGuides.filter((g) => g._roomId !== roomId);
        // Also do not forget to clear completed intents for that room SOW.
        hips.scopeOfWork.guides = hips.scopeOfWork.guides.map((g) =>
          g.roomId === roomId
            ? { ...g, completedIntents: [], status: undefined }
            : g
        );
      }
    ),
    removeGuideCategory: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<RemoveCategoryGuideEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { hipsId, type, roomId } = event.data;
        const hips = findBy(ctx.hips, "id", hipsId);
        const guidePreset = find(hips.scopeOfWork.guides, {
          roomId,
          type,
        }).preset;
        if (type === GuideType.PRESET_GROUP_TYPE) {
          hips.scopeOfWork.guides = hips.scopeOfWork.guides.filter(
            (currentGuide) => {
              const { preset, roomId: currentRoomId } = currentGuide;
              if (
                preset?.presetId === guidePreset?.presetId &&
                preset?.presetItemId === guidePreset?.presetItemId &&
                currentRoomId === roomId
              ) {
                return false;
              }
              return true;
            }
          );
        }
        hips.scopeOfWork.guides = hips.scopeOfWork.guides.filter((guide) => {
          return !(guide.type === type && guide.roomId === roomId);
        });

        if (roomId) {
          let removedItemIds: Array<GuideItem["id"]> = [];

          hips.planningAreas.rooms = hips.planningAreas.rooms.map((room) => {
            if (room.id !== roomId) {
              return room;
            }

            if (!room.guideResults) {
              return room;
            }

            const newResults: typeof room.guideResults = {
              ...room.guideResults,
            };

            const intents: Array<keyof GuideResults> = GUIDE_INTENTS_ORDER;

            for (const intent of intents) {
              const { removed, items } = removeItemsFromResultsByGuideType({
                items: room.guideResults[intent].items,
                type,
                roomId,
              });

              removedItemIds.push(...removed.map((r) => r.id));
              (newResults[intent].items as AbstractGuideItem[]) = items;
            }

            removedItemIds = uniq(removedItemIds);

            for (const intent of ["cs", ...intents]) {
              for (const itemId of removedItemIds) {
                newResults[intent].connections = disconnectFromAll(
                  newResults[intent].connections,
                  itemId
                ).remaining;
              }
            }

            newResults.machines = newResults.machines.filter((machine) => {
              const machineType = machine.meta?.type;
              const intent = machine.meta?.intent;

              if (
                machine.roomId === roomId &&
                machineType &&
                machineType === type &&
                Boolean(intent)
              ) {
                return false;
              }

              return true;
            });

            return {
              ...room,
              guideResults: newResults,
            };
          });
        }
      }
    ),
    addPresetGroup: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<AddPresetGroupEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { hipsId, presetItemId, presetId, roomIds, items } = event.data;
        const hips = findBy(ctx.hips, "id", hipsId);

        hips.scopeOfWork.guides = hips.scopeOfWork.guides.filter((guide) => {
          return guide.preset?.presetItemId !== presetItemId;
        });

        roomIds.forEach((roomId) => {
          hips.scopeOfWork.guides.push({
            preset: {
              presetItemId,
              presetId,
            },
            roomId,
            id: uuid(),
            intents: [GuideIntentType.ADD],
            completedIntents: [],
            // TODO: Change to `GuideItemTypeCategory` when it will be ready
            // this should be some fake item type category for all presets
            itemType: "NO_ITEM_TYPE_YET" as GuideItemTypeCategory,
            subCategory: "NO_ITEM_TYPE_YET" as SubCategory,
            type: GuideType.PRESET_GROUP_TYPE,
          });
          items.forEach((task) => {
            hips.scopeOfWork.guides.push({
              preset: {
                presetItemId,
                presetId,
              },
              roomId,
              id: uuid(),
              intents: task.intent,
              completedIntents: [],
              itemType: task.subCategory as GuideItemTypeCategory,
              subCategory: task.subCategory as SubCategory,
              type: task.category,
            });
          });
        });
      }
    ),
    startOverPresetGroup: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<StartOverPresetGroupEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { hipsId, presetItemId } = event.data;
        const hips = findBy(ctx.hips, "id", hipsId);

        hips.scopeOfWork.guides = hips.scopeOfWork.guides.filter((guide) => {
          return !(guide.preset?.presetItemId === presetItemId);
        });
      }
    ),
    removeAllTasks: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<RemoveAllTasksEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { hipsId, roomId } = event.data;
        const hips = findBy(ctx.hips, "id", hipsId);

        hips.scopeOfWork.guides = hips.scopeOfWork.guides.filter((guide) => {
          return guide.roomId !== roomId;
        });
      }
    ),
    removeRoomFromPlanningArea: assign(
      (
        _ctx: OrchestratorMachineContext,
        event: OrchestratorEvent<RemoveRoomFromPlanningAreaEventData>
      ) => {
        const ctx = getActivePropertyContext(_ctx);
        const { hipsId, roomId } = event.data;
        const hips = findBy(ctx.hips, "id", hipsId);

        hips.scopeOfWork.guides = hips.scopeOfWork.guides.filter((guide) => {
          return guide.roomId !== roomId;
        });
        hips.planningAreas.rooms = hips.planningAreas.rooms.filter((room) => {
          return room.id !== roomId;
        });
      }
    ),
  };
};
