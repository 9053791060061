import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  GuideItemTypeConfig,
  RuleType,
} from "constants/guides/GuideItems.types";
import { RULE_DEFAULT_CANNOT_OVERLAP } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { KitchenFixtureTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_FIXTURE,
  KitchenFixtureTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [KitchenFixtureTypes.KITCHEN_SINK]: { width: 40, height: 40 },
  [KitchenFixtureTypes.KITCHEN_FAUCET]: { width: 40, height: 40 },
  [KitchenFixtureTypes.KITCHEN_WATER_FILTER]: { width: 40, height: 40 },
  [KitchenFixtureTypes.KITCHEN_WASTE_DISPOSAL]: { width: 40, height: 40 },
};

const rules: Config["rules"] = {
  [KitchenFixtureTypes.KITCHEN_SINK]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenFixtureTypes.KITCHEN_FAUCET]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenFixtureTypes.KITCHEN_WATER_FILTER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenFixtureTypes.KITCHEN_WASTE_DISPOSAL]: RULE_DEFAULT_CANNOT_OVERLAP,
};

const ruleSets: Config["ruleSets"] = {
  [KitchenFixtureTypes.KITCHEN_SINK]: [RuleType.STAY_INSIDE],
  [KitchenFixtureTypes.KITCHEN_FAUCET]: [RuleType.STAY_INSIDE],
  [KitchenFixtureTypes.KITCHEN_WATER_FILTER]: [RuleType.STAY_INSIDE],
  [KitchenFixtureTypes.KITCHEN_WASTE_DISPOSAL]: [RuleType.STAY_INSIDE],
};

export const KitchenFixtureConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};
