import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { RULE_DEFAULT_CANNOT_OVERLAP } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { BeverageCoolerSubtypes } from "./BeverageCoolerSubtypes/enums";
import { CooktopSubtypes } from "./CooktopSubtypes/enums";
import { DishwasherSubtypes } from "./DishwasherSubtypes/enums";
import { KitchenApplianceTypes } from "./enums";
import { FreezerDrawerSubtypes } from "./FreezerDrawerSubtypes/enums";
import { IceMachineSubtypes } from "./IceMachineSubtypes/enums";
import { KitchenVentSubtypes } from "./KitchenVentSubtypes/enums";
import { MicrowaveSubtypes } from "./MicrowaveSubtypes/enums";
import { RangeOvenSubtypes } from "./RangeOvenSubtypes/enums";
import { RangetopSubtypes } from "./RangetopSubtypes/enums";
import { RefrigeratedDrawerSubtypes } from "./RefrigeratedDrawerSubtypes/enums";
import { RefrigeratorFreezerSubtypes } from "./RefrigeratorFreezerSubypes/enums";
import { TrashCompactorSubtypes } from "./TrashCompactorSubtypes/enums";
import { KitchenApplianceSubtypes } from "./types";
import { WallOvenSubtypes } from "./WallOvenSubtypes/enums";
import { WarmingDrawerSubtypes } from "./WarmingDrawerSubtypes/enums";
import { WineCoolerSubtypes } from "./WineCoolerSubtypes/enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_APPLIANCE,
  KitchenApplianceTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [KitchenApplianceTypes.RANGE_OVEN]: { width: 40, height: 40 },
  [KitchenApplianceTypes.RANGETOP]: { width: 40, height: 40 },
  [KitchenApplianceTypes.COOKTOP]: { width: 40, height: 40 },
  [KitchenApplianceTypes.WALL_OVEN]: { width: 40, height: 40 },
  [KitchenApplianceTypes.KITCHEN_VENT]: { width: 40, height: 40 },
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.REFRIGERATED_DRAWER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.FREEZER_DRAWER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.DISHWASHER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.WINE_COOLER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.BEVERAGE_COOLER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.WARMING_DRAWER]: { width: 40, height: 40 },
  [KitchenApplianceTypes.TRASH_COMPACTOR]: { width: 40, height: 40 },
  [KitchenApplianceTypes.ICE_MACHINE]: { width: 40, height: 40 },
  [KitchenApplianceTypes.MICROWAVE]: { width: 40, height: 40 },
};

const rules: Config["rules"] = {
  [KitchenApplianceTypes.RANGE_OVEN]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.RANGETOP]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.COOKTOP]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.WALL_OVEN]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.KITCHEN_VENT]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.REFRIGERATED_DRAWER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.FREEZER_DRAWER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.DISHWASHER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.WINE_COOLER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.BEVERAGE_COOLER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.WARMING_DRAWER]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.TRASH_COMPACTOR]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.ICE_MACHINE]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenApplianceTypes.MICROWAVE]: RULE_DEFAULT_CANNOT_OVERLAP,
};

const ruleSets: Config["ruleSets"] = {
  [KitchenApplianceTypes.RANGE_OVEN]: [],
  [KitchenApplianceTypes.RANGETOP]: [],
  [KitchenApplianceTypes.COOKTOP]: [],
  [KitchenApplianceTypes.WALL_OVEN]: [],
  [KitchenApplianceTypes.KITCHEN_VENT]: [],
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: [],
  [KitchenApplianceTypes.REFRIGERATED_DRAWER]: [],
  [KitchenApplianceTypes.FREEZER_DRAWER]: [],
  [KitchenApplianceTypes.DISHWASHER]: [],
  [KitchenApplianceTypes.WINE_COOLER]: [],
  [KitchenApplianceTypes.BEVERAGE_COOLER]: [],
  [KitchenApplianceTypes.WARMING_DRAWER]: [],
  [KitchenApplianceTypes.TRASH_COMPACTOR]: [],
  [KitchenApplianceTypes.ICE_MACHINE]: [],
  [KitchenApplianceTypes.MICROWAVE]: [],
};

export const KitchenApplianceConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};

const kitchenApplianceDefaultDimensions = {
  [KitchenApplianceTypes.REFRIGERATOR_FREEZER]: {
    [RefrigeratorFreezerSubtypes.FRENCH_DOOR_REFRIGERATOR_FREEZER]: {
      width: 36,
      height: 68,
      depth: 24,
    },
    [RefrigeratorFreezerSubtypes.BOTTOM_REFRIGERATOR_FREEZER]: {
      width: 30,
      height: 68,
      depth: 24,
    },
    [RefrigeratorFreezerSubtypes.SIDE_BY_SIDE_REFRIGERATOR_FREEZER]: {
      width: 36,
      height: 68,
      depth: 24,
    },
    [RefrigeratorFreezerSubtypes.TOP_REFRIGERATOR_FREEZER]: {
      width: 30,
      height: 68,
      depth: 24,
    },
    [RefrigeratorFreezerSubtypes.COLUMN_FREEZER]: {
      width: 30,
      height: 68,
      depth: 24,
    },
    [RefrigeratorFreezerSubtypes.COLUMN_REFRIGERATOR]: {
      width: 30,
      height: 68,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.BEVERAGE_COOLER]: {
    [BeverageCoolerSubtypes.UNDER_COUNTER_BEVERAGE_COOLER]: {
      width: 24,
      height: 30,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.COOKTOP]: {
    [CooktopSubtypes.ELECTRIC_COOKTOP]: {
      width: 30,
      height: 60,
      depth: 24,
    },
    [CooktopSubtypes.GAS_COOKTOP]: {
      width: 30,
      height: 60,
      depth: 24,
    },
    [CooktopSubtypes.INDUCTION_COOKTOP]: {
      width: 30,
      height: 60,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.DISHWASHER]: {
    [DishwasherSubtypes.BUILT_IN_DISHWASHER]: {
      width: 24,
      height: 36,
      depth: 24,
    },
    [DishwasherSubtypes.FREESTANDING_DISHWASHER]: {
      width: 24,
      height: 36,
      depth: 24,
    },
    DEFAULT: {
      width: 24,
      height: 36,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.FREEZER_DRAWER]: {
    [FreezerDrawerSubtypes.DOUBLE_FREEZER_DRAWER]: {
      width: 24,
      height: 18,
      depth: 24,
    },
    [FreezerDrawerSubtypes.SINGLE_FREEZER_DRAWER]: {
      width: 24,
      height: 18,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.ICE_MACHINE]: {
    [IceMachineSubtypes.BUILT_IN_ICE_MACHINE]: {
      width: 15,
      height: 34.5,
      depth: 24,
    },
    [IceMachineSubtypes.FREESTANDING_ICE_MACHINE]: {
      width: 15,
      height: 34.5,
      depth: 24,
    },
    DEFAULT: {
      width: 15,
      height: 34.5,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.KITCHEN_VENT]: {
    [KitchenVentSubtypes.DOWNDRAFT_KITCHEN_VENT]: {
      width: 30,
      height: 30,
      depth: 3,
    },
    [KitchenVentSubtypes.ISLAND_KITCHEN_VENT]: {
      width: 30,
      height: 30,
      depth: 18,
    },
    [KitchenVentSubtypes.UNDER_CABINET_KITCHEN_VENT]: {
      width: 30,
      height: 30,
      depth: 18,
    },
    [KitchenVentSubtypes.UNDER_MICROWAVE_KITCHEN_VENT]: {
      width: 30,
      height: 30,
      depth: 18,
    },
    [KitchenVentSubtypes.WALL_MOUNTED_KITCHEN_VENT]: {
      width: 30,
      height: 30,
      depth: 18,
    },
  },
  [KitchenApplianceTypes.MICROWAVE]: {
    [MicrowaveSubtypes.IN_CABINET_MICROWAVE]: {
      width: 24,
      height: 20,
      depth: 24,
    },
    [MicrowaveSubtypes.OVER_THE_RANGE_MICROWAVE]: {
      width: 30,
      height: 20,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.RANGETOP]: {
    [RangetopSubtypes.EIGHT_BURNER_GAS_RANGETOP]: {
      width: 30,
      height: 30,
      depth: 24,
    },
    [RangetopSubtypes.FIVE_BURNER_GAS_RANGETOP_WITH_GRIDDLE]: {
      width: 30,
      height: 30,
      depth: 24,
    },
    [RangetopSubtypes.FOUR_BURNER_GAS_RANGETOP]: {
      width: 30,
      height: 30,
      depth: 24,
    },
    [RangetopSubtypes.FOUR_BURNER_GAS_RANGETOP_WITH_GRIDDLE]: {
      width: 30,
      height: 30,
      depth: 24,
    },
    [RangetopSubtypes.SIX_BURNER_GAS_RANGETOP]: {
      width: 30,
      height: 30,
      depth: 24,
    },
    [RangetopSubtypes.SIX_BURNER_GAS_RANGETOP_WITH_GRIDDLE]: {
      width: 30,
      height: 30,
      depth: 24,
    },
    DEFAULT: {
      width: 30,
      height: 30,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.RANGE_OVEN]: {
    [RangeOvenSubtypes.DUAL_FUEL_RANGE]: {
      width: 30,
      height: 36,
      depth: 27,
    },
    [RangeOvenSubtypes.ELECTRIC_RANGE]: {
      width: 30,
      height: 36,
      depth: 27,
    },
    [RangeOvenSubtypes.GAS_RANGE]: {
      width: 30,
      height: 36,
      depth: 27,
    },
    [RangeOvenSubtypes.INDUCTION_RANGE]: {
      width: 30,
      height: 36,
      depth: 27,
    },
  },
  [KitchenApplianceTypes.REFRIGERATED_DRAWER]: {
    [RefrigeratedDrawerSubtypes.DOUBLE_REFRIGERATED_DRAWER]: {
      width: 24,
      height: 18,
      depth: 24,
    },
    [RefrigeratedDrawerSubtypes.SINGLE_REFRIGERATED_DRAWER]: {
      width: 24,
      height: 18,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.TRASH_COMPACTOR]: {
    [TrashCompactorSubtypes.BUILT_IN_TRASH_COMPACTOR]: {
      width: 15,
      height: 34.5,
      depth: 24,
    },
    [TrashCompactorSubtypes.FREESTANDING_TRASH_COMPACTOR]: {
      width: 15,
      height: 34.5,
      depth: 24,
    },
    DEFAULT: {
      width: 15,
      height: 34.5,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.WALL_OVEN]: {
    [WallOvenSubtypes.DOUBLE_WALL_OVEN]: {
      width: 30,
      height: 30,
      depth: 24,
    },
    [WallOvenSubtypes.SINGLE_WALL_OVEN]: {
      width: 30,
      height: 30,
      depth: 24,
    },
    [WallOvenSubtypes.WALL_OVEN_WITH_MICROWAVE]: {
      width: 30,
      height: 30,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.WARMING_DRAWER]: {
    [WarmingDrawerSubtypes.PANEL_READY_WARMING_DRAWER]: {
      width: 24,
      height: 10,
      depth: 24,
    },
    [WarmingDrawerSubtypes.STANDARD_DRAWER_WARMING_DRAWER]: {
      width: 24,
      height: 10,
      depth: 24,
    },
    DEFAULT: {
      width: 24,
      height: 10,
      depth: 24,
    },
  },
  [KitchenApplianceTypes.WINE_COOLER]: {
    [WineCoolerSubtypes.FULL_LENGTH_WINE_COOLER]: {
      width: 24,
      height: 60,
      depth: 24,
    },
    [WineCoolerSubtypes.UNDER_COUNTER_WINE_COOLER]: {
      width: 24,
      height: 36,
      depth: 24,
    },
  },
};

export const getKitchenApplianceDefaultDimensions = (
  type: KitchenApplianceTypes,
  subtype: KitchenApplianceSubtypes | "DEFAULT"
) => {
  return kitchenApplianceDefaultDimensions[type][subtype];
};
