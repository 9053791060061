import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { RULE_DEFAULT_CANNOT_OVERLAP } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { BaseCabinetSubtypes } from "./BaseCabinetSubtypes/enums";
import { KitchenCabinetTypes } from "./enums";
import { TallPantryCabinetSubtypes } from "./TallPantryCabinetSubtypes/enums";
import { KitchenCabinetSubtypes } from "./types";
import { WallCabinetSubtypes } from "./WallCabinetSubtypes/enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_CABINET,
  KitchenCabinetTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [KitchenCabinetTypes.BASE_CABINET]: { width: 40, height: 40 },
  [KitchenCabinetTypes.WALL_CABINET]: { width: 40, height: 40 },
  [KitchenCabinetTypes.TALL_PANTRY_CABINET]: { width: 40, height: 40 },
};

const rules: Config["rules"] = {
  [KitchenCabinetTypes.BASE_CABINET]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenCabinetTypes.WALL_CABINET]: RULE_DEFAULT_CANNOT_OVERLAP,
  [KitchenCabinetTypes.TALL_PANTRY_CABINET]: RULE_DEFAULT_CANNOT_OVERLAP,
};

const ruleSets: Config["ruleSets"] = {
  [KitchenCabinetTypes.BASE_CABINET]: [],
  [KitchenCabinetTypes.WALL_CABINET]: [],
  [KitchenCabinetTypes.TALL_PANTRY_CABINET]: [],
};

export const KitchenCabinetConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};

const kitchenCabinetDefaultDimensions = {
  [KitchenCabinetTypes.BASE_CABINET]: {
    [BaseCabinetSubtypes.SINGLE_BASE_CABINET]: {
      width: { unit: "inch", value: 24 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 34.5 },
    },
    [BaseCabinetSubtypes.DOUBLE_BASE_CABINET]: {
      width: { unit: "inch", value: 48 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 34.5 },
    },
    [BaseCabinetSubtypes.DRAWER_BASE_CABINET]: {
      width: { unit: "inch", value: 24 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 34.5 },
    },
    [BaseCabinetSubtypes.SINK_BASE_CABINET]: {
      width: { unit: "inch", value: 30 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 34.5 },
    },
    [BaseCabinetSubtypes.BLIND_CORNER_BASE_CABINET]: {
      width: { unit: "inch", value: 36 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 34.5 },
    },
    [BaseCabinetSubtypes.DIAGONAL_CORNER_BASE_CABINET]: {
      width: { unit: "inch", value: 33 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 34.5 },
    },
    [BaseCabinetSubtypes.L_SHAPE_CORNER_BASE_CABINET]: {
      width: { unit: "inch", value: 36 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 34.5 },
    },
  },
  [KitchenCabinetTypes.WALL_CABINET]: {
    [WallCabinetSubtypes.SINGLE_WALL_CABINET]: {
      width: { unit: "inch", value: 24 },
      depth: { unit: "inch", value: 12 },
      height: { unit: "inch", value: 30 },
    },
    [WallCabinetSubtypes.DOUBLE_WALL_CABINET]: {
      width: { unit: "inch", value: 48 },
      depth: { unit: "inch", value: 12 },
      height: { unit: "inch", value: 30 },
    },
    [WallCabinetSubtypes.BLIND_CORNER_WALL_CABINET]: {
      width: { unit: "inch", value: 36 },
      depth: { unit: "inch", value: 12 },
      height: { unit: "inch", value: 30 },
    },
    [WallCabinetSubtypes.DIAGONAL_CORNER_WALL_CABINET]: {
      width: { unit: "inch", value: 36 },
      depth: { unit: "inch", value: 18 },
      height: { unit: "inch", value: 30 },
    },
  },
  [KitchenCabinetTypes.TALL_PANTRY_CABINET]: {
    [TallPantryCabinetSubtypes.TALL_PANTRY_APPLIANCE_CABINET]: {
      width: { unit: "inch", value: 24 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 36 },
    },
    [TallPantryCabinetSubtypes.TALL_PANTRY_DOUBLE_CABINET]: {
      width: { unit: "inch", value: 36 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 36 },
    },
    [TallPantryCabinetSubtypes.TALL_PANTRY_SINGLE_CABINET]: {
      width: { unit: "inch", value: 24 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 36 },
    },
    [TallPantryCabinetSubtypes.TALL_PANTRY_DIAGONAL_CORNER_CABINET]: {
      width: { unit: "inch", value: 36 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 36 },
    },
    [TallPantryCabinetSubtypes.TALL_PANTRY_BLIND_CORNER_CABINET]: {
      width: { unit: "inch", value: 33 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 36 },
    },
    [TallPantryCabinetSubtypes.TALL_PANTRY_BOTTOM_DRAWER_CABINET]: {
      width: { unit: "inch", value: 24 },
      depth: { unit: "inch", value: 24 },
      height: { unit: "inch", value: 36 },
    },
  },
};

export const getKitchenCabinetDefaultDimensions = (
  type: KitchenCabinetTypes,
  subtype: KitchenCabinetSubtypes
) => {
  return kitchenCabinetDefaultDimensions[type][subtype];
};
