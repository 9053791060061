import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { GuideIntentType } from "pages/Guides/enums";

import { KitchenFixtureConfigFloorPlan } from "./config.floorPlan";
import { KitchenFixtureConfigImages } from "./config.images";
import { KitchenFixturesConfigTexts } from "./config.texts";
import { KitchenFixtureTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_FIXTURE,
  KitchenFixtureTypes
>;

const types: Config["types"] = [
  KitchenFixtureTypes.KITCHEN_SINK,
  KitchenFixtureTypes.KITCHEN_FAUCET,
  KitchenFixtureTypes.KITCHEN_WATER_FILTER,
  KitchenFixtureTypes.KITCHEN_WASTE_DISPOSAL,
];

export const KitchenFixtureConfig: Config = {
  guideType: GuideType.KITCHEN_SPECIFIC,
  category: GuideItemTypeCategory.KITCHEN_FIXTURE,
  types,
  typesOrder: getOrderedMap(types),
  texts: KitchenFixturesConfigTexts,
  images: KitchenFixtureConfigImages,
  floorPlan: KitchenFixtureConfigFloorPlan,
  allowedInRooms: ["kitchen"],
  allowedIntents: [GuideIntentType.ADD],
} as const;
