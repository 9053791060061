import { useSelector } from "@xstate/react";
import { useCoreContext } from "core/context/CoreContext";
import { useGlobalState } from "core/state/global/GlobalStateContext";
import type { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";

export const orchestratorContextSelector = (state: {
  context: OrchestratorMachineContext;
}) => state.context;

export const useAppData = (topLevelContent = false) => {
  const { orchestrator } = useGlobalState();
  const { globalOrchestratorMachine } = useCoreContext();
  return useSelector(
    topLevelContent ? globalOrchestratorMachine : orchestrator,
    orchestratorContextSelector
  );
};
