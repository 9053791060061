import clsx from "clsx";
import { CSSProperties, FC } from "react";

import styles from "./Skeleton.module.scss";

interface Props {
  className?: string;
  style?: CSSProperties;
  type?: "dynamic" | "static";
}

export const Skeleton: FC<Props> = ({ className, style, type = "dynamic" }) => {
  return (
    <div
      className={clsx(styles.skeleton, styles[type], className)}
      style={style}
    />
  );
};
