import { TFunction } from "react-i18next";

export enum ApplianceFuelType {
  GAS_APPLIANCE = "gas_appliance",
  ELECTRIC_APPLIANCE = "electric_appliance",
  INDUCTION_APPLIANCE = "induction_appliance",
  DUAL_FUEL_APPLIANCE = "dual_fuel_appliance",
}

export enum BeverageCoolerType {
  FULL_LENGTH_BEVERAGE_COOLER = "full_length_beverage_cooler",
  UNDER_COUNTER_BEVERAGE_COOLER = "under_counter_beverage_cooler",
}

export enum CabinetBuildStyle {
  FRAMED_CABINET = "framed_cabinet",
  FRAMELESS_CABINET = "frameless_cabinet",
  INSET_CABINET = "inset_cabinet",
}

export enum CabinetColor {
  WHITE_CABINET = "white_cabinet",
  BLACK_CABINET = "black_cabinet",
  BROWN_CABINET = "brown_cabinet",
  BLUE_CABINET = "blue_cabinet",
  GREY_CABINET = "grey_cabinet",
  BEIGE_CABINET = "beige_cabinet",
  GREEN_CABINET = "green_cabinet",
  YELLOW_CABINET = "yellow_cabinet",
  ORANGE_CABINET = "orange_cabinet",
}

export enum CabinetDoorStyle {
  FLAT_PANEL_DOOR = "flat_panel_door",
  SHAKER_DOOR = "shaker_door",
  RAISED_PANEL_DOOR = "raised_panel_door",
  RECESSED_PANEL_DOOR = "recessed_panel_door",
  LOUVERED_DOOR = "louvered_door",
  ARCHED_DOOR = "arched_door",
  GLASS_FRONT_DOOR = "glass_front_door",
  BEADBOARD_DOOR = "beadboard_door",
}

export enum CabinetLayout {
  ONE_WALL_LAYOUT = "one_wall_layout",
  GALLEY_LAYOUT = "galley_layout",
  L_SHAPE_LAYOUT = "l_shape_layout",
  U_SHAPE_LAYOUT = "u_shape_layout",
  PENINSULA_LAYOUT = "peninsula_layout",
}

export enum CabinetMaterial {
  WOOD_CABINET = "wood_cabinet",
  COLOR_CABINET = "color_cabinet",
  METAL_CABINET = "metal_cabinet",
}

export enum CooktopControlLocation {
  TOP_CONTROL_COOKTOP = "top_control_cooktop",
  FRONT_CONTROL_COOKTOP = "front_control_cooktop",
}

export enum CountertopBacksplashColor {
  WHITE_COUNTERTOP_BACKSPLASH = "white_countertop_backsplash",
  BLACK_COUNTERTOP_BACKSPLASH = "black_countertop_backsplash",
  CREAM_COUNTERTOP_BACKSPLASH = "cream_countertop_backsplash",
  NEUTRAL_COUNTERTOP_BACKSPLASH = "neutral_countertop_backsplash",
  GREY_COUNTERTOP_BACKSPLASH = "grey_countertop_backsplash",
  SILVER_COUNTERTOP_BACKSPLASH = "silver_countertop_backsplash",
  BROWN_COUNTERTOP_BACKSPLASH = "brown_countertop_backsplash",
  BLUE_COUNTERTOP_BACKSPLASH = "blue_countertop_backsplash",
  BEIGE_COUNTERTOP_BACKSPLASH = "beige_countertop_backsplash",
  RED_COUNTERTOP_BACKSPLASH = "red_countertop_backsplash",
  GREEN_COUNTERTOP_BACKSPLASH = "green_countertop_backsplash",
  ORANGE_COUNTERTOP_BACKSPLASH = "orange_countertop_backsplash",
  PINK_COUNTERTOP_BACKSPLASH = "pink_countertop_backsplash",
}

export enum CountertopBacksplashMaterial {
  NATURAL_STONE_SLAB_COUNTERTOP_BACKSPLASH = "natural_stone_slab_countertop_backsplash",
  ENGINEERED_STONE_SLAB_COUNTERTOP_BACKSPLASH = "engineered_stone_slab_countertop_backsplash",
  TILE_COUNTERTOP_BACKSPLASH = "tile_countertop_backsplash",
  WOOD_COUNTERTOP_BACKSPLASH = "wood_countertop_backsplash",
  GLASS_COUNTERTOP_BACKSPLASH = "glass_countertop_backsplash",
  METAL_COUNTERTOP_BACKSPLASH = "metal_countertop_backsplash",
  CONCRETE_SLAB_COUNTERTOP_BACKSPLASH = "concrete_slab_countertop_backsplash",
}

export enum DishwasherControlLocation {
  TOP_CONTROL_DISHWASHER = "top_control_dishwasher",
  FRONT_CONTROL_DISHWASHER = "front_control_dishwasher",
}

export enum EngineeredStoneCountertopBacksplashType {
  QUARTZ_COUNTERTOP_BACKSPLASH = "quartz_countertop_backsplash",
  SOLID_SURFACE_COUNTERTOP_BACKSPLASH = "solid_surface_countertop_backsplash",
  TERRAZZO_COUNTERTOP_BACKSPLASH = "terrazzo_countertop_backsplash",
  ULTRA_COMPACT_COUNTERTOP_BACKSPLASH = "ultra_compact_countertop_backsplash",
}

export enum KitchenApplianceType {
  REFRIGERATOR_FREEZER = "refrigerator_freezer",
  RANGE_OVEN = "range_oven",
  WALL_OVEN = "wall_oven",
  COOKTOP = "cooktop",
  KITCHEN_VENT = "kitchen_vent",
  DISHWASHER = "dishwasher",
  BUILT_IN_MICROWAVE = "built_in_microwave",
  WINE_COOLER = "wine_cooler",
  BEVERAGE_COOLER = "beverage_cooler",
  WARMING_DRAWER = "warming_drawer",
  BUILT_IN_COFFEE_MACHINE = "built_in_coffee_machine",
  STEAM_OVEN = "steam_oven",
  ICE_MACHINE = "ice_machine",
  TRASH_COMPACTOR = "trash_compactor",
}

export enum KitchenFaucetFinalFinish {
  POLISHED_KITCHEN_FAUCET = "polished_kitchen_faucet",
  BRUSHED_KITCHEN_FAUCET = "brushed_kitchen_faucet",
  MATTE_BLACK_KITCHEN_FAUCET = "matte_black_kitchen_faucet",
}

export enum KitchenFaucetInstallationType {
  DECK_MOUNTED_KITCHEN_FAUCET = "deck_mounted_kitchen_faucet",
  WALL_MOUNTED_KITCHEN_FAUCET = "wall_mounted_kitchen_faucet",
}

export enum KitchenFaucetMaterialType {
  STAINLESS_STEEL_KITCHEN_FAUCET = "stainless_steel_kitchen_faucet",
  BRASS_KITCHEN_FAUCET = "brass_kitchen_faucet",
  BRONZE_KITCHEN_FAUCET = "bronze_kitchen_faucet",
  CHROME_KITCHEN_FAUCET = "chrome_kitchen_faucet",
  NICKEL_KITCHEN_FAUCET = "nickel_kitchen_faucet",
}

export enum KitchenFaucetSprayerStyle {
  PULL_DOWN_FAUCET_SPRAYER = "pull_down_faucet_sprayer",
  PULL_OUT_FAUCET_SPRAYER = "pull_out_faucet_sprayer",
  INDEPENDENT_FAUCET_SPRAYER = "independent_faucet_sprayer",
}

export enum KitchenFaucetStyle {
  SINGLE_HOLE_KITCHEN_FAUCET = "single_hole_kitchen_faucet",
  CENTERSET_KITCHEN_FAUCET = "centerset_kitchen_faucet",
  WIDESPREAD_KITCHEN_FAUCET = "widespread_kitchen_faucet",
  BRIDGE_KITCHEN_FAUCET = "bridge_kitchen_faucet",
  TOUCHLESS_KITCHEN_FAUCET = "touchless_kitchen_faucet",
  POT_FILLER_KITCHEN_FAUCET = "pot_filler_kitchen_faucet",
  WATER_FILTER_KITCHEN_FAUCET = "water_filter_kitchen_faucet",
  INSTANT_HOT_WATER_KITCHEN_FAUCET = "instant_hot_water_kitchen_faucet",
}

export enum KitchenFixtureType {
  KITCHEN_SINK = "kitchen_sink",
  KITCHEN_FAUCET = "kitchen_faucet",
  WATER_FILTER = "water_filter",
}

export enum KitchenSinkMaterial {
  FIRECLAY_KITCHEN_SINK = "fireclay_kitchen_sink",
  PORCELAIN_KITCHEN_SINK = "porcelain_kitchen_sink",
  CERAMIC_KITCHEN_SINK = "ceramic_kitchen_sink",
  CAST_IRON_KITCHEN_SINK = "cast_iron_kitchen_sink",
  STAINLESS_STEEL_KITCHEN_SINK = "stainless_steel_kitchen_sink",
  BRASS_KITCHEN_SINK = "brass_kitchen_sink",
  COPPER_KITCHEN_SINK = "copper_kitchen_sink",
  GRANITE_COMPOSITE_KITCHEN_SINK = "granite_composite_kitchen_sink",
  MARBLE_KITCHEN_SINK = "marble_kitchen_sink",
  QUARTZ_KITCHEN_SINK = "quartz_kitchen_sink",
}

export enum KitchenSinkStyle {
  FARMHOUSE_KITCHEN_SINK = "farmhouse_kitchen_sink",
  UNDERMOUNT_KITCHEN_SINK = "undermount_kitchen_sink",
  DROP_IN_KITCHEN_SINK = "drop_in_kitchen_sink",
}

export enum KitchenVentType {
  WALL_MOUNTED_KITCHEN_VENT = "wall_mounted_kitchen_vent",
  ISLAND_KITCHEN_VENT = "island_kitchen_vent",
  DOWNDRAFT_KITCHEN_VENT = "downdraft_kitchen_vent",
  UNDER_CABINET_KITCHEN_VENT = "under_cabinet_kitchen_vent",
}

export enum MicrowaveInstallationType {
  BUILT_IN_CABINET_MICROWAVE = "built_in_cabinet_microwave",
  OVER_THE_RANGE_MICROWAVE = "over_the_range_microwave",
}

export enum NaturalStoneCountertopBacksplashType {
  GRANITE_COUNTERTOP_BACKSPLASH = "granite_countertop_backsplash",
  LIMESTONE_COUNTERTOP_BACKSPLASH = "limestone_countertop_backsplash",
  MARBLE_COUNTERTOP_BACKSPLASH = "marble_countertop_backsplash",
  QUARTZITE_COUNTERTOP_BACKSPLASH = "quartzite_countertop_backsplash",
  SANDSTONE_COUNTERTOP_BACKSPLASH = "sandstone_countertop_backsplash",
  SLATE_COUNTERTOP_BACKSPLASH = "slate_countertop_backsplash",
  SOAPSTONE_COUNTERTOP_BACKSPLASH = "soapstone_countertop_backsplash",
  TRAVERTINE_COUNTERTOP_BACKSPLASH = "travertine_countertop_backsplash",
}

export enum RangeOvenInstallationType {
  BUILT_IN_RANGE_OVEN = "built_in_range_oven",
  FREESTANDING_RANGE_OVEN = "freestanding_range_oven",
  SLIDE_IN_RANGE_OVEN = "slide_in_range_oven",
  DROP_IN_RANGE_OVEN = "drop_in_range_oven",
}

export enum RangeOvenQty {
  SINGLE_OVEN_RANGE = "single_oven_range",
  DOUBLE_OVEN_RANGE = "double_oven_range",
}

export enum RefrigeratorFreezerInstallationType {
  BUILT_IN_FRIDGE = "built_in_fridge",
  FREESTANDING_FRIDGE = "freestanding_fridge",
}

export enum RefrigeratorFreezerType {
  FRENCH_DOOR_FRIDGE = "french_door_fridge",
  SIDE_BY_SIDE_FRIDGE = "side_by_side_fridge",
  BOTTOM_FREEZER_FRIDGE = "bottom_freezer_fridge",
  TOP_FREEZER_FRIDGE = "top_freezer_fridge",
  DOOR_IN_DOOR_FRIDGE = "door_in_door_fridge",
  UNDER_COUNTER_FRIDGE = "under_counter_fridge",
}

export enum StoneSlabCountertopBacksplashMaterialFinish {
  SOLID_COLOR_STONE_COUNTERTOP_BACKSPLASH = "solid_color_stone_countertop_backsplash",
  VEINED_STONE_COUNTERTOP_BACKSPLASH = "veined_stone_countertop_backsplash",
  FLECKED_SPECKLED_STONE_COUNTERTOP_BACKSPLASH = "flecked_speckled_stone_countertop_backsplash",
  SWIRLED_STONE_COUNTERTOP_BACKSPLASH = "swirled_stone_countertop_backsplash",
  MOTTLED_STONE_COUNTERTOP_BACKSPLASH = "mottled_stone_countertop_backsplash",
  TERRAZZO_STONE_COUNTERTOP_BACKSPLASH = "terrazzo_stone_countertop_backsplash",
}

export enum TileCountertopBacksplashMaterialFinish {
  SOLID_COLOR_TILE_COUNTERTOP_BACKSPLASH = "solid_color_tile_countertop_backsplash",
  MIXED_SHADE_TILE_COUNTERTOP_BACKSPLASH = "mixed_shade_tile_countertop_backsplash",
  MIXED_COLOR_TILE_COUNTERTOP_BACKSPLASH = "mixed_color_tile_countertop_backsplash",
  PATTERN_TILE_COUNTERTOP_BACKSPLASH = "pattern_tile_countertop_backsplash",
  STONE_LOOK_TILE_COUNTERTOP_BACKSPLASH = "stone_look_tile_countertop_backsplash",
}

export enum TileCountertopBacksplashType {
  STONE_TILE_COUNTERTOP_BACKSPLASH = "stone_tile_countertop_backsplash",
  PORCELAIN_TILE_COUNTERTOP_BACKSPLASH = "porcelain_tile_countertop_backsplash",
  CERAMIC_TILE_COUNTERTOP_BACKSPLASH = "ceramic_tile_countertop_backsplash",
  TERRACOTTA_TILE_COUNTERTOP_BACKSPLASH = "terracotta_tile_countertop_backsplash",
  GLASS_TILE_COUNTERTOP_BACKSPLASH = "glass_tile_countertop_backsplash",
  CONCRETE_TILE_COUNTERTOP_BACKSPLASH = "concrete_tile_countertop_backsplash",
}

export enum TileShape {
  RECTANGLE_TILE = "rectangle_tile",
  SQUARE_TILE = "square_tile",
  TRIANGLE_TILE = "triangle_tile",
  HEXAGON_TILE = "hexagon_tile",
  DIAMOND_TILE = "diamond_tile",
  LEAF_TILE = "leaf_tile",
  PEBBLE_TILE = "pebble_tile",
  MOSAIC_TILE = "mosaic_tile",
}

export enum WallOvenConfiguration {
  STAND_ALONE_WALL_OVEN = "stand_alone_wall_oven",
  SIDE_BY_SIDE_WALL_OVEN = "side_by_side_wall_oven",
  STACKED_WALL_OVEN = "stacked_wall_oven",
}

export enum WineCoolerType {
  FULL_LENGTH_WINE_COOLER = "full_length_wine_cooler",
  UNDER_COUNTER_WINE_COOLER = "under_counter_wine_cooler",
}

export enum InspirationFilterTypes {
  APPLIANCE_FUEL_TYPE = "appliance_fuel_type",
  BEVERAGE_COOLER_TYPE = "beverage_cooler_type",
  CABINET_BUILD_STYLE = "cabinet_build_style",
  CABINET_COLOR = "cabinet_color",
  CABINET_DOOR_STYLE = "cabinet_door_style",
  CABINET_LAYOUT = "cabinet_layout",
  CABINET_MATERIAL = "cabinet_material",
  COOKTOP_CONTROL_LOCATION = "cooktop_control_location",
  COUNTERTOP_BACKSPLASH_COLOR = "countertop_backsplash_color",
  COUNTERTOP_BACKSPLASH_MATERIAL = "countertop_backsplash_material",
  DISHWASHER_CONTROL_LOCATION = "dishwasher_control_location",
  ENGINEERED_STONE_COUNTERTOP_BACKSPLASH_TYPE = "engineered_stone_countertop_backsplash_type",
  KITCHEN_APPLIANCE_TYPE = "kitchen_appliance_type",
  KITCHEN_FAUCET_FINAL_FINISH = "kitchen_faucet_final_finish",
  KITCHEN_FAUCET_INSTALLATION_TYPE = "kitchen_faucet_installation_type",
  KITCHEN_FAUCET_MATERIAL_TYPE = "kitchen_faucet_material_type",
  KITCHEN_FAUCET_SPRAYER_STYLE = "kitchen_faucet_sprayer_style",
  KITCHEN_FAUCET_STYLE = "kitchen_faucet_style",
  KITCHEN_FIXTURE_TYPE = "kitchen_fixture_type",
  KITCHEN_SINK_MATERIAL = "kitchen_sink_material",
  KITCHEN_SINK_STYLE = "kitchen_sink_style",
  KITCHEN_VENT_TYPE = "kitchen_vent_type",
  MICROWAVE_INSTALLATION_TYPE = "microwave_installation_type",
  NATURAL_STONE_COUNTERTOP_BACKSPLASH_TYPE = "natural_stone_countertop_backsplash_type",
  RANGE_OVEN_INSTALLATION_TYPE = "range_oven_installation_type",
  RANGE_OVEN_QTY = "range_oven_qty",
  REFRIGERATOR_FREEZER_INSTALLATION_TYPE = "refrigerator_freezer_installation_type",
  REFRIGERATOR_FREEZER_TYPE = "refrigerator_freezer_type",
  STONE_SLAB_COUNTERTOP_BACKSPLASH_MATERIAL_FINISH = "stone_slab_countertop_backsplash_material_finish",
  TILE_COUNTERTOP_BACKSPLASH_MATERIAL_FINISH = "tile_countertop_backsplash_material_finish",
  TILE_COUNTERTOP_BACKSPLASH_TYPE = "tile_countertop_backsplash_type",
  TILE_SHAPE = "tile_shape",
  WALL_OVEN_CONFIGURATION = "wall_oven_configuration",
  WINE_COOLER_TYPE = "wine_cooler_type",
}

const ApplianceFuelTypeTexts = (
  t: TFunction
): Record<ApplianceFuelType, string> => ({
  [ApplianceFuelType.DUAL_FUEL_APPLIANCE]: t("Dual Fuel"),
  [ApplianceFuelType.ELECTRIC_APPLIANCE]: t("Electric"),
  [ApplianceFuelType.GAS_APPLIANCE]: t("Gas"),
  [ApplianceFuelType.INDUCTION_APPLIANCE]: t("Induction"),
});

const BeverageCoolerTypeTexts = (
  t: TFunction
): Record<BeverageCoolerType, string> => ({
  [BeverageCoolerType.FULL_LENGTH_BEVERAGE_COOLER]: t("Full Length"),
  [BeverageCoolerType.UNDER_COUNTER_BEVERAGE_COOLER]: t("Under Counter"),
});

const CabinetBuildStyleTexts = (
  t: TFunction
): Record<CabinetBuildStyle, string> => ({
  [CabinetBuildStyle.FRAMED_CABINET]: t("Framed"),
  [CabinetBuildStyle.FRAMELESS_CABINET]: t("Frameless"),
  [CabinetBuildStyle.INSET_CABINET]: t("Inset"),
});

const CabinetColorTexts = (t: TFunction): Record<CabinetColor, string> => ({
  [CabinetColor.BEIGE_CABINET]: t("Beige"),
  [CabinetColor.BLACK_CABINET]: t("Black"),
  [CabinetColor.BLUE_CABINET]: t("Blue"),
  [CabinetColor.BROWN_CABINET]: t("Brown"),
  [CabinetColor.GREEN_CABINET]: t("Green"),
  [CabinetColor.GREY_CABINET]: t("Grey"),
  [CabinetColor.ORANGE_CABINET]: t("Orange"),
  [CabinetColor.WHITE_CABINET]: t("White"),
  [CabinetColor.YELLOW_CABINET]: t("Yellow"),
});

const CabinetDoorStyleTexts = (
  t: TFunction
): Record<CabinetDoorStyle, string> => ({
  [CabinetDoorStyle.ARCHED_DOOR]: t("Arched"),
  [CabinetDoorStyle.BEADBOARD_DOOR]: t("Beadboard"),
  [CabinetDoorStyle.FLAT_PANEL_DOOR]: t("Flat Panel"),
  [CabinetDoorStyle.GLASS_FRONT_DOOR]: t("Glass Front"),
  [CabinetDoorStyle.LOUVERED_DOOR]: t("Louvered"),
  [CabinetDoorStyle.RAISED_PANEL_DOOR]: t("Raised Panel"),
  [CabinetDoorStyle.RECESSED_PANEL_DOOR]: t("Recessed Panel"),
  [CabinetDoorStyle.SHAKER_DOOR]: t("Shaker"),
});

const CabinetLayoutTexts = (t: TFunction): Record<CabinetLayout, string> => ({
  [CabinetLayout.GALLEY_LAYOUT]: t("Galley"),
  [CabinetLayout.L_SHAPE_LAYOUT]: t("L-Shape"),
  [CabinetLayout.ONE_WALL_LAYOUT]: t("One Wall"),
  [CabinetLayout.PENINSULA_LAYOUT]: t("Peninsula"),
  [CabinetLayout.U_SHAPE_LAYOUT]: t("U-Shape"),
});

const CabinetMaterialTexts = (
  t: TFunction
): Record<CabinetMaterial, string> => ({
  [CabinetMaterial.COLOR_CABINET]: t("Color"),
  [CabinetMaterial.METAL_CABINET]: t("Metal"),
  [CabinetMaterial.WOOD_CABINET]: t("Wood"),
});

const CooktopControlLocationTexts = (
  t: TFunction
): Record<CooktopControlLocation, string> => ({
  [CooktopControlLocation.FRONT_CONTROL_COOKTOP]: t("Front Control"),
  [CooktopControlLocation.TOP_CONTROL_COOKTOP]: t("Top Control"),
});

const CountertopBacksplashColorTexts = (
  t: TFunction
): Record<CountertopBacksplashColor, string> => ({
  [CountertopBacksplashColor.BEIGE_COUNTERTOP_BACKSPLASH]: t("Beige"),
  [CountertopBacksplashColor.BLACK_COUNTERTOP_BACKSPLASH]: t("Black"),
  [CountertopBacksplashColor.BLUE_COUNTERTOP_BACKSPLASH]: t("Blue"),
  [CountertopBacksplashColor.BROWN_COUNTERTOP_BACKSPLASH]: t("Brown"),
  [CountertopBacksplashColor.CREAM_COUNTERTOP_BACKSPLASH]: t("Cream"),
  [CountertopBacksplashColor.GREEN_COUNTERTOP_BACKSPLASH]: t("Green"),
  [CountertopBacksplashColor.GREY_COUNTERTOP_BACKSPLASH]: t("Grey"),
  [CountertopBacksplashColor.NEUTRAL_COUNTERTOP_BACKSPLASH]: t("Neutral"),
  [CountertopBacksplashColor.ORANGE_COUNTERTOP_BACKSPLASH]: t("Orange"),
  [CountertopBacksplashColor.PINK_COUNTERTOP_BACKSPLASH]: t("Pink"),
  [CountertopBacksplashColor.RED_COUNTERTOP_BACKSPLASH]: t("Red"),
  [CountertopBacksplashColor.SILVER_COUNTERTOP_BACKSPLASH]: t("Silver"),
  [CountertopBacksplashColor.WHITE_COUNTERTOP_BACKSPLASH]: t("White"),
});

const CountertopBacksplashMaterialTexts = (
  t: TFunction
): Record<CountertopBacksplashMaterial, string> => ({
  [CountertopBacksplashMaterial.CONCRETE_SLAB_COUNTERTOP_BACKSPLASH]:
    t("Concrete"),
  [CountertopBacksplashMaterial.ENGINEERED_STONE_SLAB_COUNTERTOP_BACKSPLASH]:
    t("Engineered Stone"),
  [CountertopBacksplashMaterial.GLASS_COUNTERTOP_BACKSPLASH]: t("Glass"),
  [CountertopBacksplashMaterial.METAL_COUNTERTOP_BACKSPLASH]: t("Metal"),
  [CountertopBacksplashMaterial.NATURAL_STONE_SLAB_COUNTERTOP_BACKSPLASH]:
    t("Natural Stone"),
  [CountertopBacksplashMaterial.TILE_COUNTERTOP_BACKSPLASH]: t("Tile"),
  [CountertopBacksplashMaterial.WOOD_COUNTERTOP_BACKSPLASH]: t("Wood"),
});

const DishwasherControlLocationTexts = (
  t: TFunction
): Record<DishwasherControlLocation, string> => ({
  [DishwasherControlLocation.FRONT_CONTROL_DISHWASHER]: t("Front Control"),
  [DishwasherControlLocation.TOP_CONTROL_DISHWASHER]: t("Top Control"),
});

const EngineeredStoneCountertopBacksplashTypeTexts = (
  t: TFunction
): Record<EngineeredStoneCountertopBacksplashType, string> => ({
  [EngineeredStoneCountertopBacksplashType.QUARTZ_COUNTERTOP_BACKSPLASH]:
    t("Quartz"),
  [EngineeredStoneCountertopBacksplashType.SOLID_SURFACE_COUNTERTOP_BACKSPLASH]:
    t("Solid Surface"),
  [EngineeredStoneCountertopBacksplashType.TERRAZZO_COUNTERTOP_BACKSPLASH]:
    t("Terrazzo"),
  [EngineeredStoneCountertopBacksplashType.ULTRA_COMPACT_COUNTERTOP_BACKSPLASH]:
    t("Ultra Compact"),
});

const KitchenApplianceTypeTexts = (
  t: TFunction
): Record<KitchenApplianceType, string> => ({
  [KitchenApplianceType.BEVERAGE_COOLER]: t("Beverage Cooler"),
  [KitchenApplianceType.BUILT_IN_COFFEE_MACHINE]: t("Built In Coffee Maker"),
  [KitchenApplianceType.BUILT_IN_MICROWAVE]: t("Built In Microwave"),
  [KitchenApplianceType.COOKTOP]: t("Cooktop"),
  [KitchenApplianceType.DISHWASHER]: t("Dishwasher"),
  [KitchenApplianceType.ICE_MACHINE]: t("Ice Machine"),
  [KitchenApplianceType.KITCHEN_VENT]: t("Kitchen Vent"),
  [KitchenApplianceType.RANGE_OVEN]: t("Range"),
  [KitchenApplianceType.REFRIGERATOR_FREEZER]: t("Refrigerator / Freezer"),
  [KitchenApplianceType.STEAM_OVEN]: t("Steam Oven"),
  [KitchenApplianceType.TRASH_COMPACTOR]: t("Trash Compactor"),
  [KitchenApplianceType.WALL_OVEN]: t("Wall Oven"),
  [KitchenApplianceType.WARMING_DRAWER]: t("Warming Drawer"),
  [KitchenApplianceType.WINE_COOLER]: t("Wine Cooler"),
});

const KitchenFaucetFinalFinishTexts = (
  t: TFunction
): Record<KitchenFaucetFinalFinish, string> => ({
  [KitchenFaucetFinalFinish.BRUSHED_KITCHEN_FAUCET]: t("Brushed"),
  [KitchenFaucetFinalFinish.MATTE_BLACK_KITCHEN_FAUCET]: t("Matte Black"),
  [KitchenFaucetFinalFinish.POLISHED_KITCHEN_FAUCET]: t("Polished"),
});

const KitchenFaucetInstallationTypeTexts = (
  t: TFunction
): Record<KitchenFaucetInstallationType, string> => ({
  [KitchenFaucetInstallationType.DECK_MOUNTED_KITCHEN_FAUCET]:
    t("Deck Mounted"),
  [KitchenFaucetInstallationType.WALL_MOUNTED_KITCHEN_FAUCET]:
    t("Wall Mounted"),
});

const KitchenFaucetMaterialTypeTexts = (
  t: TFunction
): Record<KitchenFaucetMaterialType, string> => ({
  [KitchenFaucetMaterialType.BRASS_KITCHEN_FAUCET]: t("Brass"),
  [KitchenFaucetMaterialType.BRONZE_KITCHEN_FAUCET]: t("Bronze"),
  [KitchenFaucetMaterialType.CHROME_KITCHEN_FAUCET]: t("Chrome"),
  [KitchenFaucetMaterialType.NICKEL_KITCHEN_FAUCET]: t("Nickel"),
  [KitchenFaucetMaterialType.STAINLESS_STEEL_KITCHEN_FAUCET]:
    t("Stainless Steel"),
});

const KitchenFaucetSprayerStyleTexts = (
  t: TFunction
): Record<KitchenFaucetSprayerStyle, string> => ({
  [KitchenFaucetSprayerStyle.INDEPENDENT_FAUCET_SPRAYER]: t(
    "Independent Sprayer"
  ),
  [KitchenFaucetSprayerStyle.PULL_DOWN_FAUCET_SPRAYER]: t("Pull Down Sprayer"),
  [KitchenFaucetSprayerStyle.PULL_OUT_FAUCET_SPRAYER]: t("Pull Out Sprayer"),
});

const KitchenFaucetStyleTexts = (
  t: TFunction
): Record<KitchenFaucetStyle, string> => ({
  [KitchenFaucetStyle.BRIDGE_KITCHEN_FAUCET]: t("Bridge"),
  [KitchenFaucetStyle.CENTERSET_KITCHEN_FAUCET]: t("Centerset"),
  [KitchenFaucetStyle.INSTANT_HOT_WATER_KITCHEN_FAUCET]: t("Instant Hot Water"),
  [KitchenFaucetStyle.POT_FILLER_KITCHEN_FAUCET]: t("Pot Filler"),
  [KitchenFaucetStyle.SINGLE_HOLE_KITCHEN_FAUCET]: t("Single Hole"),
  [KitchenFaucetStyle.TOUCHLESS_KITCHEN_FAUCET]: t("Touchless"),
  [KitchenFaucetStyle.WATER_FILTER_KITCHEN_FAUCET]: t("Water Filter"),
  [KitchenFaucetStyle.WIDESPREAD_KITCHEN_FAUCET]: t("Widespread"),
});

const KitchenFixtureTypeTexts = (
  t: TFunction
): Record<KitchenFixtureType, string> => ({
  [KitchenFixtureType.KITCHEN_FAUCET]: t("Kitchen Faucet"),
  [KitchenFixtureType.KITCHEN_SINK]: t("Kitchen Sink"),
  [KitchenFixtureType.WATER_FILTER]: t("Water Filter"),
});

const KitchenSinkMaterialTexts = (
  t: TFunction
): Record<KitchenSinkMaterial, string> => ({
  [KitchenSinkMaterial.BRASS_KITCHEN_SINK]: t("Brass"),
  [KitchenSinkMaterial.CAST_IRON_KITCHEN_SINK]: t("Cast Iron"),
  [KitchenSinkMaterial.CERAMIC_KITCHEN_SINK]: t("Ceramic"),
  [KitchenSinkMaterial.COPPER_KITCHEN_SINK]: t("Copper"),
  [KitchenSinkMaterial.FIRECLAY_KITCHEN_SINK]: t("Fireclay"),
  [KitchenSinkMaterial.GRANITE_COMPOSITE_KITCHEN_SINK]: t("Granite Composite"),
  [KitchenSinkMaterial.MARBLE_KITCHEN_SINK]: t("Marble"),
  [KitchenSinkMaterial.PORCELAIN_KITCHEN_SINK]: t("Porcelain"),
  [KitchenSinkMaterial.QUARTZ_KITCHEN_SINK]: t("Quartz"),
  [KitchenSinkMaterial.STAINLESS_STEEL_KITCHEN_SINK]: t("Stainless Steel"),
});

const KitchenSinkStyleTexts = (
  t: TFunction
): Record<KitchenSinkStyle, string> => ({
  [KitchenSinkStyle.DROP_IN_KITCHEN_SINK]: t("Drop In"),
  [KitchenSinkStyle.FARMHOUSE_KITCHEN_SINK]: t("Farmhouse"),
  [KitchenSinkStyle.UNDERMOUNT_KITCHEN_SINK]: t("Undermount"),
});

const KitchenVentTypeTexts = (
  t: TFunction
): Record<KitchenVentType, string> => ({
  [KitchenVentType.DOWNDRAFT_KITCHEN_VENT]: t("Downdraft"),
  [KitchenVentType.ISLAND_KITCHEN_VENT]: t("Island"),
  [KitchenVentType.UNDER_CABINET_KITCHEN_VENT]: t("Under Cabinet"),
  [KitchenVentType.WALL_MOUNTED_KITCHEN_VENT]: t("Wall Mounted"),
});

const MicrowaveInstallationTypeTexts = (
  t: TFunction
): Record<MicrowaveInstallationType, string> => ({
  [MicrowaveInstallationType.BUILT_IN_CABINET_MICROWAVE]:
    t("Built In Microwave"),
  [MicrowaveInstallationType.OVER_THE_RANGE_MICROWAVE]: t("Over The Range"),
});

const NaturalStoneCountertopBacksplashTypeTexts = (
  t: TFunction
): Record<NaturalStoneCountertopBacksplashType, string> => ({
  [NaturalStoneCountertopBacksplashType.GRANITE_COUNTERTOP_BACKSPLASH]:
    t("Granite"),
  [NaturalStoneCountertopBacksplashType.LIMESTONE_COUNTERTOP_BACKSPLASH]:
    t("Limestone"),
  [NaturalStoneCountertopBacksplashType.MARBLE_COUNTERTOP_BACKSPLASH]:
    t("Marble"),
  [NaturalStoneCountertopBacksplashType.QUARTZITE_COUNTERTOP_BACKSPLASH]:
    t("Quartzite"),
  [NaturalStoneCountertopBacksplashType.SANDSTONE_COUNTERTOP_BACKSPLASH]:
    t("Sandstone"),
  [NaturalStoneCountertopBacksplashType.SLATE_COUNTERTOP_BACKSPLASH]:
    t("Slate"),
  [NaturalStoneCountertopBacksplashType.SOAPSTONE_COUNTERTOP_BACKSPLASH]:
    t("Soapstone"),
  [NaturalStoneCountertopBacksplashType.TRAVERTINE_COUNTERTOP_BACKSPLASH]:
    t("Travertine"),
});

const RangeOvenInstallationTypeTexts = (
  t: TFunction
): Record<RangeOvenInstallationType, string> => ({
  [RangeOvenInstallationType.BUILT_IN_RANGE_OVEN]: t("Built In"),
  [RangeOvenInstallationType.DROP_IN_RANGE_OVEN]: t("Drop In"),
  [RangeOvenInstallationType.FREESTANDING_RANGE_OVEN]: t("Freestanding"),
  [RangeOvenInstallationType.SLIDE_IN_RANGE_OVEN]: t("Slide In"),
});

const RangeOvenQtyTexts = (t: TFunction): Record<RangeOvenQty, string> => ({
  [RangeOvenQty.DOUBLE_OVEN_RANGE]: t("Double Oven"),
  [RangeOvenQty.SINGLE_OVEN_RANGE]: t("Single Oven"),
});

const RefrigeratorFreezerInstallationTypeTexts = (
  t: TFunction
): Record<RefrigeratorFreezerInstallationType, string> => ({
  [RefrigeratorFreezerInstallationType.BUILT_IN_FRIDGE]: t("Built In"),
  [RefrigeratorFreezerInstallationType.FREESTANDING_FRIDGE]: t("Freestanding"),
});

const RefrigeratorFreezerTypeTexts = (
  t: TFunction
): Record<RefrigeratorFreezerType, string> => ({
  [RefrigeratorFreezerType.BOTTOM_FREEZER_FRIDGE]: t("Bottom Freezer"),
  [RefrigeratorFreezerType.DOOR_IN_DOOR_FRIDGE]: t("Door In Door"),
  [RefrigeratorFreezerType.FRENCH_DOOR_FRIDGE]: t("French Door"),
  [RefrigeratorFreezerType.SIDE_BY_SIDE_FRIDGE]: t("Side By Side"),
  [RefrigeratorFreezerType.TOP_FREEZER_FRIDGE]: t("Top Freezer"),
  [RefrigeratorFreezerType.UNDER_COUNTER_FRIDGE]: t("Under Counter"),
});

const StoneSlabCountertopBacksplashMaterialFinishTexts = (
  t: TFunction
): Record<StoneSlabCountertopBacksplashMaterialFinish, string> => ({
  [StoneSlabCountertopBacksplashMaterialFinish.FLECKED_SPECKLED_STONE_COUNTERTOP_BACKSPLASH]:
    t("Flecked"),
  [StoneSlabCountertopBacksplashMaterialFinish.MOTTLED_STONE_COUNTERTOP_BACKSPLASH]:
    t("Mottled"),
  [StoneSlabCountertopBacksplashMaterialFinish.SOLID_COLOR_STONE_COUNTERTOP_BACKSPLASH]:
    t("Solid Color"),
  [StoneSlabCountertopBacksplashMaterialFinish.SWIRLED_STONE_COUNTERTOP_BACKSPLASH]:
    t("Swirled"),
  [StoneSlabCountertopBacksplashMaterialFinish.TERRAZZO_STONE_COUNTERTOP_BACKSPLASH]:
    t("Terrazzo"),
  [StoneSlabCountertopBacksplashMaterialFinish.VEINED_STONE_COUNTERTOP_BACKSPLASH]:
    t("Veined"),
});

const TileCountertopBacksplashMaterialFinishTexts = (
  t: TFunction
): Record<TileCountertopBacksplashMaterialFinish, string> => ({
  [TileCountertopBacksplashMaterialFinish.MIXED_COLOR_TILE_COUNTERTOP_BACKSPLASH]:
    t("Mixed Color"),
  [TileCountertopBacksplashMaterialFinish.MIXED_SHADE_TILE_COUNTERTOP_BACKSPLASH]:
    t("Mixed Shade"),
  [TileCountertopBacksplashMaterialFinish.PATTERN_TILE_COUNTERTOP_BACKSPLASH]:
    t("Patterned"),
  [TileCountertopBacksplashMaterialFinish.SOLID_COLOR_TILE_COUNTERTOP_BACKSPLASH]:
    t("Solid Color"),
  [TileCountertopBacksplashMaterialFinish.STONE_LOOK_TILE_COUNTERTOP_BACKSPLASH]:
    t("Stone Look"),
});

const TileCountertopBacksplashTypeTexts = (
  t: TFunction
): Record<TileCountertopBacksplashType, string> => ({
  [TileCountertopBacksplashType.CERAMIC_TILE_COUNTERTOP_BACKSPLASH]:
    t("Ceramic"),
  [TileCountertopBacksplashType.CONCRETE_TILE_COUNTERTOP_BACKSPLASH]:
    t("Concrete"),
  [TileCountertopBacksplashType.GLASS_TILE_COUNTERTOP_BACKSPLASH]: t("Glass"),
  [TileCountertopBacksplashType.PORCELAIN_TILE_COUNTERTOP_BACKSPLASH]:
    t("Porcelain"),
  [TileCountertopBacksplashType.STONE_TILE_COUNTERTOP_BACKSPLASH]: t("Stone"),
  [TileCountertopBacksplashType.TERRACOTTA_TILE_COUNTERTOP_BACKSPLASH]:
    t("Terracotta"),
});

const TileShapeTexts = (t: TFunction): Record<TileShape, string> => ({
  [TileShape.DIAMOND_TILE]: t("Diamond"),
  [TileShape.HEXAGON_TILE]: t("Hexagon"),
  [TileShape.LEAF_TILE]: t("Leaf"),
  [TileShape.MOSAIC_TILE]: t("Mosaic"),
  [TileShape.PEBBLE_TILE]: t("Pebble"),
  [TileShape.RECTANGLE_TILE]: t("Rectabgle"),
  [TileShape.SQUARE_TILE]: t("Square"),
  [TileShape.TRIANGLE_TILE]: t("Triangle"),
});

const WallOvenConfigurationTexts = (
  t: TFunction
): Record<WallOvenConfiguration, string> => ({
  [WallOvenConfiguration.SIDE_BY_SIDE_WALL_OVEN]: t("Side By Side"),
  [WallOvenConfiguration.STACKED_WALL_OVEN]: t("Stacked"),
  [WallOvenConfiguration.STAND_ALONE_WALL_OVEN]: t("Stand Alone"),
});

const WineCoolerTypeTexts = (t: TFunction): Record<WineCoolerType, string> => ({
  [WineCoolerType.FULL_LENGTH_WINE_COOLER]: t("Full Length"),
  [WineCoolerType.UNDER_COUNTER_WINE_COOLER]: t("Under Counter"),
});

export const InspirationFilterTypesTexts = (
  t: TFunction
): Record<InspirationFilterTypes, string> => ({
  [InspirationFilterTypes.APPLIANCE_FUEL_TYPE]: t("Fuel Type"),
  [InspirationFilterTypes.BEVERAGE_COOLER_TYPE]: t("Beverage Cooler Type"),
  [InspirationFilterTypes.CABINET_BUILD_STYLE]: t("Build Style"),
  [InspirationFilterTypes.CABINET_COLOR]: t("Color"),
  [InspirationFilterTypes.CABINET_DOOR_STYLE]: t("Door Style"),
  [InspirationFilterTypes.CABINET_LAYOUT]: t("Layout"),
  [InspirationFilterTypes.CABINET_MATERIAL]: t("Material"),
  [InspirationFilterTypes.COOKTOP_CONTROL_LOCATION]: t("Control Location"),
  [InspirationFilterTypes.COUNTERTOP_BACKSPLASH_COLOR]: t("Color"),
  [InspirationFilterTypes.COUNTERTOP_BACKSPLASH_MATERIAL]: t("Material"),
  [InspirationFilterTypes.DISHWASHER_CONTROL_LOCATION]: t("Control Location"),
  [InspirationFilterTypes.ENGINEERED_STONE_COUNTERTOP_BACKSPLASH_TYPE]:
    t("Stone Type"),
  [InspirationFilterTypes.KITCHEN_APPLIANCE_TYPE]: t("Appliance Type"),
  [InspirationFilterTypes.KITCHEN_FAUCET_FINAL_FINISH]: t("Faucet Finish"),
  [InspirationFilterTypes.KITCHEN_FAUCET_INSTALLATION_TYPE]: t(
    "Installation Location"
  ),
  [InspirationFilterTypes.KITCHEN_FAUCET_MATERIAL_TYPE]: t("Faucet Material"),
  [InspirationFilterTypes.KITCHEN_FAUCET_SPRAYER_STYLE]: t("Sprayer Style"),
  [InspirationFilterTypes.KITCHEN_FAUCET_STYLE]: t("Faucet Style"),
  [InspirationFilterTypes.KITCHEN_FIXTURE_TYPE]: t("Fixture Type"),
  [InspirationFilterTypes.KITCHEN_SINK_MATERIAL]: t("Material"),
  [InspirationFilterTypes.KITCHEN_SINK_STYLE]: t("Sink Style"),
  [InspirationFilterTypes.KITCHEN_VENT_TYPE]: t("Kitchen Vent Type"),
  [InspirationFilterTypes.MICROWAVE_INSTALLATION_TYPE]: t("Installation Type"),
  [InspirationFilterTypes.NATURAL_STONE_COUNTERTOP_BACKSPLASH_TYPE]:
    t("Stone Type"),
  [InspirationFilterTypes.RANGE_OVEN_INSTALLATION_TYPE]: t("Installation Type"),
  [InspirationFilterTypes.RANGE_OVEN_QTY]: t("Range Oven Qty"),
  [InspirationFilterTypes.REFRIGERATOR_FREEZER_INSTALLATION_TYPE]:
    t("Installation Type"),
  [InspirationFilterTypes.REFRIGERATOR_FREEZER_TYPE]: t(
    "Refrigerator / Freezer Type"
  ),
  [InspirationFilterTypes.STONE_SLAB_COUNTERTOP_BACKSPLASH_MATERIAL_FINISH]:
    t("Stone Look"),
  [InspirationFilterTypes.TILE_COUNTERTOP_BACKSPLASH_MATERIAL_FINISH]:
    t("Tile Look"),
  [InspirationFilterTypes.TILE_COUNTERTOP_BACKSPLASH_TYPE]: t("Tile Type"),
  [InspirationFilterTypes.TILE_SHAPE]: t("Shape"),
  [InspirationFilterTypes.WALL_OVEN_CONFIGURATION]: t("Configuration"),
  [InspirationFilterTypes.WINE_COOLER_TYPE]: t("Wine Cooler Type"),
});

export const InspirationFiltersMapping = {
  [InspirationFilterTypes.APPLIANCE_FUEL_TYPE]: {
    values: ApplianceFuelType,
    texts: ApplianceFuelTypeTexts,
  },
  [InspirationFilterTypes.BEVERAGE_COOLER_TYPE]: {
    values: BeverageCoolerType,
    texts: BeverageCoolerTypeTexts,
  },
  [InspirationFilterTypes.CABINET_BUILD_STYLE]: {
    values: CabinetBuildStyle,
    texts: CabinetBuildStyleTexts,
  },
  [InspirationFilterTypes.CABINET_COLOR]: {
    values: CabinetColor,
    texts: CabinetColorTexts,
  },
  [InspirationFilterTypes.CABINET_DOOR_STYLE]: {
    values: CabinetDoorStyle,
    texts: CabinetDoorStyleTexts,
  },
  [InspirationFilterTypes.CABINET_LAYOUT]: {
    values: CabinetLayout,
    texts: CabinetLayoutTexts,
  },
  [InspirationFilterTypes.CABINET_MATERIAL]: {
    values: CabinetMaterial,
    texts: CabinetMaterialTexts,
  },
  [InspirationFilterTypes.COOKTOP_CONTROL_LOCATION]: {
    values: CooktopControlLocation,
    texts: CooktopControlLocationTexts,
  },
  [InspirationFilterTypes.COUNTERTOP_BACKSPLASH_COLOR]: {
    values: CountertopBacksplashColor,
    texts: CountertopBacksplashColorTexts,
  },
  [InspirationFilterTypes.COUNTERTOP_BACKSPLASH_MATERIAL]: {
    values: CountertopBacksplashMaterial,
    texts: CountertopBacksplashMaterialTexts,
  },
  [InspirationFilterTypes.DISHWASHER_CONTROL_LOCATION]: {
    values: DishwasherControlLocation,
    texts: DishwasherControlLocationTexts,
  },
  [InspirationFilterTypes.ENGINEERED_STONE_COUNTERTOP_BACKSPLASH_TYPE]: {
    values: EngineeredStoneCountertopBacksplashType,
    texts: EngineeredStoneCountertopBacksplashTypeTexts,
  },
  [InspirationFilterTypes.KITCHEN_APPLIANCE_TYPE]: {
    values: KitchenApplianceType,
    texts: KitchenApplianceTypeTexts,
  },
  [InspirationFilterTypes.KITCHEN_FAUCET_FINAL_FINISH]: {
    values: KitchenFaucetFinalFinish,
    texts: KitchenFaucetFinalFinishTexts,
  },
  [InspirationFilterTypes.KITCHEN_FAUCET_INSTALLATION_TYPE]: {
    values: KitchenFaucetInstallationType,
    texts: KitchenFaucetInstallationTypeTexts,
  },
  [InspirationFilterTypes.KITCHEN_FAUCET_MATERIAL_TYPE]: {
    values: KitchenFaucetMaterialType,
    texts: KitchenFaucetMaterialTypeTexts,
  },
  [InspirationFilterTypes.KITCHEN_FAUCET_SPRAYER_STYLE]: {
    values: KitchenFaucetSprayerStyle,
    texts: KitchenFaucetSprayerStyleTexts,
  },
  [InspirationFilterTypes.KITCHEN_FAUCET_STYLE]: {
    values: KitchenFaucetStyle,
    texts: KitchenFaucetStyleTexts,
  },
  [InspirationFilterTypes.KITCHEN_FIXTURE_TYPE]: {
    values: KitchenFixtureType,
    texts: KitchenFixtureTypeTexts,
  },
  [InspirationFilterTypes.KITCHEN_SINK_MATERIAL]: {
    values: KitchenSinkMaterial,
    texts: KitchenSinkMaterialTexts,
  },
  [InspirationFilterTypes.KITCHEN_SINK_STYLE]: {
    values: KitchenSinkStyle,
    texts: KitchenSinkStyleTexts,
  },
  [InspirationFilterTypes.KITCHEN_VENT_TYPE]: {
    values: KitchenVentType,
    texts: KitchenVentTypeTexts,
  },
  [InspirationFilterTypes.MICROWAVE_INSTALLATION_TYPE]: {
    values: MicrowaveInstallationType,
    texts: MicrowaveInstallationTypeTexts,
  },
  [InspirationFilterTypes.NATURAL_STONE_COUNTERTOP_BACKSPLASH_TYPE]: {
    values: NaturalStoneCountertopBacksplashType,
    texts: NaturalStoneCountertopBacksplashTypeTexts,
  },
  [InspirationFilterTypes.RANGE_OVEN_INSTALLATION_TYPE]: {
    values: RangeOvenInstallationType,
    texts: RangeOvenInstallationTypeTexts,
  },
  [InspirationFilterTypes.RANGE_OVEN_QTY]: {
    values: RangeOvenQty,
    texts: RangeOvenQtyTexts,
  },
  [InspirationFilterTypes.REFRIGERATOR_FREEZER_INSTALLATION_TYPE]: {
    values: RefrigeratorFreezerInstallationType,
    texts: RefrigeratorFreezerInstallationTypeTexts,
  },
  [InspirationFilterTypes.REFRIGERATOR_FREEZER_TYPE]: {
    values: RefrigeratorFreezerType,
    texts: RefrigeratorFreezerTypeTexts,
  },
  [InspirationFilterTypes.STONE_SLAB_COUNTERTOP_BACKSPLASH_MATERIAL_FINISH]: {
    values: StoneSlabCountertopBacksplashMaterialFinish,
    texts: StoneSlabCountertopBacksplashMaterialFinishTexts,
  },
  [InspirationFilterTypes.TILE_COUNTERTOP_BACKSPLASH_MATERIAL_FINISH]: {
    values: TileCountertopBacksplashMaterialFinish,
    texts: TileCountertopBacksplashMaterialFinishTexts,
  },
  [InspirationFilterTypes.TILE_COUNTERTOP_BACKSPLASH_TYPE]: {
    values: TileCountertopBacksplashType,
    texts: TileCountertopBacksplashTypeTexts,
  },
  [InspirationFilterTypes.TILE_SHAPE]: {
    values: TileShape,
    texts: TileShapeTexts,
  },
  [InspirationFilterTypes.WALL_OVEN_CONFIGURATION]: {
    values: WallOvenConfiguration,
    texts: WallOvenConfigurationTexts,
  },
  [InspirationFilterTypes.WINE_COOLER_TYPE]: {
    values: WineCoolerType,
    texts: WineCoolerTypeTexts,
  },
};
