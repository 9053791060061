import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import {
  GuideItemTypeConfig,
  RuleType,
} from "constants/guides/GuideItems.types";
import { RULE_DEFAULT_ALL_ALLOWED } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.rules";

import { KitchenLayoutTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_LAYOUT,
  KitchenLayoutTypes
>["floorPlan"];

const sizes: Config["sizes"] = {
  [KitchenLayoutTypes.ONE_WALL]: { width: 40, height: 40 },
  [KitchenLayoutTypes.GALLEY]: { width: 40, height: 40 },
  [KitchenLayoutTypes.L_SHAPED]: { width: 40, height: 40 },
  [KitchenLayoutTypes.U_SHAPED]: { width: 40, height: 40 },
  [KitchenLayoutTypes.PENINSULA]: { width: 40, height: 40 },
};

const rules: Config["rules"] = {
  [KitchenLayoutTypes.ONE_WALL]: RULE_DEFAULT_ALL_ALLOWED,
  [KitchenLayoutTypes.GALLEY]: RULE_DEFAULT_ALL_ALLOWED,
  [KitchenLayoutTypes.L_SHAPED]: RULE_DEFAULT_ALL_ALLOWED,
  [KitchenLayoutTypes.U_SHAPED]: RULE_DEFAULT_ALL_ALLOWED,
  [KitchenLayoutTypes.PENINSULA]: RULE_DEFAULT_ALL_ALLOWED,
};

const ruleSets: Config["ruleSets"] = {
  [KitchenLayoutTypes.ONE_WALL]: [RuleType.STAY_INSIDE],
  [KitchenLayoutTypes.GALLEY]: [RuleType.STAY_INSIDE],
  [KitchenLayoutTypes.L_SHAPED]: [RuleType.STAY_INSIDE],
  [KitchenLayoutTypes.U_SHAPED]: [RuleType.STAY_INSIDE],
  [KitchenLayoutTypes.PENINSULA]: [RuleType.STAY_INSIDE],
};

export const KitchenLayoutConfigFloorPlan: Config = {
  sizes,
  rules,
  ruleSets,
};
