import { CANONICAL_ORIGIN, IS_DEV_ENV, IS_STAGING } from "constants/env";
import { RoomsCategories } from "constants/rooms/roomCategories";
import { ROOMS_PIECES_NAMES, RoomType } from "constants/rooms/roomPieces";
import { getEphemeral } from "core/persistence";
import {
  createPropertyContext,
  getOrchestratorMachineContext,
} from "core/state/global/OrchestratorMachine/actions.utils";
import { createHIPsContext } from "core/state/global/OrchestratorMachine/hips/HIPsContext";
import { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { RoomAndSpace } from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { SIGN_UP } from "router/routes";
import { uuid } from "shared/util/uuid";

import {
  getEmbeddedGuidePartnerName,
  getEmbededPartnerConfig,
} from "./TryItOut.embeded";

export const TRY_IT_OUT_STORAGE_KEY = "try-it-out";
export const TRY_IT_OUT_BACK_URL_KEY = "demoBackHost";
const TRY_IT_OUT_PROPERTY_ID = 11;

const createRoom = (category: RoomsCategories, type: RoomType, i: number) => ({
  id: uuid(),
  category: category,
  name: `${ROOMS_PIECES_NAMES((value) => value)[type]} ${i}`,
  type,
});

const createVendorContactContext = (): OrchestratorMachineContext => {
  const base = getOrchestratorMachineContext();
  const hip = createHIPsContext();

  hip.onboarding.title = "Demo Project";
  hip.onboarding.budget = 100000;
  hip.onboarding.reserve = 30000;
  hip.planningAreas.rooms = [];
  base.properties = [
    {
      ...createPropertyContext(),
      property: {
        id: TRY_IT_OUT_PROPERTY_ID,
        num_bathrooms: 0,
        num_bedrooms: 1,
        estimated_value: 546000,
        home_sqft: 3540,
        normalized_address_line1: "1234 Demo Street",
        normalized_address_line2: "Miami, FL 33133",
      },
      hips: [hip],
      rooms: [],
    },
  ];

  return base;
};

const createTryItOutContextBase = (): OrchestratorMachineContext => {
  const base = getOrchestratorMachineContext();
  const hip = createHIPsContext();
  const rooms: RoomAndSpace[] = [
    createRoom(RoomsCategories.LIVING, "bedroom", 1),
  ];

  hip.onboarding.title = "Demo Project";
  hip.onboarding.budget = 100000;
  hip.onboarding.reserve = 30000;
  hip.planningAreas.rooms = [rooms[0]];
  base.properties = [
    {
      ...createPropertyContext(),
      property: {
        id: TRY_IT_OUT_PROPERTY_ID,
        num_bathrooms: 0,
        num_bedrooms: 1,
        estimated_value: 546000,
        home_sqft: 3540,
        normalized_address_line1: "1234 Demo Street",
        normalized_address_line2: "Miami, FL 33133",
      },
      hips: [hip],
      rooms,
    },
  ];

  return base;
};

export const createTryItOutContext = (
  isVendor = false
): OrchestratorMachineContext => {
  const partner = getEmbeddedGuidePartnerName(!isVendor);
  if (partner) {
    const config = getEmbededPartnerConfig(partner);
    return config.demoContext?.() || createTryItOutContextBase();
  }
  if (isVendor) {
    return createVendorContactContext();
  }
  return createTryItOutContextBase();
};

export const getTryItOutBaseUrl = (path: string) => {
  const backUrl = getEphemeral().getStringSync(TRY_IT_OUT_BACK_URL_KEY);
  const baseUrl =
    backUrl || (IS_DEV_ENV ? document.location.origin : CANONICAL_ORIGIN);
  const queryParam =
    IS_DEV_ENV || IS_STAGING ? "?is_try_it_out_enabled=false" : "";
  return `${baseUrl}${path}${queryParam}`;
};

export const handleCreateTryItOutAccount = () => {
  document.location.href = getTryItOutBaseUrl(SIGN_UP());
};
