import { useQueryClient } from "@tanstack/react-query";
import {
  fetchDataTryItOut,
  getIntegrationResultById,
  saveDataTryItOut,
} from "api/homebase.api";
import { SidebarForms } from "components/SidebarForms/SidebarForms";
import { SystemSnackbars } from "components/SystemSnackbars/SystemSnackbars";
import { IS_TRY_IT_OUT_ENABLED } from "constants/env";
import { useCoreContext } from "core/context/CoreContext";
import { GlobalGuards } from "core/GlobalGuards/GlobalGuards";
import { GlobalStateProvider } from "core/state/global/GlobalStateProvider";
import { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { GuideMetaContextProvider } from "pages/Guides/components/GuideMetaContextProvider/GuideMetaContextProvider";
import { useIntegrationResultById } from "pages/Vendor/hooks/useIntegrationResultById";
import { useUpdateIntegrationResultState } from "pages/Vendor/hooks/useUpdateIntegrationResultState";
import { FC, PropsWithChildren, useCallback } from "react";
import { useDidUpdate, useFreshRef, usePreviousDifferent } from "rooks";

export const GlobalStateWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { integrationId } = useCoreContext();
  const isEnabled = Boolean(integrationId);
  const { data } = useIntegrationResultById(integrationId, {
    enabled: isEnabled,
  });
  const dataRef = useFreshRef(data);
  const prevIntegrationId = usePreviousDifferent(integrationId);
  const prevData = usePreviousDifferent(data);
  const { mutateAsync: updateIntegrationResultState } =
    useUpdateIntegrationResultState(integrationId);
  const queryClient = useQueryClient();

  useDidUpdate(() => {
    if (!integrationId && prevIntegrationId) {
      queryClient.invalidateQueries({
        queryKey: ["get_appdata", Number(prevIntegrationId)],
      });
      queryClient.setQueryData(
        ["get_appdata", Number(prevIntegrationId)],
        prevData?.state.appdata
      );
    }
  }, [integrationId, prevIntegrationId, prevData]);

  const onFetchVendorData = useCallback(() => {
    return getIntegrationResultById(integrationId).then(
      (res) => res.content.state.appdata
    );
  }, [integrationId]);

  const onSaveVendorData = useCallback(
    async (state: OrchestratorMachineContext) => {
      await updateIntegrationResultState({
        data: {
          appdata: state,
          guideType: data?.state.guideType,
          assignedMap: dataRef.current?.state.assignedMap,
        },
        xEpoch: data?.state_epoch,
      });
      return;
    },
    [updateIntegrationResultState, data, dataRef]
  );

  const onFetchData = integrationId
    ? onFetchVendorData
    : IS_TRY_IT_OUT_ENABLED
    ? fetchDataTryItOut
    : undefined;
  const onSaveData = integrationId
    ? onSaveVendorData
    : IS_TRY_IT_OUT_ENABLED
    ? saveDataTryItOut
    : undefined;
  const queryKey = integrationId ?? "is_try_it_out";

  if (!onFetchData && !onSaveData) {
    return (
      <>
        <GuideMetaContextProvider>
          <GlobalGuards>{children}</GlobalGuards>
        </GuideMetaContextProvider>
        <SidebarForms />
        <SystemSnackbars />
      </>
    );
  }

  return (
    <GlobalStateProvider
      onSaveData={onSaveData}
      onFetchData={onFetchData}
      queryKey={queryKey}
    >
      <GuideMetaContextProvider>
        <GlobalGuards>{children}</GlobalGuards>
      </GuideMetaContextProvider>
      <SidebarForms />
      <SystemSnackbars />
    </GlobalStateProvider>
  );
};
