export enum GuideIntentType {
  REPLACE = "rpl",
  ADD = "add",
  REMOVE = "rem",
  MOVE = "mv",
}

export enum GuideAnimationTypes {
  SLIDE = "slide",
  FADE = "fade",
}

export enum GuideMachineId {
  CS_CONCRETE_SLAB = "cs_concrete_slab",
  ADD_CONCRETE_SLAB = "add_concrete_slab",
  RPL_CONCRETE_SLAB = "rpl_concrete_slab",
  REM_CONCRETE_SLAB = "rem_concrete_slab",
  CS_CONCRETE_EXTERIOR_WALL = "cs_concrete_exterior_wall",
  ADD_CONCRETE_EXTERIOR_WALL = "add_concrete_exterior_wall",
  RPL_CONCRETE_EXTERIOR_WALL = "rpl_concrete_exterior_wall",
  REM_CONCRETE_EXTERIOR_WALL = "rem_concrete_exterior_wall",
  CS_CONCRETE_INTERIOR_WALL = "cs_concrete_interior_wall",
  ADD_CONCRETE_INTERIOR_WALL = "add_concrete_interior_wall",
  RPL_CONCRETE_INTERIOR_WALL = "rpl_concrete_interior_wall",
  REM_CONCRETE_INTERIOR_WALL = "rem_concrete_interior_wall",
  CS_WALL_FRAME = "cs_wall_frame",
  ADD_WALL_FRAME = "add_wall_frame",
  RPL_WALL_FRAME = "rpl_wall_frame",
  MV_WALL_FRAME = "mv_wall_frame",
  REM_WALL_FRAME = "rem_wall_frame",
  CS_CEILING_JOIST = "cs_ceiling_joist",
  ADD_CEILING_JOIST = "add_ceiling_joist",
  RPL_CEILING_JOIST = "rpl_ceiling_joist",
  REM_CEILING_JOIST = "rem_ceiling_joist",
  CS_FLOOR_JOIST = "cs_floor_joist",
  ADD_FLOOR_JOIST = "add_floor_joist",
  RPL_FLOOR_JOIST = "rpl_floor_joist",
  REM_FLOOR_JOIST = "rem_floor_joist",
  CS_SUB_FLOOR = "cs_sub_floor",
  ADD_SUB_FLOOR = "add_sub_floor",
  RPL_SUB_FLOOR = "rpl_sub_floor",
  REM_SUB_FLOOR = "rem_sub_floor",
  CS_ROUGH_DOOR_FRAME = "cs_rough_door_frame",
  ADD_ROUGH_DOOR_FRAME = "add_rough_door_frame",
  RPL_ROUGH_DOOR_FRAME = "rpl_rough_door_frame",
  MV_ROUGH_DOOR_FRAME = "mv_rough_door_frame",
  REM_ROUGH_DOOR_FRAME = "rem_rough_door_frame",
  CS_ROUGH_WINDOW_FRAME = "cs_rough_window_frame",
  ADD_ROUGH_WINDOW_FRAME = "add_rough_window_frame",
  RPL_ROUGH_WINDOW_FRAME = "rpl_rough_window_frame",
  MV_ROUGH_WINDOW_FRAME = "mv_rough_window_frame",
  REM_ROUGH_WINDOW_FRAME = "rem_rough_window_frame",
  CS_STAIRCASE = "cs_staircase",
  ADD_STAIRCASE = "add_staircase",
  RPL_STAIRCASE = "rpl_staircase",
  MV_STAIRCASE = "mv_staircase",
  REM_STAIRCASE = "rem_staircase",
  CS_SOFFIT_FRAME = "cs_soffit_frame",
  ADD_SOFFIT_FRAME = "add_soffit_frame",
  RPL_SOFFIT_FRAME = "rpl_soffit_frame",
  MV_SOFFIT_FRAME = "mv_soffit_frame",
  REM_SOFFIT_FRAME = "rem_soffit_frame",
  CS_DECK = "cs_deck",
  ADD_DECK = "add_deck",
  RPL_DECK = "rpl_deck",
  MV_DECK = "mv_deck",
  REM_DECK = "rem_deck",
  CS_FURNACE = "cs_furnace",
  ADD_FURNACE = "add_furnace",
  RPL_FURNACE = "rpl_furnace",
  MV_FURNACE = "mv_furnace",
  REM_FURNACE = "rem_furnace",
  CS_BOILER = "cs_boiler",
  ADD_BOILER = "add_boiler",
  RPL_BOILER = "rpl_boiler",
  MV_BOILER = "mv_boiler",
  REM_BOILER = "rem_boiler",
  ADD_SPLIT_SYSTEM = "add_split_system",
  RPL_SPLIT_SYSTEM = "rpl_split_system",
  MV_SPLIT_SYSTEM = "mv_split_system",
  CS_MINI_SPLIT_SYSTEM = "cs_mini_split_system",
  ADD_MINI_SPLIT_SYSTEM = "add_mini_split_system",
  RPL_MINI_SPLIT_SYSTEM = "rpl_mini_split_system",
  MV_MINI_SPLIT_SYSTEM = "mv_mini_split_system",
  REM_MINI_SPLIT_SYSTEM = "rem_mini_split_system",
  CS_ELECTRICAL_SOURCE = "cs_electrical_source",
  ADD_ELECTRICAL_SOURCE = "add_electrical_source",
  RPL_ELECTRICAL_SOURCE = "rpl_electrical_source",
  MV_ELECTRICAL_SOURCE = "mv_electrical_source",
  REM_ELECTRICAL_SOURCE = "rem_electrical_source",
  CS_SOLAR_PANEL = "cs_solar_panel",
  ADD_SOLAR_PANEL = "add_solar_panel",
  RPL_SOLAR_PANEL = "rpl_solar_panel",
  MV_SOLAR_PANEL = "mv_solar_panel",
  REM_SOLAR_PANEL = "rem_solar_panel",
  CS_INTERIOR_GAS_LINE = "cs_interior_gas_line",
  ADD_INTERIOR_GAS_LINE = "add_interior_gas_line",
  RPL_INTERIOR_GAS_LINE = "rpl_interior_gas_line",
  MV_INTERIOR_GAS_LINE = "mv_interior_gas_line",
  REM_INTERIOR_GAS_LINE = "rem_interior_gas_line",
  CS_EXTERIOR_GAS_LINE = "cs_exterior_gas_line",
  ADD_EXTERIOR_GAS_LINE = "add_exterior_gas_line",
  RPL_EXTERIOR_GAS_LINE = "rpl_exterior_gas_line",
  MV_EXTERIOR_GAS_LINE = "mv_exterior_gas_line",
  REM_EXTERIOR_GAS_LINE = "rem_exterior_gas_line",
  CS_INTERIOR_WATER_LINE = "cs_interior_water_line",
  ADD_INTERIOR_WATER_LINE = "add_interior_water_line",
  RPL_INTERIOR_WATER_LINE = "rpl_interior_water_line",
  MV_INTERIOR_WATER_LINE = "mv_interior_water_line",
  REM_INTERIOR_WATER_LINE = "rem_interior_water_line",
  CS_EXTERIOR_WATER_LINE = "cs_exterior_water_line",
  ADD_EXTERIOR_WATER_LINE = "add_exterior_water_line",
  RPL_EXTERIOR_WATER_LINE = "rpl_exterior_water_line",
  MV_EXTERIOR_WATER_LINE = "mv_exterior_water_line",
  REM_EXTERIOR_WATER_LINE = "rem_exterior_water_line",
  CS_WATER_SOURCE = "cs_water_source",
  ADD_WATER_SOURCE = "add_water_source",
  RPL_WATER_SOURCE = "rpl_water_source",
  MV_WATER_SOURCE = "mv_water_source",
  REM_WATER_SOURCE = "rem_water_source",
  CS_SUMP_PUMP = "cs_sump_pump",
  ADD_SUMP_PUMP = "add_sump_pump",
  RPL_SUMP_PUMP = "rpl_sump_pump",
  MV_SUMP_PUMP = "mv_sump_pump",
  REM_SUMP_PUMP = "rem_sump_pump",
  CS_INTERIOR_SEWER_LINE = "cs_interior_sewer_line",
  ADD_INTERIOR_SEWER_LINE = "add_interior_sewer_line",
  RPL_INTERIOR_SEWER_LINE = "rpl_interior_sewer_line",
  REM_INTERIOR_SEWER_LINE = "rem_interior_sewer_line",
  CS_EXTERIOR_SEWER_LINE = "cs_exterior_sewer_line",
  ADD_EXTERIOR_SEWER_LINE = "add_exterior_sewer_line",
  RPL_EXTERIOR_SEWER_LINE = "rpl_exterior_sewer_line",
  REM_EXTERIOR_SEWER_LINE = "rem_exterior_sewer_line",
  CS_SEWER_SYSTEM = "cs_sewer_system",
  ADD_SEWER_SYSTEM = "add_sewer_system",
  RPL_SEWER_SYSTEM = "rpl_sewer_system",
  MV_SEWER_SYSTEM = "mv_sewer_system",
  REM_SEWER_SYSTEM = "rem_sewer_system",
  CS_WATER_HEATER = "cs_water_heater",
  ADD_WATER_HEATER = "add_water_heater",
  RPL_WATER_HEATER = "rpl_water_heater",
  MV_WATER_HEATER = "mv_water_heater",
  REM_WATER_HEATER = "rem_water_heater",
  CS_MUNICIPAL_GAS = "cs_municipal_gas",
  ADD_MUNICIPAL_GAS = "add_municipal_gas",
  MV_MUNICIPAL_GAS = "mv_municipal_gas",
  REM_MUNICIPAL_GAS = "rem_municipal_gas",
  CS_WATER_SHUT_OFF_VALVE = "cs_water_shut_off_valve",
  ADD_WATER_SHUT_OFF_VALVE = "add_water_shut_off_valve",
  RPL_WATER_SHUT_OFF_VALVE = "rpl_water_shut_off_valve",
  MV_WATER_SHUT_OFF_VALVE = "mv_water_shut_off_valve",
  REM_WATER_SHUT_OFF_VALVE = "rem_water_shut_off_valve",
  CS_INTERIOR_INSULATION = "cs_interior_insulation",
  CS_EXTERIOR_INSULATION = "cs_exterior_insulation",
  ADD_INTERIOR_INSULATION = "add_interior_insulation",
  RPL_INTERIOR_INSULATION = "rpl_interior_insulation",
  REM_INTERIOR_INSULATION = "rem_interior_insulation",
  ADD_EXTERIOR_INSULATION = "add_exterior_insulation",
  RPL_EXTERIOR_INSULATION = "rpl_exterior_insulation",
  REM_EXTERIOR_INSULATION = "rem_exterior_insulation",
  CS_CRAWL_SPACE_VENT = "cs_crawl_space_vent",
  ADD_CRAWL_SPACE_VENT = "add_crawl_space_vent",
  RPL_CRAWL_SPACE_VENT = "rpl_crawl_space_vent",
  MV_CRAWL_SPACE_VENT = "mv_crawl_space_vent",
  REM_CRAWL_SPACE_VENT = "rem_crawl_space_vent",
  CS_ROOF_VENT = "cs_roof_vent",
  ADD_ROOF_VENT = "add_roof_vent",
  RPL_ROOF_VENT = "rpl_roof_vent",
  MV_ROOF_VENT = "mv_roof_vent",
  REM_ROOF_VENT = "rem_roof_vent",
  CS_BASEMENT_VENT = "cs_basement_vent",
  ADD_BASEMENT_VENT = "add_basement_vent",
  RPL_BASEMENT_VENT = "rpl_basement_vent",
  MV_BASEMENT_VENT = "mv_basement_vent",
  REM_BASEMENT_VENT = "rem_basement_vent",
  CS_BATHROOM_VENT = "cs_bathroom_vent",
  ADD_BATHROOM_VENT = "add_bathroom_vent",
  RPL_BATHROOM_VENT = "rpl_bathroom_vent",
  MV_BATHROOM_VENT = "mv_bathroom_vent",
  REM_BATHROOM_VENT = "rem_bathroom_vent",
  CS_DOOR = "cs_door",
  ADD_DOOR = "add_door",
  RPL_DOOR = "rpl_door",
  MV_DOOR = "mv_door",
  REM_DOOR = "rem_door",
  CS_WINDOW = "cs_window",
  ADD_WINDOW = "add_window",
  RPL_WINDOW = "rpl_window",
  MV_WINDOW = "mv_window",
  REM_WINDOW = "rem_window",
  CS_SKYLIGHT = "cs_skylight",
  ADD_SKYLIGHT = "add_skylight",
  RPL_SKYLIGHT = "rpl_skylight",
  MV_SKYLIGHT = "mv_skylight",
  REM_SKYLIGHT = "rem_skylight",
  CS_GATE = "cs_gate",
  ADD_GATE = "add_gate",
  RPL_GATE = "rpl_gate",
  MV_GATE = "mv_gate",
  REM_GATE = "rem_gate",
  ADD_KITCHEN_CABINET_DRAWER = "add_kitchen_cabinet_drawer",
  ADD_KITCHEN_CABINET_INTERIOR = "add_kitchen_cabinet_interior",
  CS_KITCHEN_CABINET = "cs_kitchen_cabinet",
  ADD_KITCHEN_CABINET = "add_kitchen_cabinet",
  RPL_KITCHEN_CABINET = "rpl_kitchen_cabinet",
  MV_KITCHEN_CABINET = "mv_kitchen_cabinet",
  REM_KITCHEN_CABINET = "rem_kitchen_cabinet",
  CS_KITCHEN_CABINET_DOOR = "cs_kitchen_cabinet_door",
  ADD_KITCHEN_CABINET_DOOR = "add_kitchen_cabinet_door",
  RPL_KITCHEN_CABINET_DOOR = "rpl_kitchen_cabinet_door",
  MV_KITCHEN_CABINET_DOOR = "mv_kitchen_cabinet_door",
  REM_KITCHEN_CABINET_DOOR = "rem_kitchen_cabinet_door",
  CS_KITCHEN_APPLIANCE = "cs_kitchen_appliance",
  ADD_KITCHEN_APPLIANCE = "add_kitchen_appliance",
  RPL_KITCHEN_APPLIANCE = "rpl_kitchen_appliance",
  MV_KITCHEN_APPLIANCE = "mv_kitchen_appliance",
  REM_KITCHEN_APPLIANCE = "rem_kitchen_appliance",
  CS_KITCHEN_RANGE = "cs_kitchen_range",
  ADD_KITCHEN_RANGE = "add_kitchen_range",
  RPL_KITCHEN_RANGE = "rpl_kitchen_range",
  MV_KITCHEN_RANGE = "mv_kitchen_range",
  REM_KITCHEN_RANGE = "rem_kitchen_range",
  CS_REFRIGERATOR_FREEZER = "cs_refrigerator_freezer",
  ADD_REFRIGERATOR_FREEZER = "add_refrigerator_freezer",
  RPL_REFRIGERATOR_FREEZER = "rpl_refrigerator_freezer",
  MV_REFRIGERATOR_FREEZER = "mv_refrigerator_freezer",
  REM_REFRIGERATOR_FREEZER = "rem_refrigerator_freezer",
  CS_KITCHEN_FREEZER_DRAWER = "cs_kitchen_freezer_drawer",
  ADD_KITCHEN_FREEZER_DRAWER = "add_kitchen_freezer_drawer",
  CS_KITCHEN_WINE_COOLER = "cs_kitchen_wine_cooler",
  ADD_KITCHEN_WINE_COOLER = "add_kitchen_wine_cooler",
  CS_KITCHEN_BEVERAGE_COOLER = "cs_kitchen_beverage_cooler",
  ADD_KITCHEN_BEVERAGE_COOLER = "add_kitchen_beverage_cooler",
  CS_KITCHEN_REFRIGERATED_DRAWER = "cs_kitchen_refrigerated_drawer",
  ADD_KITCHEN_REFRIGERATED_DRAWER = "add_kitchen_refrigerated_drawer",
  CS_KITCHEN_WARMING_DRAWER = "cs_kitchen_warming_drawer",
  ADD_KITCHEN_WARMING_DRAWER = "add_kitchen_warming_drawer",
  CS_KITCHEN_TRASH_COMPACTOR = "cs_kitchen_trash_compactor",
  ADD_KITCHEN_TRASH_COMPACTOR = "add_kitchen_trash_compactor",
  CS_KITCHEN_COUNTERTOP = "cs_kitchen_countertop",
  ADD_KITCHEN_COUNTERTOP = "add_kitchen_countertop",
  RPL_KITCHEN_COUNTERTOP = "rpl_kitchen_countertop",
  MV_KITCHEN_COUNTERTOP = "mv_kitchen_countertop",
  REM_KITCHEN_COUNTERTOP = "rem_kitchen_countertop",
  CS_KITCHEN_BACKSPLASH = "cs_kitchen_backsplash",
  ADD_KITCHEN_BACKSPLASH = "add_kitchen_backsplash",
  RPL_KITCHEN_BACKSPLASH = "rpl_kitchen_backsplash",
  MV_KITCHEN_BACKSPLASH = "mv_kitchen_backsplash",
  REM_KITCHEN_BACKSPLASH = "rem_kitchen_backsplash",
  CS_KITCHEN_ICE_MACHINE = "cs_kitchen_ice_machine",
  ADD_KITCHEN_ICE_MACHINE = "add_kitchen_ice_machine",
  CS_KITCHEN_MICROWAVE = "cs_kitchen_microwave",
  ADD_KITCHEN_MICROWAVE = "add_kitchen_microwave",
  CS_KITCHEN_FIXTURE = "cs_kitchen_fixture",
  ADD_KITCHEN_FIXTURE = "add_kitchen_fixture",
  CS_KITCHEN_SINK = "cs_kitchen_sink",
  ADD_KITCHEN_SINK = "add_kitchen_sink",
  CS_KITCHEN_FAUCET = "cs_kitchen_faucet",
  ADD_KITCHEN_FAUCET = "add_kitchen_faucet",
  CS_KITCHEN_WASTE_DISPOSAL = "cs_kitchen_waste_disposal",
  ADD_KITCHEN_WASTE_DISPOSAL = "add_kitchen_waste_disposal",
  CS_KITCHEN_WATER_FILTER = "cs_kitchen_water_filter",
  ADD_KITCHEN_WATER_FILTER = "add_kitchen_water_filter",
  CS_BATHROOM_VANITY = "cs_bathroom_vanity",
  ADD_BATHROOM_VANITY = "add_bathroom_vanity",
  RPL_BATHROOM_VANITY = "rpl_bathroom_vanity",
  MV_BATHROOM_VANITY = "mv_bathroom_vanity",
  REM_BATHROOM_VANITY = "rem_bathroom_vanity",
  CS_BATHROOM_SINK = "cs_bathroom_sink",
  ADD_BATHROOM_SINK = "add_bathroom_sink",
  RPL_BATHROOM_SINK = "rpl_bathroom_sink",
  MV_BATHROOM_SINK = "mv_bathroom_sink",
  REM_BATHROOM_SINK = "rem_bathroom_sink",
  CS_BATHROOM_SINK_FAUCET_AND_CONTROL = "cs_bathroom_sink_faucet_and_control",
  ADD_BATHROOM_SINK_FAUCET_AND_CONTROL = "add_bathroom_sink_faucet_and_control",
  RPL_BATHROOM_SINK_FAUCET_AND_CONTROL = "rpl_bathroom_sink_faucet_and_control",
  MV_BATHROOM_SINK_FAUCET_AND_CONTROL = "mv_bathroom_sink_faucet_and_control",
  REM_BATHROOM_SINK_FAUCET_AND_CONTROL = "rem_bathroom_sink_faucet_and_control",
  CS_BATHTUB = "cs_bathtub",
  ADD_BATHTUB = "add_bathtub",
  RPL_BATHTUB = "rpl_bathtub",
  MV_BATHTUB = "mv_bathtub",
  REM_BATHTUB = "rem_bathtub",
  CS_BATHTUB_FAUCET_AND_CONTROL = "cs_bathtub_faucet_and_control",
  ADD_BATHTUB_FAUCET_AND_CONTROL = "add_bathtub_faucet_and_control",
  RPL_BATHTUB_FAUCET_AND_CONTROL = "rpl_bathtub_faucet_and_control",
  MV_BATHTUB_FAUCET_AND_CONTROL = "mv_bathtub_faucet_and_control",
  REM_BATHTUB_FAUCET_AND_CONTROL = "rem_bathtub_faucet_and_control",
  CS_SHOWER = "cs_shower",
  ADD_SHOWER = "add_shower",
  RPL_SHOWER = "rpl_shower",
  MV_SHOWER = "mv_shower",
  REM_SHOWER = "rem_shower",
  CS_SHOWER_FAUCET_AND_CONTROL = "cs_shower_faucet_and_control",
  ADD_SHOWER_FAUCET_AND_CONTROL = "add_shower_faucet_and_control",
  RPL_SHOWER_FAUCET_AND_CONTROL = "rpl_shower_faucet_and_control",
  MV_SHOWER_FAUCET_AND_CONTROL = "mv_shower_faucet_and_control",
  REM_SHOWER_FAUCET_AND_CONTROL = "rem_shower_faucet_and_control",
  CS_SHOWER_GLASS = "cs_shower_glass",
  ADD_SHOWER_GLASS = "add_shower_glass",
  RPL_SHOWER_GLASS = "rpl_shower_glass",
  MV_SHOWER_GLASS = "mv_shower_glass",
  REM_SHOWER_GLASS = "rem_shower_glass",
  CS_TOILET = "cs_toilet",
  ADD_TOILET = "add_toilet",
  RPL_TOILET = "rpl_toilet",
  MV_TOILET = "mv_toilet",
  REM_TOILET = "rem_toilet",
  ADD_WALL_MATERIAL = "add_wall_material",
  RPL_WALL_MATERIAL = "rpl_wall_material",
  REM_WALL_MATERIAL = "rem_wall_material",
  CS_CEILING_MATERIAL = "cs_ceiling_material",
  ADD_CEILING_MATERIAL = "add_ceiling_material",
  RPL_CEILING_MATERIAL = "rpl_ceiling_material",
  REM_CEILING_MATERIAL = "rem_ceiling_material",
  CS_INTERIOR_CEILING_MATERIAL = "cs_interior_ceiling_material",
  ADD_INTERIOR_CEILING_MATERIAL = "add_interior_ceiling_material",
  RPL_INTERIOR_CEILING_MATERIAL = "rpl_interior_ceiling_material",
  REM_INTERIOR_CEILING_MATERIAL = "rem_interior_ceiling_material",
  CS_INTERIOR_WALL_MATERIAL = "cs_interior_wall_material",
  ADD_INTERIOR_WALL_MATERIAL = "add_interior_wall_material",
  RPL_INTERIOR_WALL_MATERIAL = "rpl_interior_wall_material",
  REM_INTERIOR_WALL_MATERIAL = "rem_interior_wall_material",
  CS_EXTERIOR_WALL_MATERIAL = "cs_exterior_wall_material",
  ADD_EXTERIOR_WALL_MATERIAL = "add_exterior_wall_material",
  RPL_EXTERIOR_WALL_MATERIAL = "rpl_exterior_wall_material",
  REM_EXTERIOR_WALL_MATERIAL = "rem_exterior_wall_material",
  CS_EXTERIOR_CEILING_MATERIAL = "cs_exterior_ceiling_material",
  ADD_EXTERIOR_CEILING_MATERIAL = "add_exterior_ceiling_material",
  RPL_EXTERIOR_CEILING_MATERIAL = "rpl_exterior_ceiling_material",
  REM_EXTERIOR_CEILING_MATERIAL = "rem_exterior_ceiling_material",
  CS_INTERIOR_FLOOR_MATERIAL = "cs_interior_floor_material",
  ADD_INTERIOR_FLOOR_MATERIAL = "add_interior_floor_material",
  RPL_INTERIOR_FLOOR_MATERIAL = "rpl_interior_floor_material",
  REM_INTERIOR_FLOOR_MATERIAL = "rem_interior_floor_material",
  ADD_INTERIOR_FLOOR_MATERIAL_TILE = "add_interior_floor_material_tile",
  ADD_INTERIOR_FLOOR_MATERIAL_WOOD = "add_interior_floor_material_wood",
  ADD_INTERIOR_FLOOR_MATERIAL_CARPET = "add_interior_floor_material_carpet",
  ADD_INTERIOR_FLOOR_MATERIAL_CONCRETE = "add_interior_floor_material_concrete",
  ADD_INTERIOR_FLOOR_MATERIAL_BRICK = "add_interior_floor_material_brick",
  CS_EXTERIOR_FLOOR_MATERIAL = "cs_exterior_floor_material",
  ADD_EXTERIOR_FLOOR_MATERIAL = "add_exterior_floor_material",
  RPL_EXTERIOR_FLOOR_MATERIAL = "rpl_exterior_floor_material",
  REM_EXTERIOR_FLOOR_MATERIAL = "rem_exterior_floor_material",
  CS_BASEBOARD = "cs_baseboard",
  ADD_BASEBOARD = "add_baseboard",
  RPL_BASEBOARD = "rpl_baseboard",
  REM_BASEBOARD = "rem_baseboard",
  CS_CROWN_MOULDING = "cs_crown_moulding",
  ADD_CROWN_MOULDING = "add_crown_moulding",
  RPL_CROWN_MOULDING = "rpl_crown_moulding",
  REM_CROWN_MOULDING = "rem_crown_moulding",
  CS_DOOR_CASING_AND_TRIM = "cs_door_casing_and_trim",
  ADD_DOOR_CASING_AND_TRIM = "add_door_casing_and_trim",
  RPL_DOOR_CASING_AND_TRIM = "rpl_door_casing_and_trim",
  REM_DOOR_CASING_AND_TRIM = "rem_door_casing_and_trim",
  CS_WINDOW_CASING_AND_TRIM = "cs_window_casing_and_trim",
  ADD_WINDOW_CASING_AND_TRIM = "add_window_casing_and_trim",
  RPL_WINDOW_CASING_AND_TRIM = "rpl_window_casing_and_trim",
  REM_WINDOW_CASING_AND_TRIM = "rem_window_casing_and_trim",
  CS_WALL_PANELING = "cs_wall_paneling",
  ADD_WALL_PANELING = "add_wall_paneling",
  RPL_WALL_PANELING = "rpl_wall_paneling",
  REM_WALL_PANELING = "rem_wall_paneling",
  CS_CEILING_PANELING = "cs_ceiling_paneling",
  ADD_CEILING_PANELING = "add_ceiling_paneling",
  RPL_CEILING_PANELING = "rpl_ceiling_paneling",
  REM_CEILING_PANELING = "rem_ceiling_paneling",
  CS_WAINSCOTING = "cs_wainscoting",
  ADD_WAINSCOTING = "add_wainscoting",
  RPL_WAINSCOTING = "rpl_wainscoting",
  REM_WAINSCOTING = "rem_wainscoting",
  CS_CHAIR_RAIL = "cs_chair_rail",
  ADD_CHAIR_RAIL = "add_chair_rail",
  RPL_CHAIR_RAIL = "rpl_chair_rail",
  REM_CHAIR_RAIL = "rem_chair_rail",
  CS_SHELVING = "cs_shelving",
  ADD_SHELVING = "add_shelving",
  RPL_SHELVING = "rpl_shelving",
  MV_SHELVING = "mv_shelving",
  REM_SHELVING = "rem_shelving",
  CS_PICTURE_FRAME_MOULDING = "cs_picture_frame_moulding",
  ADD_PICTURE_FRAME_MOULDING = "add_picture_frame_moulding",
  RPL_PICTURE_FRAME_MOULDING = "rpl_picture_frame_moulding",
  REM_PICTURE_FRAME_MOULDING = "rem_picture_frame_moulding",
  CS_PAINT = "cs_paint",
  ADD_PAINT = "add_paint",
  RPL_PAINT = "rpl_paint",
  REM_PAINT = "rem_paint",
  CS_STAIN = "cs_stain",
  ADD_STAIN = "add_stain",
  RPL_STAIN = "rpl_stain",
  REM_STAIN = "rem_stain",
  CS_PROTECTIVE_FINISH = "cs_protective_finish",
  ADD_PROTECTIVE_FINISH = "add_protective_finish",
  RPL_PROTECTIVE_FINISH = "rpl_protective_finish",
  REM_PROTECTIVE_FINISH = "rem_protective_finish",
  CS_SEALER = "cs_sealer",
  ADD_SEALER = "add_sealer",
  RPL_SEALER = "rpl_sealer",
  REM_SEALER = "rem_sealer",
  CS_PLASTER = "cs_plaster",
  ADD_PLASTER = "add_plaster",
  RPL_PLASTER = "rpl_plaster",
  REM_PLASTER = "rem_plaster",
  CS_ALARM_CONTROL_PANEL = "cs_alarm_control_panel",
  ADD_ALARM_CONTROL_PANEL = "add_alarm_control_panel",
  RPL_ALARM_CONTROL_PANEL = "rpl_alarm_control_panel",
  MV_ALARM_CONTROL_PANEL = "mv_alarm_control_panel",
  REM_ALARM_CONTROL_PANEL = "rem_alarm_control_panel",
  CS_DOOR_AND_WINDOW_ALARM = "cs_door_and_window_alarm",
  ADD_DOOR_AND_WINDOW_ALARM = "add_door_and_window_alarm",
  RPL_DOOR_AND_WINDOW_ALARM = "rpl_door_and_window_alarm",
  MV_DOOR_AND_WINDOW_ALARM = "mv_door_and_window_alarm",
  REM_DOOR_AND_WINDOW_ALARM = "rem_door_and_window_alarm",
  CS_MOTION_SENSOR = "cs_motion_sensor",
  ADD_MOTION_SENSOR = "add_motion_sensor",
  RPL_MOTION_SENSOR = "rpl_motion_sensor",
  MV_MOTION_SENSOR = "mv_motion_sensor",
  REM_MOTION_SENSOR = "rem_motion_sensor",
  CS_INTERIOR_CAMERA = "cs_interior_camera",
  ADD_INTERIOR_CAMERA = "add_interior_camera",
  RPL_INTERIOR_CAMERA = "rpl_interior_camera",
  MV_INTERIOR_CAMERA = "mv_interior_camera",
  REM_INTERIOR_CAMERA = "rem_interior_camera",
  CS_EXTERIOR_CAMERA = "cs_exterior_camera",
  ADD_EXTERIOR_CAMERA = "add_exterior_camera",
  RPL_EXTERIOR_CAMERA = "rpl_exterior_camera",
  MV_EXTERIOR_CAMERA = "mv_exterior_camera",
  REM_EXTERIOR_CAMERA = "rem_exterior_camera",
  CS_DORMER = "cs_dormer",
  ADD_DORMER = "add_dormer",
  RPL_DORMER = "rpl_dormer",
  REM_DORMER = "rem_dormer",
  CS_DRIP_EDGE = "cs_drip_edge",
  ADD_DRIP_EDGE = "add_drip_edge",
  RPL_DRIP_EDGE = "rpl_drip_edge",
  REM_DRIP_EDGE = "rem_drip_edge",
  CS_DRAIN = "cs_drain",
  ADD_DRAIN = "add_drain",
  RPL_DRAIN = "rpl_drain",
  MV_DRAIN = "mv_drain",
  REM_DRAIN = "rem_drain",
  CS_GUTTER = "cs_gutter",
  ADD_GUTTER = "add_gutter",
  RPL_GUTTER = "rpl_gutter",
  MV_GUTTER = "mv_gutter",
  REM_GUTTER = "rem_gutter",
  CS_ROOF = "cs_roof",
  ADD_ROOF = "add_roof",
  RPL_ROOF = "rpl_roof",
  REM_ROOF = "rem_roof",
  CS_POOL_FILTER = "cs_pool_filter",
  ADD_POOL_FILTER = "add_pool_filter",
  RPL_POOL_FILTER = "rpl_pool_filter",
  MV_POOL_FILTER = "mv_pool_filter",
  REM_POOL_FILTER = "rem_pool_filter",
  CS_SWIMMING_POOL = "cs_swimming_pool",
  ADD_SWIMMING_POOL = "add_swimming_pool",
  RPL_SWIMMING_POOL = "rpl_swimming_pool",
  REM_SWIMMING_POOL = "rem_swimming_pool",
  CS_POOL_PUMP = "cs_pool_pump",
  ADD_POOL_PUMP = "add_pool_pump",
  RPL_POOL_PUMP = "rpl_pool_pump",
  MV_POOL_PUMP = "mv_pool_pump",
  REM_POOL_PUMP = "rem_pool_pump",
  CS_POOL_CLEANING_SYSTEM = "cs_pool_cleaning_system",
  ADD_POOL_CLEANING_SYSTEM = "add_pool_cleaning_system",
  RPL_POOL_CLEANING_SYSTEM = "rpl_pool_cleaning_system",
  MV_POOL_CLEANING_SYSTEM = "mv_pool_cleaning_system",
  REM_POOL_CLEANING_SYSTEM = "rem_pool_cleaning_system",
  CS_POOL_LIGHTING = "cs_pool_lighting",
  ADD_POOL_LIGHTING = "add_pool_lighting",
  RPL_POOL_LIGHTING = "rpl_pool_lighting",
  MV_POOL_LIGHTING = "mv_pool_lighting",
  REM_POOL_LIGHTING = "rem_pool_lighting",
  CS_POOL_DRAIN = "cs_pool_drain",
  ADD_POOL_DRAIN = "add_pool_drain",
  RPL_POOL_DRAIN = "rpl_pool_drain",
  MV_POOL_DRAIN = "mv_pool_drain",
  REM_POOL_DRAIN = "rem_pool_drain",
  CS_WATER_FEATURE = "cs_water_feature",
  ADD_WATER_FEATURE = "add_water_feature",
  RPL_WATER_FEATURE = "rpl_water_feature",
  REM_WATER_FEATURE = "rem_water_feature",
  CS_POOL_SURFACE = "cs_pool_surface",
  ADD_POOL_SURFACE = "add_pool_surface",
  RPL_POOL_SURFACE = "rpl_pool_surface",
  REM_POOL_SURFACE = "rem_pool_surface",
  CS_EXTERIOR_WALL = "cs_exterior_wall",
  ADD_EXTERIOR_WALL = "add_exterior_wall",
  RPL_EXTERIOR_WALL = "rpl_exterior_wall",
  REM_EXTERIOR_WALL = "rem_exterior_wall",
  CS_DRIVEWAY = "cs_driveway",
  ADD_DRIVEWAY = "add_driveway",
  RPL_DRIVEWAY = "rpl_driveway",
  REM_DRIVEWAY = "rem_driveway",
  CS_WALKWAY = "cs_walkway",
  ADD_WALKWAY = "add_walkway",
  RPL_WALKWAY = "rpl_walkway",
  REM_WALKWAY = "rem_walkway",
  CS_FIRE_PIT = "cs_fire_pit",
  ADD_FIRE_PIT = "add_fire_pit",
  RPL_FIRE_PIT = "rpl_fire_pit",
  REM_FIRE_PIT = "rem_fire_pit",
  CS_EXTERIOR_STAIRCASE = "cs_exterior_staircase",
  ADD_EXTERIOR_STAIRCASE = "add_exterior_staircase",
  RPL_EXTERIOR_STAIRCASE = "rpl_exterior_staircase",
  REM_EXTERIOR_STAIRCASE = "rem_exterior_staircase",
  CS_RETAINING_WALL = "cs_retaining_wall",
  ADD_RETAINING_WALL = "add_retaining_wall",
  RPL_RETAINING_WALL = "rpl_retaining_wall",
  MV_RETAINING_WALL = "mv_retaining_wall",
  REM_RETAINING_WALL = "rem_retaining_wall",
  CS_FENCE = "cs_fence",
  ADD_FENCE = "add_fence",
  RPL_FENCE = "rpl_fence",
  REM_FENCE = "rem_fence",
  ADD_DOOR_MATERIAL = "add_door_material",
  ADD_WINDOW_MATERIAL = "add_window_material",
  ADD_FULL_KITCHEN_LIGHTING_CONTROL = "add_full_kitchen_lighting_control",
  ADD_FULL_BATHROOM_LIGHTING_CONTROL = "add_full_bathroom_lighting_control",
  ADD_FULL_BATHROOM_LIGHTING_FIXTURE = "add_full_bathroom_lighting_fixture",
  ADD_FULL_KITCHEN_LIGHTING_FIXTURE = "add_full_kitchen_lighting_fixture",
  ADD_FULL_KITCHEN_OUTLET = "add_full_kitchen_outlet",
  ADD_FULL_BATHROOM_OUTLET = "add_full_bathroom_outlet",
  CS_KITCHEN_LAYOUT = "cs_kitchen_layout",
  ELECTRICAL_PANEL_COMBINED_CONNECT = "electrical_panel_combined_connect",
  CS_KITCHEN_DISHWASHER = "cs_kitchen_dishwasher",
  ADD_KITCHEN_DISHWASHER = "add_kitchen_dishwasher",
  CS_KITCHEN_COOKTOP = "cs_kitchen_cooktop",
  ADD_KITCHEN_COOKTOP = "add_kitchen_cooktop",
  CS_KITCHEN_RANGE_TOP = "cs_kitchen_range_top",
  ADD_KITCHEN_RANGE_TOP = "add_kitchen_range_top",
  CS_KITCHEN_OVEN_VENT = "cs_kitchen_oven_vent",
  ADD_KITCHEN_OVEN_VENT = "add_kitchen_oven_vent",
  CS_KITCHEN_WALL_OVEN = "cs_kitchen_wall_oven",
  ADD_KITCHEN_WALL_OVEN = "add_kitchen_wall_oven",
  ADD_DOOR_FINISHES_PAINT = "add_door_finishes_paint",
  ADD_DOOR_FINISH_STAIN = "add_door_finish_stain",
  ADD_WINDOW_FINISH_STAIN = "add_window_finish_stain",
  ADD_WINDOW_FINISH_PAINT = "add_window_finish_paint",
  PLACEHOLDER_MACHINE = "placeholder_machine",
  SHOP_PARTNER_MACHINE = "shop_partner_machine",
  CS_LIGHTING_FIXTURE = "cs_lighting_fixture",
  ADD_LIGHTING_FIXTURE = "add_lighting_fixture",
  RPL_LIGHTING_FIXTURE = "rpl_lighting_fixture",
  MV_LIGHTING_FIXTURE = "mv_lighting_fixture",
  REM_LIGHTING_FIXTURE = "rem_lighting_fixture",
  CS_LIGHTING_CONTROL = "cs_lighting_control",
  ADD_LIGHTING_CONTROL = "add_lighting_control",
  MOV_LIGHTING_CONTROL = "mov_lighting_control",
  RPL_LIGHTING_CONTROL = "rpl_lighting_control",
  REM_LIGHTING_CONTROL = "rem_lighting_control",
  LIGHTING_FIXTURE_CONTROL_COMBINED_CONNECT = "lighting_fixture_control_combined_connect",
  CS_ELECTRICAL_PANEL = "cs_electrical_panel",
  ADD_ELECTRICAL_PANEL = "add_electrical_panel",
  RPL_ELECTRICAL_PANEL = "rpl_electrical_panel",
  REM_ELECTRICAL_PANEL = "rem_electrical_panel",
  MV_ELECTRICAL_PANEL = "mv_electrical_panel",
  CS_WALL_MATERIAL = "cs_wall_material",
  CS_SPLIT_SYSTEM = "cs_split_system",
  REM_SPLIT_SYSTEM = "rem_split_system",
  ADD_ROOM_LAYOUT = "add_room_layout",
  CS_OUTLET = "cs_outlet",
  ADD_OUTLET = "add_outlet",
  RPL_OUTLET = "rpl_outlet",
  MOV_OUTLET = "mov_outlet",
  REM_OUTLET = "rem_outlet",
  CS_CEILING_FAN = "cs_ceiling_fan",
  ADD_CEILING_FAN = "add_ceiling_fan",
  RPL_CEILING_FAN = "rpl_ceiling_fan",
  MV_CEILING_FAN = "mv_ceiling_fan",
  REM_CEILING_FAN = "rem_ceiling_fan",
  CS_CEILING_FAN_CONTROL = "cs_ceiling_fan_control",
  ADD_CEILING_FAN_CONTROL = "add_ceiling_fan_control",
  RPL_CEILING_FAN_CONTROL = "rpl_ceiling_fan_control",
  MV_CEILING_FAN_CONTROL = "mv_ceiling_fan_control",
  REM_CEILING_FAN_CONTROL = "rem_ceiling_fan_control",
  CEILING_FAN_CONTROL_COMBINED_CONNECT = "ceiling_fan_control_combined_connect",
  CS_SMOKE_ALARM = "cs_smoke_alarm",
  ADD_SMOKE_ALARM = "add_smoke_alarm",
  RPL_SMOKE_ALARM = "rpl_smoke_alarm",
  MV_SMOKE_ALARM = "mv_smoke_alarm",
  REM_SMOKE_ALARM = "rem_smoke_alarm",
}
