import { presetCategoryData } from "api/static/getPresetCategoryData";
import { getGroupedMap } from "common/utils/listUtils";
import { ArrayElement } from "common/utils/typeUtils";
import { getGuideItemTypeCategoryFromSubcategory } from "constants/categories/category.utils";
import { GuideType } from "constants/guides/GuideType";
import { PresetGroupId } from "constants/presets/PresetGroup.types";
import { PresetGroupItemId } from "constants/presets/PresetGroupItem.types";
import { SUBCATEGORY_TAXONOMY_ORDER } from "constants/subcategories/subcategory.types";
import {
  OrchestratorHIPSContext,
  OrchestratorSOWGuide,
  OrchestratorSOWGuideStatus,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { useOrchestratorActiveGuides } from "core/state/hooks/useOrchestratorActiveGuides";
import { useOrchestratorHIP } from "core/state/hooks/useOrchestratorHIPs";
import { useOrchestratorRoomsAndSpaces } from "core/state/hooks/useOrchestratorRoomsAndSpaces";
import { flatten, uniq } from "lodash-es";
import {
  EXCLUDED_GUIDE_RESULTS_MACHINE_IDS,
  FAKE_GUIDE_TYPES,
  GUIDE_INTENTS_ORDER,
  GUIDE_INTENTS_ORDER_MAP,
} from "pages/Guides/consts";
import { GuideIntentType } from "pages/Guides/enums";
import { BaseActiveGuideContext, GuideMachineEntry } from "pages/Guides/types";
import { isGuideOnIDKPause } from "pages/Guides/utils/utils.results";
import {
  CategoryPanelStatus,
  SubCategoryStatus,
} from "pages/HIPs/HIPsRoom/types";
import { orderCategories } from "pages/HIPs/ProjectScope/modules/Tasks/HIPsSOWTasks.utils";
import {
  RoomAndSpace,
  RoomGuideResults,
} from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { useMemo } from "react";
import { useHIPsParams } from "router/hooks/useHipsParams";
import { findAll } from "shared/util/findAll";
import { findBy } from "shared/util/findBy";
import { getKeys } from "shared/util/getKeys";

type PlanningAreasGuidesMap = Record<string, OrchestratorSOWGuide[]>;
type PlanningAreasGuideResultsMap = Record<string, RoomGuideResults>;

export const filterExcludedGuideResultsMachineIdsFn = (
  machine: GuideMachineEntry
) => {
  return !EXCLUDED_GUIDE_RESULTS_MACHINE_IDS.includes(machine.machineId);
};

export const getHIPAddedCategoriesCount = (hip: OrchestratorHIPSContext) => {
  const guideTypes = hip.scopeOfWork.guides.map((guide) => guide.type);
  const guideResultsTypes = hip.planningAreas.rooms
    .flatMap((room) =>
      room.guideResults?.machines
        .filter(filterExcludedGuideResultsMachineIdsFn)
        .map((machine) => machine.meta.type)
    )
    .filter(Boolean);

  const merged = new Set([...guideTypes, ...guideResultsTypes]);

  return merged.size;
};

export const getTotalCategories = (
  guides: OrchestratorSOWGuide[] = [],
  guideResults: RoomGuideResults
) => {
  const categoriesSet = new Set<GuideType>();

  guides.forEach((guide) => {
    categoriesSet.add(guide.type);
  });

  guideResults?.machines
    .filter(filterExcludedGuideResultsMachineIdsFn)
    .forEach((machine) => {
      categoriesSet.add(machine.meta.type);
    });

  return categoriesSet;
};

export const getGuideResultsMachines = (machines: GuideMachineEntry[] = []) => {
  return machines.filter((machine) => {
    return (
      !machine.machineId.startsWith("cs_") &&
      filterExcludedGuideResultsMachineIdsFn(machine)
    );
  });
};

export const getDetailsFromMachineEntry = (machine: GuideMachineEntry) => {
  const intent =
    machine.meta.intent === null
      ? undefined
      : machine.meta.intent ||
        machine.machineId
          .split("_")
          .filter(
            (probablyIntent) =>
              probablyIntent === "cs" ||
              GUIDE_INTENTS_ORDER.includes(probablyIntent as GuideIntentType)
          )[0];
  const guideItemTypeCategory = getGuideItemTypeCategoryFromSubcategory(
    machine.meta.subcategories[0]
  );
  const relatedGuideItemTypeCategories = machine.meta.relatedItemTypes;

  return {
    intent: intent as GuideIntentType | undefined,
    guideItemTypeCategory,
    relatedGuideItemTypeCategories,
    roomId: machine.roomId,
  };
};
const getGuideResultsTasksCount = (guideResults: RoomGuideResults) => {
  return getGuideResultsMachines(guideResults.machines).length;
};

const getGuidesTasksCount = (guides: OrchestratorSOWGuide[]) => {
  return guides.flatMap((guide) => guide.intents).length;
};

export const getSOWTasksCount = (props: {
  guides: OrchestratorSOWGuide[];
  guideResults?: RoomGuideResults;
}) => {
  const { guides = [], guideResults } = props;

  const guidesTasksCount = getGuidesTasksCount(guides);

  if (!guideResults) {
    return guidesTasksCount;
  }

  const guideResultsTasksCount = getGuideResultsTasksCount(guideResults);

  return guidesTasksCount + guideResultsTasksCount;
};

export const getPlanningAreasGuidesMap = (
  rooms: RoomAndSpace[],
  guides: OrchestratorSOWGuide[]
): PlanningAreasGuidesMap => {
  return rooms.reduce((result, room) => {
    const roomGuides = findAll(guides, "roomId", room.id);
    if (roomGuides.length) {
      result[room.id] = roomGuides;
    }
    return result;
  }, {});
};

export const getGuideResultsMap = (
  rooms: RoomAndSpace[]
): PlanningAreasGuideResultsMap => {
  return rooms.reduce((result, room) => {
    result[room.id] = room.guideResults;
    return result;
  }, {});
};

export const filterHiddenGuides = <T extends OrchestratorSOWGuide>(
  guides: T[]
): T[] => {
  return guides.filter((guide) => {
    return !FAKE_GUIDE_TYPES.includes(guide.type);
  });
};

export const filterDoneGuides = (guides?: OrchestratorSOWGuide[]) =>
  (guides || []).filter(
    (guide) => guide.status !== OrchestratorSOWGuideStatus.DONE
  );

export const getSelectedPresetIds = (guides: OrchestratorSOWGuide[]) => {
  return [
    ...new Set(
      guides.reduce((result, guide) => {
        if (guide.preset) {
          result.push(guide.preset.presetId);
        }
        return result;
      }, [])
    ),
  ];
};

export const getSelectedPresetItemIds = (guides: OrchestratorSOWGuide[]) => {
  return [
    ...new Set(
      guides.reduce((result, guide) => {
        if (guide.preset) {
          result.push(guide.preset.presetItemId);
        }
        return result;
      }, [])
    ),
  ];
};

export const getSelectedPresetRoomsIds = (
  guides: OrchestratorSOWGuide[],
  presetItemId: string
) => {
  return [
    ...new Set(
      guides.reduce((result, guide) => {
        if (guide.preset?.presetItemId === presetItemId) {
          result.push(guide.roomId);
        }
        return result;
      }, [])
    ),
  ];
};

export const getPresetGroupById = (presetId: PresetGroupId) =>
  findBy(presetCategoryData, "id", presetId);

export const getPresetItemGroupById = (
  presetId: PresetGroupId,
  presetItemId: PresetGroupItemId
) => {
  const presetGroup = findBy(presetCategoryData, "id", presetId);
  if (presetGroup) {
    return findBy(presetGroup.items, "id", presetItemId);
  }
};

export const getFlatGuideResultsMachines = (rooms: RoomAndSpace[]) => {
  return rooms.flatMap((room) =>
    getGuideResultsMachines(room.guideResults?.machines ?? [])
  );
};

const getRoomCategoryPanelStatus = (params: {
  hasFloorPlan: boolean;
  prevCompleted: boolean;
  allCompleted: boolean;
}) => {
  const { hasFloorPlan, prevCompleted, allCompleted } = params;

  if (!hasFloorPlan) {
    return CategoryPanelStatus.NO_LAYOUT;
  } else if (!prevCompleted) {
    return CategoryPanelStatus.LOCKED;
  } else if (allCompleted) {
    return CategoryPanelStatus.COMPLETED;
  } else {
    return CategoryPanelStatus.ACTIVE;
  }
};
const filterActiveGuidesBySOWGuide = (
  activeGuides: BaseActiveGuideContext[],
  sowGuides: OrchestratorSOWGuide[]
) =>
  activeGuides.filter((activeGuide) =>
    sowGuides.some(
      (sow) =>
        sow.id === activeGuide._SOWId && sow.type === activeGuide._meta.type
    )
  );
const getSOWStatus = (params: {
  current: Omit<OrchestratorSOWGuideExtra, "subcategoryStatus">;
  prev?: Omit<OrchestratorSOWGuideExtra, "subcategoryStatus">;
  list: Array<Omit<OrchestratorSOWGuideExtra, "subcategoryStatus">>;
  panelStatus: CategoryPanelStatus;
}) => {
  const { current, prev, list, panelStatus } = params;

  if (current.isCompleted || panelStatus === CategoryPanelStatus.COMPLETED) {
    return SubCategoryStatus.COMPLETED;
  }

  if (panelStatus !== CategoryPanelStatus.ACTIVE) {
    return SubCategoryStatus.CAN_NOT_START;
  }

  if (current.activeGuide) {
    return SubCategoryStatus.ACTIVE;
  }

  if (current.isPartialCompleted) {
    return SubCategoryStatus.IN_PROGRESS;
  }

  if (!prev) {
    return SubCategoryStatus.CAN_START;
  } else {
    if (prev.isCompleted || isGuideOnIDKPause(prev.activeGuide)) {
      return SubCategoryStatus.CAN_START;
    } else {
      const pausedList = list.filter((it) => isGuideOnIDKPause(it.activeGuide));

      const prevDisabledBecauseHasPausedRelated = pausedList.some((sow) =>
        (sow?.activeGuide?._meta?.relatedItemTypes || []).includes(
          prev.itemType
        )
      );

      return prevDisabledBecauseHasPausedRelated
        ? SubCategoryStatus.CAN_START
        : SubCategoryStatus.CAN_NOT_START;
    }
  }
};

export interface OrchestratorSOWGuideExtra extends OrchestratorSOWGuide {
  isCompleted: boolean;
  isPartialCompleted: boolean;
  activeGuide: BaseActiveGuideContext | undefined;
  subcategoryStatus: SubCategoryStatus;
}

export const getSOWCategoryData = (params: {
  hip: OrchestratorHIPSContext;
  activeGuides: BaseActiveGuideContext[];
  roomsAndSpaces: RoomAndSpace[];
  withHidden?: boolean;
}) => {
  const SOWGuidesCategoriesMap: Partial<
    Record<GuideType, OrchestratorSOWGuide[]>
  > = getGroupedMap(
    params.hip.scopeOfWork.guides.filter((guide) => {
      const rooms = params.hip.planningAreas.rooms;
      const hasRoom = rooms.some((r) => r.id === guide.roomId);

      return hasRoom;
    }),
    (guide) => guide.type
  );
  const SOWGuidesCategories = orderCategories(
    getKeys(SOWGuidesCategoriesMap)
  ).map((guideType) => {
    const _sowGuides = SOWGuidesCategoriesMap[guideType].sort(
      (a, b) =>
        SUBCATEGORY_TAXONOMY_ORDER[a.subCategory] -
        SUBCATEGORY_TAXONOMY_ORDER[b.subCategory]
    );
    const sowGuides = params.withHidden
      ? _sowGuides
      : filterHiddenGuides(_sowGuides);
    const activeGuides = filterActiveGuidesBySOWGuide(
      params.activeGuides,
      sowGuides
    );

    const sowGuidesWithExtras: Array<
      Omit<OrchestratorSOWGuideExtra, "subcategoryStatus">
    > = sowGuides
      .map((sow) => {
        const room = params.hip.planningAreas.rooms.find(
          (room) => room.id === sow.roomId
        );
        const activeGuide = activeGuides.find((ag) => ag._SOWId === sow.id);

        const guideResultsMachines = getGuideResultsMachines(
          room?.guideResults?.machines || []
        );
        const activeGuideMachines = getGuideResultsMachines(
          activeGuide?._machines || []
        );
        const relatedResults = guideResultsMachines
          .map((machine) => getDetailsFromMachineEntry(machine))
          .filter((d) => d.guideItemTypeCategory === sow.itemType);
        const completedIntents = uniq(
          uniq([
            ...(sow.completedIntents || []),
            ...[...guideResultsMachines, ...activeGuideMachines]
              .map((machine) => getDetailsFromMachineEntry(machine))
              .filter(
                (details) => details.guideItemTypeCategory === sow.itemType
              )
              .map((details) => details.intent)
              .filter(Boolean),
          ])
        ).sort(
          (a, b) => GUIDE_INTENTS_ORDER_MAP[a] - GUIDE_INTENTS_ORDER_MAP[b]
        );
        const sortedIntents = sow.intents
          .filter(Boolean)
          .sort(
            (a, b) => GUIDE_INTENTS_ORDER_MAP[a] - GUIDE_INTENTS_ORDER_MAP[b]
          );
        const isCompleted =
          !activeGuide &&
          sortedIntents.length > 0 &&
          sortedIntents.every((intent) => completedIntents.includes(intent));
        const isPartialCompleted =
          completedIntents.length > 0 &&
          sortedIntents.some((si) => !completedIntents.includes(si));

        return {
          ...sow,
          relatedResults,
          completedIntents,
          activeGuide,
          isCompleted,
          isPartialCompleted,
        };
      })
      .map((sow, _i, list) => {
        if (sow.isCompleted) {
          return sow;
        }

        const related = sow.relatedResults.filter(
          (r) => r.guideItemTypeCategory !== sow.itemType
        );
        const hasCompletedRelatedSOW = related.some((rel) =>
          list.find(
            (s) =>
              s.isCompleted &&
              s.roomId === sow.roomId &&
              s.itemType === rel.guideItemTypeCategory
          )
        );

        if (!hasCompletedRelatedSOW) {
          return sow;
        }

        return {
          ...sow,
          isCompleted: true,
          isPartialCompleted: false,
        };
      });

    const allCompleted =
      sowGuidesWithExtras.length > 0 &&
      sowGuidesWithExtras.every((g) => g.isCompleted);

    return {
      category: guideType,
      sowGuides: sowGuidesWithExtras,
      activeGuides,
      allCompleted,
    };
  });

  const getSOWGuidesCategoriesForRoom = (roomId: RoomAndSpace["id"]) => {
    const room = params.hip.planningAreas.rooms.find(
      (room) => room.id === roomId
    );
    const roomAndSpace = params.roomsAndSpaces.find(
      (room) => room.id === roomId
    );

    const roomSOWGuidesCategories = SOWGuidesCategories.filter((it) =>
      it.sowGuides.some((sow) => sow.roomId === roomId)
    )
      .map((data) => {
        const roomSOWGuides = data.sowGuides.filter(
          (sowGuide) => sowGuide.roomId === roomId
        );
        const roomActiveGuides = data.activeGuides.filter(
          (ag) => ag._roomId === roomId
        );
        const allCompleted =
          roomSOWGuides.length > 0 && roomSOWGuides.every((g) => g.isCompleted);

        return {
          ...data,
          sowGuides: roomSOWGuides,
          activeGuides: roomActiveGuides,
          allCompleted,
        };
      })
      .map((data, i, list) => {
        const prev = i > 0 ? list[i - 1] : undefined;
        const resultsMachines = getGuideResultsMachines(
          room.guideResults?.machines || []
        ).filter((r) => r.meta.type === data.category);

        const prevCompleted = prev ? Boolean(prev.allCompleted) : true;
        const hasFloorPlan = Boolean(roomAndSpace.roomPlan);
        const panelStatus = getRoomCategoryPanelStatus({
          hasFloorPlan,
          prevCompleted,
          allCompleted: data.allCompleted,
        });

        const sowGuides: OrchestratorSOWGuideExtra[] = data.sowGuides.map(
          (current, i, list) => {
            const prev = i > 0 ? list[i - 1] : undefined;
            const subcategoryStatus = getSOWStatus({
              current,
              prev,
              panelStatus,
              list,
            });

            return {
              ...current,
              subcategoryStatus,
            };
          }
        );

        // If the guide is on pause then all related guides have to be disabled.
        for (let i = 0; i < sowGuides.length; i++) {
          const guide = sowGuides[i];

          if (!isGuideOnIDKPause(guide.activeGuide)) {
            continue;
          }

          const related = guide.activeGuide?._meta?.relatedItemTypes || [];

          if (related.length === 0) {
            continue;
          }

          for (let j = 0; j < sowGuides.length; j++) {
            if (j === i) {
              continue;
            }

            const otherGuide = sowGuides[j];

            if (related.includes(otherGuide.itemType)) {
              sowGuides[j] = {
                ...otherGuide,
                subcategoryStatus: SubCategoryStatus.CAN_NOT_START,
              };
            }
          }
        }

        const activeGuides = filterActiveGuidesBySOWGuide(
          data.activeGuides,
          sowGuides
        );
        const activeRelatedGuideItemCategories = flatten(
          activeGuides.map((ag) => {
            const machinesCategories = flatten(
              getGuideResultsMachines(ag._machines || []).map(
                (m) => m.meta.relatedItemTypes
              )
            );
            const metaCategories = ag._meta.relatedItemTypes || [];

            return [...machinesCategories, ...metaCategories];
          })
        ).filter(Boolean);
        const finishedRelatedGuideItemCategories = flatten(
          resultsMachines
            .filter((m) => {
              const hasRelated =
                Array.isArray(m.meta.relatedItemTypes) &&
                m.meta.relatedItemTypes.length > 0;

              if (!hasRelated) {
                return false;
              }

              const hasSOW = sowGuides.some(
                (s) =>
                  m.meta.relatedItemTypes?.includes(s.itemType) ||
                  m.meta.subcategories?.includes(s.subCategory)
              );

              return Boolean(hasRelated && hasSOW);
            })
            .map((m) => m.meta.relatedItemTypes)
        );
        const relatedGuideItemCategories = uniq([
          ...activeRelatedGuideItemCategories,
          ...finishedRelatedGuideItemCategories,
        ]);

        const orderedCategories = uniq([
          ...sowGuides.map((s) => s.itemType),
          ...relatedGuideItemCategories,
        ]).sort(
          (a, b) =>
            SUBCATEGORY_TAXONOMY_ORDER[a] - SUBCATEGORY_TAXONOMY_ORDER[b]
        );
        const sowWithRelatedCategories = orderedCategories.map(
          (itemCategory) => {
            const sow = sowGuides.find((g) => g.itemType === itemCategory);

            return {
              sow,
              itemCategory,
            };
          }
        );

        const finishedFPMachines = (room.guideResults?.machines || []).filter(
          (r) =>
            r.meta.type === data.category ||
            r.meta.type === GuideType.ADD_ROOM_LAYOUT
        );
        const inProgressFPMachines = flatten(
          activeGuides
            .filter((ag) => ag._roomId === room.id)
            .map((ag) => ag._machines || [])
        );
        /*
        Note(pavel): get floor plan items categories from finished
        and in-progress machines, so we show on the room floor plan
        the same types of items as in the guides that may not be in the SOW
        but still may be in the guides chain, e.g. kitchen layout.

        One exception here is the add_room_layout guide - it is not
        from the category, but needed always as it contains
        room layout with doors and windows, so we include it anyway.
         */
        const machinesWithFPCategories = uniq(
          flatten(
            [...inProgressFPMachines, ...finishedFPMachines]
              .filter(
                (r) =>
                  r.meta.type === data.category ||
                  r.meta.type === GuideType.ADD_ROOM_LAYOUT
              )
              .map((m) =>
                (m.meta?.subcategories || [])
                  .map((subcat) =>
                    getGuideItemTypeCategoryFromSubcategory(subcat)
                  )
                  .filter(Boolean)
              )
          )
        );

        const floorPlanCategories = uniq([
          ...sowWithRelatedCategories
            .map((it) => it.itemCategory)
            .filter(Boolean),
          ...finishedRelatedGuideItemCategories,
          ...machinesWithFPCategories,
        ]);

        return {
          ...data,
          sowGuides,
          sowWithRelatedCategories,
          activeGuides,
          prevCompleted,
          hasFloorPlan,
          panelStatus,
          room,
          roomAndSpace,
          relatedGuideItemCategories,
          floorPlanCategories,
        };
      });
    const activeGuides = roomSOWGuidesCategories
      .map((data) => data.activeGuides)
      .flat();

    return {
      room,
      activeGuides,
      roomSOWGuidesCategories,
    };
  };

  return {
    activeGuides: params.activeGuides,
    SOWGuidesCategories,
    getSOWGuidesCategoriesForRoom,
  };
};

export type SOWCategoryData = ReturnType<typeof getSOWCategoryData>;
type SOWRoomCategoryData = ReturnType<
  ReturnType<typeof getSOWCategoryData>["getSOWGuidesCategoriesForRoom"]
>;
export type SOWRoomGuideCategoryData = ArrayElement<
  SOWRoomCategoryData["roomSOWGuidesCategories"]
>;

export const useSOWCategoryData = (params: { hipId?: string } = {}) => {
  const { hipsId } = useHIPsParams();
  const hip = useOrchestratorHIP(params.hipId || hipsId);
  const activeGuides = useOrchestratorActiveGuides();
  const roomsAndSpaces = useOrchestratorRoomsAndSpaces();

  return useMemo(
    () => getSOWCategoryData({ hip, activeGuides, roomsAndSpaces }),
    [activeGuides, hip, roomsAndSpaces]
  );
};
