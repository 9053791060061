import { MINUTES } from "constants/time";

export const VENDOR_QUERIES = {
  INTEGRATION_RESULTS: "integration-results",
  INTEGRATION_RESULT_BY_ID: "integration-result-by-id",
  INTEGRATION_RESULTS_SILENT_REFRESH: "integration-results-silent-refresh",
  INTEGRATION_LOCATIONS_MINE: "integration-locations-mine",
  INTEGRATION_MEMBERS: "integration-members",
  INTEGRATION_MEMBER_BY_ID: "integration-member-by-id",
};

export const VENDOR_REFRESH_QUERY_STALE_TIME = 3 * MINUTES;
export const VENDOR_BASE_QUERY_STALE_TIME = 10 * MINUTES;
