import { getEphemeral } from "core/persistence";

const KEY = "vendor";
const INTEGRATION_KEY = "integrationId";

export const isVendor = () => getEphemeral().getStringSync(KEY) === "true";
export const setVendor = () => getEphemeral().setStringSync(KEY, "true");

export const getIntegrationId = () =>
  getEphemeral().getStringSync(INTEGRATION_KEY);
export const setIntegrationId = (integrationId: string) =>
  getEphemeral().setStringSync(INTEGRATION_KEY, integrationId);
