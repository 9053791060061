import type { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { createContext, useContext } from "react";
import type { Interpreter } from "xstate";

export type CoreContextState = {
  integrationId: number;
  globalOrchestratorMachine: Interpreter<OrchestratorMachineContext>;
};

type CoreContextActions = {
  setValue: (
    key: keyof CoreContextState,
    value: CoreContextState[keyof CoreContextState]
  ) => void;
};

export const CoreContext = createContext<CoreContextState & CoreContextActions>(
  undefined as unknown as CoreContextState & CoreContextActions
);

export const useCoreContext = () => {
  return useContext(CoreContext);
};
