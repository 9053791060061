export enum WindowTypes {
  FIXED = "FIXED",
  SINGLE_HUNG = "SINGLE_HUNG",
  DOUBLE_HUNG = "DOUBLE_HUNG",
  CASEMENT = "CASEMENT",
  SLIDING = "SLIDING",
  HOPPER = "HOPPER",
  BAY = "BAY",
  BOW = "BOW",
  GARDEN = "GARDEN",
  GLASS_BLOCK = "GLASS_BLOCK",
  ARCHED = "ARCHED",
}
