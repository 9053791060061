import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";

import { WindowsConfigFloorPlan } from "./config.floorPlan";
import { WindowsConfigImages } from "./config.images";
import { WindowsConfigTexts } from "./config.texts";
import { WindowTypes } from "./enums";

type Config = GuideItemTypeConfig<GuideItemTypeCategory.WINDOWS, WindowTypes>;

const types: Config["types"] = [
  WindowTypes.SINGLE_HUNG,
  WindowTypes.DOUBLE_HUNG,
  WindowTypes.CASEMENT,
  WindowTypes.FIXED,
  WindowTypes.SLIDING,
  WindowTypes.BAY,
  WindowTypes.BOW,
  WindowTypes.HOPPER,
  WindowTypes.ARCHED,
  WindowTypes.GARDEN,
  WindowTypes.GLASS_BLOCK,
];

export const WindowsConfig: Config = {
  guideType: GuideType.WINDOWS_AND_DOORS,
  category: GuideItemTypeCategory.WINDOWS,
  types,
  typesOrder: getOrderedMap(types),
  texts: WindowsConfigTexts,
  images: WindowsConfigImages,
  floorPlan: WindowsConfigFloorPlan,
  allowedInRooms: undefined,
  allowedIntents: undefined,
} as const;
