import { getOrderedMap } from "common/utils/listUtils";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideItemTypeConfig } from "constants/guides/GuideItems.types";
import { GuideType } from "constants/guides/GuideType";
import { GuideIntentType } from "pages/Guides/enums";

import { KitchenCabinetConfigFloorPlan } from "./config.floorPlan";
import { KitchenCabinetConfigImages } from "./config.images";
import { KitchenCabinetConfigTexts } from "./config.texts";
import { KitchenCabinetTypes } from "./enums";

type Config = GuideItemTypeConfig<
  GuideItemTypeCategory.KITCHEN_CABINET,
  KitchenCabinetTypes
>;

const types: Config["types"] = [
  KitchenCabinetTypes.BASE_CABINET,
  KitchenCabinetTypes.WALL_CABINET,
  KitchenCabinetTypes.TALL_PANTRY_CABINET,
];

export const KitchenCabinetConfig: Config = {
  guideType: GuideType.KITCHEN_SPECIFIC,
  category: GuideItemTypeCategory.KITCHEN_CABINET,
  types,
  typesOrder: getOrderedMap(types),
  texts: KitchenCabinetConfigTexts,
  images: KitchenCabinetConfigImages,
  floorPlan: KitchenCabinetConfigFloorPlan,
  allowedInRooms: ["kitchen"],
  allowedIntents: [GuideIntentType.ADD],
} as const;
