import { CSSProperties } from "react";

export const getCSSVarsStyle = (style = {}): CSSProperties => {
  return style;
};

export const rem = (pxValue: string) => {
  return `${Number(pxValue.replace(/[^\d.-]/g, "")) / 16}rem`;
};

export const getCSSVarValue = (key: string, node?: Element) => {
  const element = node || document.getElementById("root");

  if (!element) {
    return "";
  }

  const computedStyle = window.getComputedStyle(element);

  if (!computedStyle) {
    return "";
  }

  return computedStyle.getPropertyValue(key);
};

export const getCSSFloatValue = (key: string, node?: Element) => {
  const value = getCSSVarValue(key, node) || "0";

  return parseFloat(value) || 0;
};

export const getCSSVarNumberValue = (key: string, node?: Element) => {
  return parseInt(getCSSVarValue(key, node).replace(/\D+/g, "")) || 0;
};
