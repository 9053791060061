import {
  AnyShape,
  Line,
  Point,
  Polygon,
  Segment,
  Vector,
} from "@flatten-js/core";
import { Graphics as PixiGraphics } from "@pixi/graphics";
import { toFixed } from "common/utils/numberUtils";
import { converSqInchesToSqFeet } from "components/HBFloorPlan/FloorPlan/components/FloorPlanMeasurements/utils/floorPlanMeasurements.utils";
import {
  FloorPlanMeasurementsValues,
  Line as FloorPlanLine,
  Points,
} from "components/HBFloorPlan/HBFloorPlan.types";
import { AreaMeasurement } from "constants/guides/GuideItems.types";
import { Polygon as GeometricPolygon } from "geometric";
import { cloneDeep, isEqual, uniqWith } from "lodash-es";
import Offset from "offset-polygon";
import { Coords } from "pages/Guides/types";
import { RoomPlan } from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { Resource, Sprite, Texture } from "pixi.js";
import polygonClipping from "polygon-clipping";
import { AUTO_ALIGN_OFFSET, WALL_THICKNESS } from "shared/floorPlan/constants";

import {
  getPointAtLength,
  getSegmentLength,
  getSegmentMidPoint,
  isSegmentContainsPoint,
  isSegmentsParallel,
  mapConnectedLinesToPoints,
  mapPointsToConnectedLines,
} from "./line.utils";
import { calcSelectableBaseShape } from "./material.utils";
import {
  covertFromCoordsToPoints,
  extractCoords,
  getNearAndFarPoints,
  isEqualCoords,
  mapListOfPointsToCoords,
  mapPointsToList,
} from "./points.utils";

enum RectBorderType {
  OUTSIDE = "OUTSIDE",
  INSIDE = "INSIDE",
}

const POLYGON_MINIMUM_COORDS_COUNT = 4;

export const rotatePolygon = (
  coords: Coords[] | Polygon,
  rotationRad: number,
  rotationCenter?: Coords
): Coords[] => {
  const P =
    coords instanceof Polygon ? coords : new Polygon(mapPointsToList(coords));
  const center = rotationCenter
    ? new Point(rotationCenter.x, rotationCenter.y)
    : undefined;

  return P.rotate(rotationRad, center).vertices.map(extractCoords);
};

export const translatePolygonToCoords = (
  polygon: Coords[] | Polygon,
  target: Coords
) => {
  const P =
    polygon instanceof Polygon
      ? polygon
      : new Polygon(mapPointsToList(polygon));
  const center = extractCoords(P.box.center);
  const V = new Vector(
    new Point(center.x, center.y),
    new Point(target.x, target.y)
  );

  return P.translate(V).vertices.map(extractCoords);
};

/**
 * Converts segment to polygon, imagine stretching line to a brick-like box.
 */
export const mapSegmentToPolygon = (params: {
  segment: [Coords, Coords] | Segment;
  thickness?: number;
}) => {
  const { segment, thickness = WALL_THICKNESS } = params;

  const S =
    segment instanceof Segment
      ? segment
      : new Segment(
          new Point(segment[0].x, segment[0].y),
          new Point(segment[1].x, segment[1].y)
        );

  const temp1 = getPointAtLength(S, thickness * 0.5);
  const temp2 = getPointAtLength(S.reverse(), thickness * 0.5);
  const S1 = new Segment(S.ps, new Point(temp1.x, temp1.y));
  const S2 = new Segment(S.pe, new Point(temp2.x, temp2.y));

  const p1 = extractCoords(S1.rotate(Math.PI / 2, S1.ps).pe);
  const p2 = extractCoords(S1.rotate((-1 * Math.PI) / 2, S1.ps).pe);
  const p3 = extractCoords(S2.rotate(Math.PI / 2, S2.ps).pe);
  const p4 = extractCoords(S2.rotate((-1 * Math.PI) / 2, S2.ps).pe);

  return [p1, p2, p3, p4];
};

/** @description Creates an offset polygon from points.
 *
 * @example
 * ┌──────────────┐
 * │  ┌────────┐  │
 * │  │        │  │
 * │  │        │  │
 * │  └────────┘  │
 * └──────────────┘
 *
 * The function returns the inner polygon points, basically, original points
 * with a padding.
 * */
export const offsetPolygon = (
  props:
    | {
        points: Points;
        padding?: number;
        arc?: number;
      }
    | {
        points: Points;
        margin?: number;
        arc?: number;
      }
): Points => {
  const { points, arc = 0 } = props;

  const cleansedData = filterOverlappingVertices(points);
  cleansedData.pop();
  let offsetedPolygon;

  if ("margin" in props) {
    offsetedPolygon = Offset(cleansedData, props.margin, arc);
  }
  if ("padding" in props) {
    offsetedPolygon = Offset(cleansedData, -props.padding, arc);
  }

  if (offsetedPolygon && offsetedPolygon.length > 0) {
    offsetedPolygon.push(offsetedPolygon[0]);
  }

  return offsetedPolygon as Points;
};

export const getMinMaxXYOfPolygon = (
  points: Coords[]
): { minX: number; maxX: number; minY: number; maxY: number } => {
  return points.reduce(
    (acc, curr) => {
      acc.minX = Math.min(acc.minX, curr.x);
      acc.maxX = Math.max(acc.maxX, curr.x);
      acc.minY = Math.min(acc.minY, curr.y);
      acc.maxY = Math.max(acc.maxY, curr.y);

      return acc;
    },
    { minX: Infinity, maxX: -1 * Infinity, minY: Infinity, maxY: -1 * Infinity }
  );
};

export const filterOverlappingVertices = (points: Coords[]): Coords[] => {
  const uniquePoints = uniqWith(points, (a, b) => a.x === b.x && a.y === b.y);
  const firstPoint = points[0];
  uniquePoints.push(firstPoint);

  return uniquePoints;
};

/** @description
 * Rearranges points of array to always start with the top left most point.
 * */
const rotatePolygonPointsArrayToTopLeftmost = (points: Coords[]): Coords[] => {
  let topLeftIndex = 0;
  for (let i = 1; i < points.length; i++) {
    if (
      points[i].y < points[topLeftIndex].y ||
      (points[i].y === points[topLeftIndex].y &&
        points[i].x < points[topLeftIndex].x)
    ) {
      topLeftIndex = i;
    }
  }
  return [...points.slice(topLeftIndex), ...points.slice(0, topLeftIndex)];
};

/** @description
 * To merge points on the same line segment in a polygon, we need to iterate through the list of points and determine
 * whether three consecutive points lie on the same straight line. If they do, the middle point can be removed. This is
 * because if three points are collinear, the middle point does not change the shape of the line segment.
 * */
export const mergePolygonCoLinearPoints = (points: Coords[]): Coords[] => {
  if (points.length <= 2) {
    return points;
  }

  const areCollinear = (p1: Coords, p2: Coords, p3: Coords): boolean =>
    p1.x * (p2.y - p3.y) + p2.x * (p3.y - p1.y) + p3.x * (p1.y - p2.y) === 0;
  const cPoints = [...points];
  const firstElement = cPoints[0];
  const lastElement = cPoints[cPoints.length - 1];

  if (isEqual(firstElement, lastElement)) {
    cPoints.pop();
  }

  let i = 0;
  while (i < cPoints.length) {
    const nextIndex = (i + 1) % cPoints.length;
    const nextNextIndex = (i + 2) % cPoints.length;

    if (areCollinear(cPoints[i], cPoints[nextIndex], cPoints[nextNextIndex])) {
      cPoints.splice(nextIndex, 1);
    } else {
      i++;
    }
  }

  const rotatedPoints = rotatePolygonPointsArrayToTopLeftmost(cPoints);
  rotatedPoints.push(rotatedPoints[0]);

  return rotatedPoints;
};

export const polygonHasOverlappingVertices = (points: Coords[]): boolean => {
  const lines = mapPointsToConnectedLines(points);
  const noLengthLinesFiltered = lines.filter((l) => getSegmentLength(l) !== 0);
  const filteredPoints = mapConnectedLinesToPoints(noLengthLinesFiltered);

  filteredPoints.pop();
  const uniquePoints = uniqWith(
    filteredPoints,
    (a, b) => a.x === b.x && a.y === b.y
  );

  return uniquePoints.length !== filteredPoints.length;
};

export const getBoxCentroid = (shape: Coords[]): Coords => {
  const polygon = new Polygon(mapPointsToList(shape));
  const { x, y } = polygon.box.center;
  return { x, y };
};

// Note(Alan): I made this function because the getBoxCentroid function is not accurate for some special polygons.
// For example, we have L shape and it's centroid calculated as { x: 0, y: 0 } but it shouldn't be like that.
export const getPolygonCentroid = (polygon: Coords[]): Coords => {
  let area = 0;
  let centerX = 0;
  let centerY = 0;

  const n = polygon.length;
  for (let i = 0; i < n; i++) {
    const { x: x1, y: y1 } = polygon[i];
    const { x: x2, y: y2 } = polygon[(i + 1) % n];

    const cross = x1 * y2 - x2 * y1;
    area += cross;
    centerX += (x1 + x2) * cross;
    centerY += (y1 + y2) * cross;
  }

  area /= 2;
  centerX /= 6 * area;
  centerY /= 6 * area;

  return { x: centerX, y: centerY };
};

export const rotateRadian90Deg = (radian: number): number => {
  return normalizeRadian(radian + Math.PI / 2);
};

export const normalizeRadian = (radian: number) => {
  const _radian = isNaN(radian) || radian === undefined ? 0 : radian;
  return _radian % (Math.PI * 2);
};

export const isSameShape = (shape1: Coords[], shape2: Coords[]): boolean => {
  if (shape1.length !== shape2.length) {
    return false;
  }

  for (let index = 0; index < shape1.length; index++) {
    if (!isEqualCoords(shape1[index], shape2[index])) {
      return false;
    }
  }

  return true;
};

export const calcSignedArea = (points: Coords[]): number => {
  const polygon = new Polygon();
  const face = polygon.addFace(covertFromCoordsToPoints(points));
  return face.signedArea();
};

export const ensureClockWise = (points: Coords[]): Coords[] => {
  const signedArea = calcSignedArea(points);
  if (signedArea > 0) {
    return points.slice().reverse();
  }
  return points;
};

export const shortestSegmentFromPointToShape = (
  point: Coords,
  shapes: Points[]
): Segment => {
  const _point = new Point(point.x, point.y);
  const flattenList: GeometricPolygon = [];
  shapes.forEach((shape) => flattenList.push(...mapPointsToList(shape)));
  const _shape = new Polygon(flattenList);
  return _point.distanceTo(_shape)[1];
};

export const getClosestShape = (item: Polygon | Point, shapes: AnyShape[]) => {
  const distances = shapes
    .map((shape) => {
      const [distance, segment] = item.distanceTo(shape);

      return {
        shape,
        distance,
        segment,
      };
    })
    .sort((a, b) => a.distance - b.distance);

  return distances[0];
};

export const getStickedWallFromShape = (
  shapes: Points[],
  point: Point
): FloorPlanLine | undefined => {
  for (let sIndex = 0; sIndex < shapes.length; sIndex++) {
    const shape = shapes[sIndex];
    for (let pIndex = 0; pIndex < shape.length - 1; pIndex++) {
      const p1 = shape[pIndex];
      const p2 = shape[pIndex + 1];

      if (!p1 || !p2) {
        continue;
      }

      const line = new Segment(new Point(p1.x, p1.y), new Point(p2.x, p2.y));

      if (line.contains(point)) {
        return { p1, p2 };
      }
    }
  }

  return undefined;
};

export const getStickedWallIndex = (
  shapes: Points[],
  point: Point
): number | undefined => {
  let wallIndex = 0;
  for (let sIndex = 0; sIndex < shapes.length; sIndex++) {
    const shape = shapes[sIndex];
    for (let pIndex = 0; pIndex < shape.length - 1; pIndex++) {
      const p1 = shape[pIndex];
      const p2 = shape[pIndex + 1];

      if (!p1 || !p2) {
        continue;
      }

      const line = new Segment(new Point(p1.x, p1.y), new Point(p2.x, p2.y));

      if (line.contains(point)) {
        return wallIndex;
      }
      wallIndex++;
    }
  }

  return undefined;
};

export const getPointFromStickedWall = (
  stickedWall: FloorPlanLine,
  point: Coords
): Coords => {
  const _point = new Point(point.x, point.y);
  const _line = new Line(
    new Point(stickedWall.p1.x, stickedWall.p1.y),
    new Point(stickedWall.p2.x, stickedWall.p2.y)
  );

  const resultPoint = {
    x: _point.distanceTo(_line)[1].pe.x,
    y: _point.distanceTo(_line)[1].pe.y,
  };

  if (!isSegmentContainsPoint(stickedWall, resultPoint)) {
    const nearPoint = getNearAndFarPoints(point, stickedWall)[0];
    resultPoint.x = nearPoint.x;
    resultPoint.y = nearPoint.y;
  }

  return resultPoint;
};

export const checkShapeClosed = (shape: Coords[]): boolean => {
  return shape.length > 3 && isEqualCoords(shape[0], shape[shape.length - 1]);
};

// Note(Alan): this function calculate centered value of every lines in polygon
export const getMidpointsOfAllEdges = (polygon: Coords[]): Coords[] => {
  const centerCoords: Coords[] = [];

  for (let i = 0; i < polygon.length; i++) {
    const j = (i + 1) % polygon.length;

    const segment = {
      p1: polygon[i],
      p2: polygon[j],
    };

    centerCoords.push(getSegmentMidPoint(segment));
  }

  return centerCoords;
};

export const getShortestSegmentBetweenPolygons = (
  fromPolygon: Coords[] | Coords,
  toPolygon: Coords[] | Coords
):
  | {
      shortestSegment: FloorPlanLine;
      shortestIndex: { fromIndex: number; toIndex: number };
    }
  | undefined => {
  const fromCoordsArray = Array.isArray(fromPolygon)
    ? fromPolygon
    : [fromPolygon];
  const toCoordsArray = Array.isArray(toPolygon) ? toPolygon : [toPolygon];

  let minDistance = Infinity;
  let shortestSegment: FloorPlanLine | undefined;
  let shortestIndex = { fromIndex: 0, toIndex: 0 };

  fromCoordsArray.forEach((fromCoords, fromIndex) => {
    toCoordsArray.forEach((toCoords, toIndex) => {
      const segment = { p1: fromCoords, p2: toCoords };
      const distance = getSegmentLength(segment);
      if (distance < minDistance) {
        minDistance = distance;
        shortestSegment = segment;
        shortestIndex = { fromIndex, toIndex };
      }
    });
  });

  return shortestSegment ? { shortestSegment, shortestIndex } : undefined;
};

export const getPolygonEdgeAlignment = (params: {
  polygon1: Points;
  polygon2: Points;
  offset?: number;
}): { lines: FloorPlanLine[]; offset: Coords } => {
  const { polygon1, polygon2, offset = AUTO_ALIGN_OFFSET } = params;
  const polygonEdges1: FloorPlanLine[] = polygon1.map((coords, index) => ({
    p1: coords,
    p2: polygon1[(index + 1) % polygon1.length],
  }));
  const polygonEdges2: FloorPlanLine[] = polygon2.map((coords, index) => ({
    p1: coords,
    p2: polygon2[(index + 1) % polygon2.length],
  }));

  const lines: FloorPlanLine[] = [];
  let updatedOffset = { x: null, y: null };
  polygonEdges1.forEach((edge1) => {
    const parallelEdges = polygonEdges2.filter((edge2) =>
      isSegmentsParallel(edge1, edge2)
    );
    parallelEdges.forEach((parallelEdge) => {
      let startCoords: Coords;
      let endCoords: Coords;
      let xOffset: number = null;
      let yOffset: number = null;
      if (parallelEdge.p1.x === parallelEdge.p2.x) {
        startCoords = {
          x: parallelEdge.p1.x,
          y: Math.min(
            parallelEdge.p1.y,
            parallelEdge.p2.y,
            edge1.p1.y,
            edge1.p2.y
          ),
        };
        endCoords = {
          x: parallelEdge.p1.x,
          y: Math.max(
            parallelEdge.p1.y,
            parallelEdge.p2.y,
            edge1.p1.y,
            edge1.p2.y
          ),
        };
        xOffset = parallelEdge.p1.x - edge1.p1.x;
      } else {
        startCoords = {
          x: Math.min(
            parallelEdge.p1.x,
            parallelEdge.p2.x,
            edge1.p1.x,
            edge1.p2.x
          ),
          y: parallelEdge.p1.y,
        };
        endCoords = {
          x: Math.max(
            parallelEdge.p1.x,
            parallelEdge.p2.x,
            edge1.p1.x,
            edge1.p2.x
          ),
          y: parallelEdge.p1.y,
        };
        yOffset = parallelEdge.p1.y - edge1.p1.y;
      }
      if (
        (xOffset !== null && Math.abs(xOffset) <= offset) ||
        (yOffset !== null && Math.abs(yOffset) <= offset)
      ) {
        lines.push({
          p1: startCoords,
          p2: endCoords,
        });
        if (xOffset !== 0) {
          updatedOffset = {
            x: xOffset ?? updatedOffset.x,
            y: yOffset ?? updatedOffset.y,
          };
        }
      }
    });
  });
  return { lines, offset: updatedOffset };
};

export const getPolygonEdgeAlignmentFromSegment = (params: {
  segment: FloorPlanLine;
  polygon: Points;
  skipIndex?: number;
  offset?: number;
}): { lines: FloorPlanLine[]; offset: Coords } => {
  const {
    segment,
    polygon,
    skipIndex = NaN,
    offset = AUTO_ALIGN_OFFSET,
  } = params;

  const polygonEdges: FloorPlanLine[] = polygon.map((coords, index) => ({
    p1: coords,
    p2: polygon[(index + 1) % polygon.length],
  }));

  const lines: FloorPlanLine[] = [];
  let updatedOffset = { x: null, y: null };

  const parallelEdges = polygonEdges.filter(
    (edge, index) => isSegmentsParallel(segment, edge) && index !== skipIndex
  );
  parallelEdges.forEach((parallelEdge) => {
    let startCoords: Coords;
    let endCoords: Coords;
    let xOffset: number = null;
    let yOffset: number = null;
    if (parallelEdge.p1.x === parallelEdge.p2.x) {
      startCoords = {
        x: parallelEdge.p1.x,
        y: Math.min(
          parallelEdge.p1.y,
          parallelEdge.p2.y,
          segment.p1.y,
          segment.p2.y
        ),
      };
      endCoords = {
        x: parallelEdge.p1.x,
        y: Math.max(
          parallelEdge.p1.y,
          parallelEdge.p2.y,
          segment.p1.y,
          segment.p2.y
        ),
      };
      xOffset = parallelEdge.p1.x - segment.p1.x;
    } else {
      startCoords = {
        x: Math.min(
          parallelEdge.p1.x,
          parallelEdge.p2.x,
          segment.p1.x,
          segment.p2.x
        ),
        y: parallelEdge.p1.y,
      };
      endCoords = {
        x: Math.max(
          parallelEdge.p1.x,
          parallelEdge.p2.x,
          segment.p1.x,
          segment.p2.x
        ),
        y: parallelEdge.p1.y,
      };
      yOffset = parallelEdge.p1.y - segment.p1.y;
    }
    if (
      (xOffset !== null && Math.abs(xOffset) <= offset) ||
      (yOffset !== null && Math.abs(yOffset) <= offset)
    ) {
      lines.push({
        p1: startCoords,
        p2: endCoords,
      });
      updatedOffset = {
        x: xOffset ?? updatedOffset.x,
        y: yOffset ?? updatedOffset.y,
      };
    }
  });

  return { lines, offset: updatedOffset };
};

export const drawRect = (
  g: PixiGraphics,
  options: {
    color?: number;
    interiorColor?: number;
    exteriorColor?: number;
    position: Coords;
    width: number;
    height: number;
    texture?: Texture<Resource>;
  }
) => {
  const {
    color,
    interiorColor,
    exteriorColor,
    position,
    width,
    height,
    texture,
  } = options;

  if (texture) {
    g.beginTextureFill({ texture });
    g.drawRect(position.x, position.y, width, height);
    g.endFill();
  } else if (color) {
    g.beginFill(color);
    g.drawRect(position.x, position.y, width, height);
    g.endFill();
  } else if (interiorColor && exteriorColor) {
    g.beginFill(interiorColor);
    g.drawRect(position.x + width / 2, position.y, width / 2, height);
    g.endFill();

    g.beginFill(exteriorColor);
    g.drawRect(position.x, position.y, width / 2, height);
    g.endFill();
  }
};

export const drawBorderedRect = (
  g: PixiGraphics,
  options: {
    bgColor?: number;
    interiorColor?: number;
    exteriorColor?: number;
    position: Coords;
    width: number;
    height: number;
    borderColor: number;
    borderWidth: number;
    borderType?: RectBorderType;
    drawTop?: boolean;
    drawBottom?: boolean;
    drawLeft?: boolean;
    drawRight?: boolean;
    texture?: Texture<Resource>;
  }
) => {
  const {
    bgColor,
    interiorColor,
    exteriorColor,
    position,
    width,
    height,
    borderColor,
    borderWidth,
    borderType = RectBorderType.INSIDE,
    drawTop = true,
    drawBottom = true,
    drawLeft = true,
    drawRight = true,
    texture,
  } = options;

  const offset = borderType === RectBorderType.OUTSIDE ? 0 : borderWidth;
  const drawX = position.x + offset;
  const drawY = position.y + offset;
  const drawWidth = width - offset * 2;
  const drawHeight = height - offset * 2;

  const adjustedX = drawLeft ? drawX : drawX - borderWidth;
  const adjustedY = drawTop ? drawY : drawY - borderWidth;
  const adjustedWidth = drawRight ? drawWidth : drawWidth + borderWidth;
  const adjustedHeight = drawBottom ? drawHeight : drawHeight + borderWidth;

  if (texture) {
    g.beginTextureFill({ texture });
    g.drawRect(adjustedX, adjustedY, adjustedWidth, adjustedHeight);
    g.endFill();
  } else if (bgColor) {
    g.beginFill(bgColor);
    g.drawRect(adjustedX, adjustedY, adjustedWidth, adjustedHeight);
    g.endFill();
  } else if (interiorColor && exteriorColor) {
    g.beginFill(interiorColor);
    g.drawRect(
      adjustedX + adjustedWidth / 2,
      adjustedY,
      adjustedWidth / 2,
      adjustedHeight
    );
    g.endFill();

    g.beginFill(exteriorColor);
    g.drawRect(adjustedX, adjustedY, adjustedWidth / 2, adjustedHeight);
    g.endFill();
  }

  g.lineStyle(borderWidth, borderColor);

  const drawBorderLine = (
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ) => {
    g.moveTo(startX, startY);
    g.lineTo(endX, endY);
  };

  if (drawTop) {
    drawBorderLine(adjustedX, adjustedY, adjustedX + adjustedWidth, adjustedY);
  }
  if (drawBottom) {
    drawBorderLine(
      adjustedX,
      adjustedY + adjustedHeight,
      adjustedX + adjustedWidth,
      adjustedY + adjustedHeight
    );
  }
  if (drawLeft) {
    drawBorderLine(adjustedX, adjustedY, adjustedX, adjustedY + adjustedHeight);
  }
  if (drawRight) {
    drawBorderLine(
      adjustedX + adjustedWidth,
      adjustedY,
      adjustedX + adjustedWidth,
      adjustedY + adjustedHeight
    );
  }

  g.lineStyle(0);
};

export const drawDashedLine = (
  g: PixiGraphics,
  startX: number,
  startY: number,
  endX: number,
  endY: number,
  dash: number,
  gap: number
) => {
  const totalLength = Math.hypot(endX - startX, endY - startY);
  const directionX = (endX - startX) / totalLength;
  const directionY = (endY - startY) / totalLength;

  let currentPos = 0;
  let isFirstDash = true;

  while (currentPos < totalLength) {
    const dashLength = isFirstDash ? dash / 2 : dash;
    const nextPos = Math.min(currentPos + dashLength, totalLength);

    const x1 = startX + directionX * currentPos;
    const y1 = startY + directionY * currentPos;
    const x2 = startX + directionX * nextPos;
    const y2 = startY + directionY * nextPos;

    g.moveTo(x1, y1);
    g.lineTo(x2, y2);

    currentPos = nextPos + gap;
    isFirstDash = false;
  }
};

export const drawDashedArc = (
  g: PixiGraphics,
  radius: number,
  startAngleRad: number,
  endAngleRad: number,
  dash: number,
  gap: number,
  startX: number,
  startY: number
) => {
  const totalAngle = endAngleRad - startAngleRad;
  const totalLength = radius * totalAngle;

  const dashCount = Math.floor(totalLength / (dash + gap));
  const angleStep = totalAngle / dashCount;
  const dashStep = (dash / totalLength) * totalAngle;

  let theta1 = startAngleRad;

  for (let i = 0; i < dashCount; i++) {
    const theta2 = theta1 + dashStep;

    const cosTheta1 = Math.cos(theta1);
    const sinTheta1 = Math.sin(theta1);
    const cosTheta2 = Math.cos(theta2);
    const sinTheta2 = Math.sin(theta2);

    g.moveTo(startX + cosTheta1 * radius, startY + sinTheta1 * radius);
    g.lineTo(startX + cosTheta2 * radius, startY + sinTheta2 * radius);

    theta1 += angleStep;
  }
};

export const drawDashedBorderedRect = (
  g: PixiGraphics,
  options: {
    bgColor?: number;
    interiorColor?: number;
    exteriorColor?: number;
    position: Coords;
    width: number;
    height: number;
    borderColor: number;
    borderWidth: number;
    borderType?: RectBorderType;
    dash?: number;
    gap?: number;
    texture?: Texture<Resource>;
  }
) => {
  const {
    bgColor,
    interiorColor,
    exteriorColor,
    position,
    width,
    height,
    borderColor,
    borderWidth,
    dash = 6,
    gap = 6,
    texture,
  } = options;

  g.clear();

  if (texture) {
    g.beginTextureFill({ texture });
    g.drawRect(position.x, position.y, width, height);
    g.endFill();
  } else if (bgColor) {
    g.beginFill(bgColor);
    g.drawRect(position.x, position.y, width, height);
    g.endFill();
  } else if (interiorColor && exteriorColor) {
    g.beginFill(interiorColor);
    g.drawRect(position.x + width / 2, position.y, width / 2, height);
    g.endFill();

    g.beginFill(exteriorColor);
    g.drawRect(position.x, position.y, width / 2, height);
    g.endFill();
  }

  const drawX = position.x + borderWidth;
  const drawY = position.y + borderWidth;
  const drawWidth = width - borderWidth * 2;
  const drawHeight = height - borderWidth * 2;

  g.lineStyle(borderWidth, borderColor);

  drawDashedLine(g, drawX, drawY, drawX + drawWidth, drawY, dash, gap);
  drawDashedLine(
    g,
    drawX + drawWidth,
    drawY,
    drawX + drawWidth,
    drawY + drawHeight,
    dash,
    gap
  );
  drawDashedLine(
    g,
    drawX + drawWidth,
    drawY + drawHeight,
    drawX,
    drawY + drawHeight,
    dash,
    gap
  );
  drawDashedLine(g, drawX, drawY + drawHeight, drawX, drawY, dash, gap);

  g.lineStyle(0);
};

export const calcShapeFromTwoCoords = (
  startCoords: Coords,
  endCoords: Coords
): Points => {
  const topLeft = {
    x: Math.min(startCoords.x, endCoords.x),
    y: Math.min(startCoords.y, endCoords.y),
  };
  const bottomRight = {
    x: Math.max(startCoords.x, endCoords.x),
    y: Math.max(startCoords.y, endCoords.y),
  };
  const topRight = { x: bottomRight.x, y: topLeft.y };
  const bottomLeft = { x: topLeft.x, y: bottomRight.y };

  return fixShapeCoords([topLeft, topRight, bottomRight, bottomLeft, topLeft]);
};

export const isValidPolygon = (polygon: Coords[]): boolean => {
  return polygon && polygon.length >= POLYGON_MINIMUM_COORDS_COUNT;
};

export const calcPolygonIntersection = (params: {
  shape1: Points;
  shape2: Points;
  distanceEnabled?: boolean;
}): Coords[] => {
  const { shape1, shape2, distanceEnabled = true } = params;
  if (!isValidPolygon(shape1) || !isValidPolygon(shape2)) {
    return [];
  }

  const polygon1 = [mapPointsToList(shape1)];
  const polygon2 = [mapPointsToList(shape2)];
  const intersection = polygonClipping.intersection(polygon1, polygon2);
  if (intersection.length > 0) {
    return fixShapeCoords(
      mapListOfPointsToCoords({
        pointsList: intersection[0][0],
      })
    );
  } else {
    if (distanceEnabled) {
      const flattenPolygon1 = new Polygon(mapPointsToList(shape1));
      const flattenPolygon2 = new Polygon(mapPointsToList(shape2));
      const [distance] = flattenPolygon1.distanceTo(flattenPolygon2);
      if (distance === 0) {
        return fixShapeCoords(shape1);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
};

export const calcPolygonSubtract = (
  shape1: Points,
  shape2: Points
): Coords[][] => {
  if (!isValidPolygon(shape1) || !isValidPolygon(shape2)) {
    return [];
  }

  const polygon1 = [mapPointsToList(shape1)];
  const polygon2 = [mapPointsToList(shape2)];
  const difference = polygonClipping.difference(polygon1, polygon2);
  if (difference.length > 0) {
    return difference.map((subtract) =>
      fixShapeCoords(
        mapListOfPointsToCoords({
          pointsList: subtract[0],
        })
      )
    );
  } else {
    return [];
  }
};

export const calcMultiPolygonSubtract = (
  shapes1: Points[],
  shapes2: Points[]
): Coords[][] => {
  const result: Coords[][] = [];
  const shapes2Counts = shapes2.length;
  for (let i = 0; i < shapes2Counts; i++) {
    const fromShapes = result.length === 0 ? [...shapes1] : [...result];
    const fromShapesCount = fromShapes.length;
    for (let j = 0; j < fromShapesCount; j++) {
      const subtractPolygons = calcPolygonSubtract(fromShapes[j], shapes2[i]);
      if (subtractPolygons.length > 0) {
        result[j] = subtractPolygons[0];
        subtractPolygons.splice(0, 1);
        if (subtractPolygons.length > 0) {
          result.push(...subtractPolygons);
        }
      }
    }
  }
  return result;
};

export const calcPolygonUnion = (shape1: Points, shape2: Points): Coords[] => {
  if (!isValidPolygon(shape1) || !isValidPolygon(shape2)) {
    return [];
  }

  const polygon1 = [mapPointsToList(shape1)];
  const polygon2 = [mapPointsToList(shape2)];
  const union = polygonClipping.union(polygon1, polygon2);
  if (union.length > 0) {
    return fixShapeCoords(
      mapListOfPointsToCoords({
        pointsList: union[0][0],
      })
    );
  } else {
    return [];
  }
};

export const getFloorPlanAreaSizes = (params: {
  precision?: number;
  points: Coords[];
  wallMeasurements: FloorPlanMeasurementsValues;
}) => {
  const { precision = 8, points, wallMeasurements } = params;
  const physicalPoints = calcSelectableBaseShape(points);
  const polygon = new Polygon(mapPointsToList(physicalPoints));
  const unitlessArea = Number(polygon.area().toFixed(precision));

  const measurementPoints = createMeasurementPolygon({
    baseShape: physicalPoints,
    wallMeasurements,
  });
  const measurementPolygon = new Polygon(mapPointsToList(measurementPoints));
  const mUnitlessArea = converSqInchesToSqFeet(
    Number(measurementPolygon.area().toFixed(precision))
  );
  const scale = unitlessArea / mUnitlessArea;

  const realSize: AreaMeasurement = {
    unit: "sqft",
    value: Number(mUnitlessArea.toFixed(8)),
  };

  return {
    size: unitlessArea,
    sizeScale: scale,
    realSize,
  };
};

export const getPolygonSQFT = (
  points: Coords[] | Polygon,
  params: {
    precision?: number;
    roomPlan: Pick<RoomPlan, "realSize" | "size"> | undefined;
  }
): AreaMeasurement => {
  const { precision = 8, roomPlan = {} } = params;
  const { realSize, size } = roomPlan || {};
  const polygon =
    points instanceof Polygon ? points : new Polygon(mapPointsToList(points));

  const unitlessArea = polygon.area();
  // Note(pavel): Add units checking once we have something other that sqft.
  const realSizeValue = realSize?.value || 0;
  // avoid dividing by zero
  const scale = !size ? 0 : realSizeValue / size;

  const result: AreaMeasurement = {
    unit: "sqft",
    value: Number((unitlessArea * scale).toFixed(precision)),
  };

  return result;
};

export const isPolygonContainsCoords = (
  shape: Points,
  coords: Coords
): boolean => {
  const polygon = new Polygon(mapPointsToList(shape));
  const point = new Point(coords.x, coords.y);
  return polygon.contains(point);
};

export const removeSmallSegmentsFromPolygon = (params: {
  shape: Coords[];
  segmentMinLength: number;
}): Coords[] => {
  const { shape, segmentMinLength } = params;

  if (shape.length === 0) {
    return [];
  }

  const result: Coords[] = [];

  for (let i = 0; i < shape.length - 1; i++) {
    const segment: FloorPlanLine = { p1: shape[i], p2: shape[i + 1] };

    if (getSegmentLength(segment) >= segmentMinLength) {
      result.push(shape[i]);
    } else {
      if (i < shape.length - 2) {
        i++;
      } else {
        result.shift();
      }
    }
  }

  if (result.length > 0) {
    result.push(result[0]);
  }

  return result;
};

export const isPolygonLinesIncludeCoords = (
  shape: Coords[],
  coords: Coords
): boolean => {
  return shape.some(
    (point, i) =>
      i < shape.length - 1 &&
      isSegmentContainsPoint({ p1: point, p2: shape[i + 1] }, coords)
  );
};

export const isPolygonContainsPolygon = (
  shape1: Coords[],
  shape2: Coords[]
): boolean => {
  const polygon1 = new Polygon(mapPointsToList(shape1));
  const polygon2 = new Polygon(mapPointsToList(shape2));
  return polygon1.contains(polygon2);
};

export const getPolygonSegmentsWithinDistanceFromCoords = (
  polygon: Coords[],
  targetCoords: Coords,
  maxDistance: number
): FloorPlanLine[] => {
  const segmentCount = polygon.length - 1;
  const closeSegments: FloorPlanLine[] = [];
  const targetPoint = new Point(targetCoords.x, targetCoords.y);

  for (let i = 0; i < segmentCount; i++) {
    const segment = new Line(
      new Point(polygon[i].x, polygon[i].y),
      new Point(polygon[i + 1].x, polygon[i + 1].y)
    );
    const [distanceToSegment] = targetPoint.distanceTo(segment);

    if (distanceToSegment < maxDistance) {
      closeSegments.push({
        p1: polygon[i],
        p2: polygon[i + 1],
      });
    }
  }

  return closeSegments;
};

export const getLinesFromShapes = (shapes: Points[]): FloorPlanLine[] => {
  return shapes
    .flatMap((shape) => mapPointsToConnectedLines(shape))
    .filter((line) => getSegmentLength(line) !== 0);
};

const crossProduct = (p1: Coords, p2: Coords, p3: Coords): number => {
  return (p2.x - p1.x) * (p3.y - p1.y) - (p2.y - p1.y) * (p3.x - p1.x);
};

const calculateConvexHull = (points: Coords[]): Coords[] => {
  if (points.length <= 1) {
    return points;
  }

  const sortedPoints = points
    .slice()
    .sort((a, b) => (a.x === b.x ? a.y - b.y : a.x - b.x));

  const lower: Coords[] = [];
  for (const point of sortedPoints) {
    while (
      lower.length >= 2 &&
      crossProduct(lower[lower.length - 2], lower[lower.length - 1], point) <= 0
    ) {
      lower.pop();
    }
    lower.push(point);
  }

  const upper: Coords[] = [];
  for (const point of [...sortedPoints].reverse()) {
    while (
      upper.length >= 2 &&
      crossProduct(upper[upper.length - 2], upper[upper.length - 1], point) <= 0
    ) {
      upper.pop();
    }
    upper.push(point);
  }

  upper.pop();
  lower.pop();

  return lower.concat(upper);
};

export const calculateOutlinePolygon = (polygons: Points[]): Coords[] => {
  const allCoords = polygons.flatMap((polygon) => polygon);
  return calculateConvexHull(allCoords);
};

export const fixShapeCoords = (shape: Coords[]): Coords[] => {
  const _shape = cloneDeep(shape);
  return _shape.map((item) => fixCoords(item));
};

export const fixCoords = (coords: Coords): Coords => {
  return {
    x: Number(toFixed(coords.x)),
    y: Number(toFixed(coords.y)),
  };
};

const createMeasurementPolygon = (params: {
  baseShape: Points;
  wallMeasurements: FloorPlanMeasurementsValues;
}): Points => {
  const { baseShape, wallMeasurements } = params;
  const newPolygon: Points = [{ x: 0, y: 0 }];

  for (let i = 0; i < baseShape.length - 2; i++) {
    const measurement = wallMeasurements.find(
      (measurement) => measurement.id === i.toString()
    )?.value;
    if (measurement) {
      const bP1 = baseShape[i];
      const bP2 = baseShape[i + 1];

      const nP1 = newPolygon[i];

      const baseVector = {
        x: bP2.x - bP1.x,
        y: bP2.y - bP1.y,
      };

      const baseLength = Math.sqrt(
        baseVector.x * baseVector.x + baseVector.y * baseVector.y
      );

      const normalizedVector = {
        x: baseVector.x / baseLength,
        y: baseVector.y / baseLength,
      };

      const nP2 = {
        x: nP1.x + normalizedVector.x * measurement,
        y: nP1.y + normalizedVector.y * measurement,
      };

      newPolygon.push(nP2);
    }
  }
  newPolygon.push(newPolygon[0]);

  return newPolygon;
};

export const getSpriteCoords = (sprite: Sprite) => {
  const { x, y, width, height } = sprite.getBounds();
  return getCoordsFromBounds({ x, y, width, height });
};

export const getCoordsFromBounds = ({
  x,
  y,
  width,
  height,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
}) => {
  return [
    { x, y },
    { x: x + width, y },
    { x: x + width, y: y + height },
    { x, y: y + height },
  ];
};

export const calcPolygonArea = (shape: Coords[]): number => {
  const polygon = new Polygon(mapPointsToList(shape));
  return polygon.area();
};
