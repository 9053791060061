import don_bailey_flooring_logo_fallback from "assets/integration/don_bailey_flooring.png";
import don_bailey_flooring_logo from "assets/integration/don_bailey_flooring.webp";
import { ReactComponent as DonBaileyFlooringSlogan } from "assets/integration/don_bailey_flooring_slogan.svg";
import ferguson_logo_fallback from "assets/integration/ferguson.png";
import ferguson_logo from "assets/integration/ferguson.webp";
import { ReactComponent as FergusonSlogan } from "assets/integration/ferguson_slogan.svg";
import floor_and_decor_logo_fallback from "assets/integration/floor_and_decor.png";
import floor_and_decor_logo from "assets/integration/floor_and_decor.webp";
import { ReactComponent as FloorAndDecorSlogan } from "assets/integration/floor_and_decor_slogan.svg";
import home_depot_logo_fallback from "assets/integration/home_depot.png";
import home_depot_logo from "assets/integration/home_depot.webp";
import { ReactComponent as HomeDepotSlogan } from "assets/integration/home_depot_slogan.svg";
import lumber_liquidators_logo_fallback from "assets/integration/lumber_liquidators.png";
import lumber_liquidators_logo from "assets/integration/lumber_liquidators.webp";
import { ReactComponent as LumberLiquidatorsSlogan } from "assets/integration/lumber_liquidators_slogan.svg";
import nook_place_logo_fallback from "assets/integration/nook_place.png";
import nook_place_logo from "assets/integration/nook_place.webp";
import { ReactComponent as NookPlaceSlogan } from "assets/integration/nook_place_slogan.svg";
import { InteriorFloorMaterialTypes } from "constants/guides/FloorMaterial/InteriorFloorMaterial/enums";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { GuideType } from "constants/guides/GuideType";
import { getEphemeral } from "core/persistence";
import { OrchestratorMachineContext } from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { GuideIntentType } from "pages/Guides/enums";
import { FC } from "react";
import { ImageItem } from "shared/types/ImageItem";
import { SvgIconComponent } from "shared/types/SvgIcon";

import { PartnerLogoAndCaptionLoader } from "./components/PartnerLogoAndCaptionLoader/PartnerLogoAndCaptionLoader";

const EMBEDDED_GUIDE_PARTNER_GUIDE_STORAGE_KEY = "embedded-guide-partner";
const VENDOR_EMBEDDED_GUIDE_PARTNER_GUIDE_STORAGE_KEY =
  "vendor-embedded-guide-partner";

export enum EMBEDDED_GUIDE_PARTNER {
  // Note(Andrei): This company was made for demo purposes only.
  "Nook Place" = "Nook Place",
  "The Home Depot" = "The Home Depot",
  "Lumber Liquidators" = "Lumber Liquidators",
  "Don Bailey Flooring" = "Don Bailey Flooring",
  "Ferguson" = "Ferguson",
  "Floor & Decor" = "Floor & Decor",
}

export const setVendorEmbeddedGuidePartner = (
  partner: EMBEDDED_GUIDE_PARTNER
) => {
  getEphemeral().setStringSync(
    VENDOR_EMBEDDED_GUIDE_PARTNER_GUIDE_STORAGE_KEY,
    partner
  );
};

export const getEmbeddedGuidePartnerName = (allowVendor = true) => {
  const urlParams = new URLSearchParams(document.location.search);
  const partner = urlParams.get("partner") as EMBEDDED_GUIDE_PARTNER;
  if (EMBEDDED_GUIDE_PARTNER[partner]) {
    getEphemeral().setStringSync(
      EMBEDDED_GUIDE_PARTNER_GUIDE_STORAGE_KEY,
      partner
    );
    return partner as EMBEDDED_GUIDE_PARTNER;
  }
  const sessionPartner = getEphemeral().getStringSync(
    EMBEDDED_GUIDE_PARTNER_GUIDE_STORAGE_KEY
  ) as EMBEDDED_GUIDE_PARTNER;
  if (EMBEDDED_GUIDE_PARTNER[sessionPartner]) {
    return sessionPartner;
  }
  const vendorPartner = getEphemeral().getStringSync(
    VENDOR_EMBEDDED_GUIDE_PARTNER_GUIDE_STORAGE_KEY
  ) as EMBEDDED_GUIDE_PARTNER;
  if (allowVendor && EMBEDDED_GUIDE_PARTNER[vendorPartner]) {
    return vendorPartner;
  }
  return null;
};

const EMBEDDED_GUIDE_PARTNER_LOGOS: Record<EMBEDDED_GUIDE_PARTNER, ImageItem> =
  {
    [EMBEDDED_GUIDE_PARTNER["The Home Depot"]]: {
      src: home_depot_logo,
      fallbackSrc: home_depot_logo_fallback,
    },
    [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: {
      src: lumber_liquidators_logo,
      fallbackSrc: lumber_liquidators_logo_fallback,
    },
    [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: {
      src: don_bailey_flooring_logo,
      fallbackSrc: don_bailey_flooring_logo_fallback,
    },
    [EMBEDDED_GUIDE_PARTNER["Ferguson"]]: {
      src: ferguson_logo,
      fallbackSrc: ferguson_logo_fallback,
    },
    [EMBEDDED_GUIDE_PARTNER["Floor & Decor"]]: {
      src: floor_and_decor_logo,
      fallbackSrc: floor_and_decor_logo_fallback,
    },
    [EMBEDDED_GUIDE_PARTNER["Nook Place"]]: {
      src: nook_place_logo,
      fallbackSrc: nook_place_logo_fallback,
    },
  };

const EMBEDED_GUIDE_PARTNER_DEMO_CONTEXT: Partial<
  Record<EMBEDDED_GUIDE_PARTNER, () => OrchestratorMachineContext>
> = {};

const EMBEDDED_GUIDE_PARTNER_CAPTIONS: Record<
  EMBEDDED_GUIDE_PARTNER,
  SvgIconComponent
> = {
  [EMBEDDED_GUIDE_PARTNER["The Home Depot"]]: HomeDepotSlogan,
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: LumberLiquidatorsSlogan,
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: DonBaileyFlooringSlogan,
  [EMBEDDED_GUIDE_PARTNER["Ferguson"]]: FergusonSlogan,
  [EMBEDDED_GUIDE_PARTNER["Floor & Decor"]]: FloorAndDecorSlogan,
  [EMBEDDED_GUIDE_PARTNER["Nook Place"]]: NookPlaceSlogan,
};

const EMEBEDED_GUIDE_PARTNER_LOADERS: Record<
  EMBEDDED_GUIDE_PARTNER,
  FC<{ onProceed: () => void }>
> = {
  [EMBEDDED_GUIDE_PARTNER["The Home Depot"]]: PartnerLogoAndCaptionLoader,
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: PartnerLogoAndCaptionLoader,
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: PartnerLogoAndCaptionLoader,
  [EMBEDDED_GUIDE_PARTNER["Ferguson"]]: PartnerLogoAndCaptionLoader,
  [EMBEDDED_GUIDE_PARTNER["Floor & Decor"]]: PartnerLogoAndCaptionLoader,
  [EMBEDDED_GUIDE_PARTNER["Nook Place"]]: PartnerLogoAndCaptionLoader,
};

const EMBEDDED_GUIDE_PARTNER_THEMES: Record<
  EMBEDDED_GUIDE_PARTNER,
  null | string
> = {
  [EMBEDDED_GUIDE_PARTNER["The Home Depot"]]: "home_depot_theme",
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: null,
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: null,
  [EMBEDDED_GUIDE_PARTNER["Ferguson"]]: null,
  [EMBEDDED_GUIDE_PARTNER["Floor & Decor"]]: null,
  [EMBEDDED_GUIDE_PARTNER["Nook Place"]]: null,
};

type ItemToIntent = Partial<Record<GuideItemTypeCategory, GuideIntentType[]>>;
type ItemToBoolean = Partial<Record<GuideItemTypeCategory, boolean>>;

const EMBEDDED_GUIDE_PARTNER_PREDEFINED_GUIDES: Partial<
  Record<EMBEDDED_GUIDE_PARTNER, Partial<Record<GuideType, ItemToIntent>>>
> = {
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: [GuideIntentType.ADD],
    },
  },
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: [GuideIntentType.ADD],
    },
  },
  [EMBEDDED_GUIDE_PARTNER["Ferguson"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: [GuideIntentType.ADD],
    },
  },
  [EMBEDDED_GUIDE_PARTNER["Floor & Decor"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: [GuideIntentType.ADD],
    },
  },
  [EMBEDDED_GUIDE_PARTNER["Nook Place"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: [GuideIntentType.ADD],
    },
    [GuideType.WINDOWS_AND_DOORS]: {
      [GuideItemTypeCategory.DOOR]: [GuideIntentType.ADD],
    },
  },
};

const EMBEDDED_GUIDE_PARTNER_SHOULD_SKIP_CS_GUIDES: Partial<
  Record<EMBEDDED_GUIDE_PARTNER, Partial<Record<GuideType, ItemToBoolean>>>
> = {
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: true,
    },
  },
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: true,
    },
  },
  [EMBEDDED_GUIDE_PARTNER["Ferguson"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: true,
    },
  },
  [EMBEDDED_GUIDE_PARTNER["Floor & Decor"]]: {
    [GuideType.FLOOR_MATERIAL]: {
      [GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL]: true,
    },
  },
};

const EMBEDDED_GUIDE_PARTNER_FLOOR_MATERIAL_ITEMS_TYPES_MAP: Partial<
  Record<EMBEDDED_GUIDE_PARTNER, InteriorFloorMaterialTypes[]>
> = {
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: [
    InteriorFloorMaterialTypes.WOOD,
  ],
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: [
    InteriorFloorMaterialTypes.WOOD,
    InteriorFloorMaterialTypes.TILE,
    InteriorFloorMaterialTypes.CARPET,
  ],
  [EMBEDDED_GUIDE_PARTNER["Ferguson"]]: [
    InteriorFloorMaterialTypes.WOOD,
    InteriorFloorMaterialTypes.TILE,
    InteriorFloorMaterialTypes.CARPET,
  ],
  [EMBEDDED_GUIDE_PARTNER["Floor & Decor"]]: [
    InteriorFloorMaterialTypes.WOOD,
    InteriorFloorMaterialTypes.TILE,
    InteriorFloorMaterialTypes.CARPET,
  ],
};

const EMBEDDED_GUIDE_CATEGORIES_MAP: Record<
  EMBEDDED_GUIDE_PARTNER,
  GuideType[]
> = {
  [EMBEDDED_GUIDE_PARTNER["The Home Depot"]]: [
    GuideType.FLOOR_MATERIAL,
    GuideType.ELECTRICAL,
  ],
  [EMBEDDED_GUIDE_PARTNER["Lumber Liquidators"]]: [GuideType.FLOOR_MATERIAL],
  [EMBEDDED_GUIDE_PARTNER["Don Bailey Flooring"]]: [GuideType.FLOOR_MATERIAL],
  [EMBEDDED_GUIDE_PARTNER["Ferguson"]]: [GuideType.FLOOR_MATERIAL],
  [EMBEDDED_GUIDE_PARTNER["Floor & Decor"]]: [GuideType.FLOOR_MATERIAL],
  [EMBEDDED_GUIDE_PARTNER["Nook Place"]]: [
    GuideType.ELECTRICAL,
    GuideType.FLOOR_MATERIAL,
    GuideType.WINDOWS_AND_DOORS,
  ],
};

export type EmbededPartnerConfig = ReturnType<typeof getEmbededPartnerConfig>;

export const getDemoResultGuideType = (partner: EMBEDDED_GUIDE_PARTNER) => {
  return partner ? GuideType.SHOP_INTEGRATION : GuideType.TRY_IT_OUT_GUIDE;
};

export const getEmbededPartnerConfig = (
  partner: EMBEDDED_GUIDE_PARTNER | null
) => {
  if (!partner) {
    return null;
  }
  return {
    partner,
    theme: EMBEDDED_GUIDE_PARTNER_THEMES[partner],
    loader: EMEBEDED_GUIDE_PARTNER_LOADERS[partner],
    caption: EMBEDDED_GUIDE_PARTNER_CAPTIONS[partner],
    logo: EMBEDDED_GUIDE_PARTNER_LOGOS[partner],
    floorMaterialItemsTypes:
      EMBEDDED_GUIDE_PARTNER_FLOOR_MATERIAL_ITEMS_TYPES_MAP[partner],
    predefinedGuides: EMBEDDED_GUIDE_PARTNER_PREDEFINED_GUIDES[partner],
    shouldSkipCsGuides: EMBEDDED_GUIDE_PARTNER_SHOULD_SKIP_CS_GUIDES[partner],
    demoContext: EMBEDED_GUIDE_PARTNER_DEMO_CONTEXT[partner],
    categories: EMBEDDED_GUIDE_CATEGORIES_MAP[partner],
  };
};
