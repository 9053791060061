import { useQuery } from "@tanstack/react-query";
import { ACCOUNT_DETAILS_QUERY_KEY } from "api/constants";
import { getUserDetails } from "api/homebase.api";
import { IS_TRY_IT_OUT_ENABLED } from "constants/env";
import { useIdentifyUser } from "core/telemetry";
import { isVendor, setVendor } from "integration/Vendor";

export const useAccountDetails = (isEnabled: boolean = true) => {
  const identifyUser = useIdentifyUser();
  return useQuery({
    queryKey: [ACCOUNT_DETAILS_QUERY_KEY],
    queryFn: async () => {
      const data = await getUserDetails();
      if (data?.is_partner) {
        setVendor();
      }
      identifyUser(data.id, {
        is_professional: data?.is_professional,
        is_partner: data?.is_partner,
      });
      return data;
    },
    staleTime: Infinity,
    enabled: isEnabled && (!IS_TRY_IT_OUT_ENABLED || isVendor()),
  });
};
