import { getIntegrationId } from "integration/Vendor";
import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useDidMount } from "rooks";

import { CoreContext, CoreContextState } from "./CoreContext";

const initialState: CoreContextState = {
  integrationId: null,
  globalOrchestratorMachine: null,
};

export const CoreContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useState<CoreContextState>(initialState);

  useDidMount(() => {
    const integrationId = getIntegrationId();
    if (integrationId) {
      setState({
        integrationId: Number(integrationId),
        globalOrchestratorMachine: null,
      });
    }
  });
  const setValue = useCallback(
    (
      key: keyof CoreContextState,
      value: CoreContextState[keyof CoreContextState]
    ) => {
      setState((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    []
  );

  const contextValue = useMemo(
    () => ({ ...state, setValue }),
    [state, setValue]
  );

  return (
    <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
  );
};
