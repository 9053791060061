import { smartTimeout } from "common/utils/testUtils";
import {
  HBSnackbar,
  SNACKBAR_TRANSITION_DURATION,
  SnackbarVariants,
} from "components/Snackbars/HBSnackbar/HBSnackbar";
import { FC, useCallback } from "react";

type Props = {
  id: string;
  onClose: (id: string) => void;
  title: string;
  variant: SnackbarVariants;
};

export const BasicSnackbar: FC<Props> = ({ id, onClose, title, variant }) => {
  const handleClose = useCallback(() => {
    smartTimeout(() => {
      onClose(id);
    }, SNACKBAR_TRANSITION_DURATION);
  }, [onClose, id]);
  return (
    <HBSnackbar
      open
      variant={variant}
      message={title}
      autoHideDuration={2000}
      onClose={onClose ? handleClose : undefined}
    />
  );
};
