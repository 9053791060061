import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";

import { KitchenApplianceConfig } from "./KitchenAppliance/config";
import { KitchenCabinetConfig } from "./KitchenCabinet/config";
import { KitchenFixtureConfig } from "./KitchenFixture/config";
import { KitchenLayoutConfig } from "./KitchenLayout/config";

export const Kitchen = {
  [GuideItemTypeCategory.KITCHEN_LAYOUT]: KitchenLayoutConfig,
  [GuideItemTypeCategory.KITCHEN_APPLIANCE]: KitchenApplianceConfig,
  [GuideItemTypeCategory.KITCHEN_FIXTURE]: KitchenFixtureConfig,
  [GuideItemTypeCategory.KITCHEN_CABINET]: KitchenCabinetConfig,
};
