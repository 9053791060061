import { Graphics as PixiGraphics } from "@pixi/graphics";
import { to0xHex } from "common/utils/colors";
import {
  FloorMaterialAreaShapes,
  Line,
  Points,
} from "components/HBFloorPlan/HBFloorPlan.types";
import { InteriorFloorMaterialTypes } from "constants/guides/FloorMaterial/InteriorFloorMaterial/enums";
import { InteriorFloorMaterial } from "constants/guides/FloorMaterial/InteriorFloorMaterial/types";
import { GuideItemTypeCategory } from "constants/guides/GuideItems.enums";
import { getNextGuideItemIndex } from "constants/guides/GuideItems.utils";
import { cloneDeep } from "lodash-es";
import { GuideIntentType } from "pages/Guides/enums";
import { PlaceFloorMaterialsEventData } from "pages/Guides/guides/FloorMaterialGuide/InteriorFloorMaterial/CS/machine/types";
import { Coords } from "pages/Guides/types";
import {
  RoomAndSpace,
  RoomPlan,
} from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { Sprite, Texture } from "pixi.js";
import { uuid } from "shared/util/uuid";
import colors from "styles/_jscolors.module.scss";

import { BASE_SHAPE_HIT_AREA_PADDING } from "./baseShape.utils";
import {
  calculateCastPointOfLineFromCoords,
  getSegmentAngleInRad,
  getSegmentMidPoint,
  intersectionsBetweenLine,
  isLineHorizontal,
  isLineVertical,
  isSegmentMoreHorizontal,
} from "./line.utils";
import { flattenPoints, isEqualCoords } from "./points.utils";
import {
  calcPolygonIntersection,
  calcPolygonSubtract,
  calcPolygonUnion,
  filterOverlappingVertices,
  fixShapeCoords,
  getPolygonSegmentsWithinDistanceFromCoords,
  getPolygonSQFT,
  isPolygonLinesIncludeCoords,
  isValidPolygon,
  offsetPolygon,
} from "./polygon.utils";

export const MATERIAL_HELPER_OPTION = {
  width: 52,
  height: 25,
  radius: 6,
  offset: 8,
};

export const ZERO_COORDS = { x: 0, y: 0 };

const MATERIAL_INDICATOR_RADIUS = 13;
const MATERIAL_INDICATOR_TRIANGLE_WIDTH = 10;
const MATERIAL_INDICATOR_TRIANGLE_HEIGHT = 5;
const MATERIAL_INDICATOR_TRIANGLE_OFFSET = 2;
const MATERIAL_INDICATOR_TRIANGLE_COLOR = to0xHex(colors.colorWhite);

const MATERIAL_CURSOR_LENGTH = 20;
const MATERIAL_CURSOR_THICKNESS = 2;
const MATERIAL_CURSOR_COLOR = to0xHex(colors.pureBlack);

export const MIN_SEGMENT_LENGTH = 15;

const WALL_SNAPPING_LENGTH = 15;

interface DrawFloorMaterialProps {
  g: PixiGraphics;
  options: {
    materialAreaShape: FloorMaterialAreaShapes;
    intent?: GuideIntentType | "cs";
    replaced?: boolean;
  };
}

type DrawFloorMaterialFn = (props: DrawFloorMaterialProps) => void;

const FloorMaterialBorderColors: Record<InteriorFloorMaterialTypes, number> = {
  [InteriorFloorMaterialTypes.TILE]: to0xHex(
    colors.colorFloorMaterialTileBorder
  ),
  [InteriorFloorMaterialTypes.WOOD]: to0xHex(
    colors.colorFloorMaterialWoodBorder
  ),
  [InteriorFloorMaterialTypes.BRICK]: to0xHex(
    colors.colorFloorMaterialBrickBorder
  ),
  [InteriorFloorMaterialTypes.CARPET]: to0xHex(
    colors.colorFloorMaterialCarpetBorder
  ),
  [InteriorFloorMaterialTypes.VINYL]: to0xHex(
    colors.colorFloorMaterialVinylBorder
  ),
  [InteriorFloorMaterialTypes.CONCRETE]: to0xHex(
    colors.colorFloorMaterialConcreteBorder
  ),
  [InteriorFloorMaterialTypes.NO_MATERIAL]: to0xHex(
    colors.colorFloorMaterialNoMaterialBorder
  ),
};

const FloorMaterialBackgroundIntentColors: Record<
  GuideIntentType | "cs",
  number
> = {
  cs: to0xHex(colors.colorWhite),
  [GuideIntentType.ADD]: to0xHex(colors.colorFloorMaterialADDBackground),
  [GuideIntentType.REMOVE]: to0xHex(colors.colorGrey100),
  [GuideIntentType.REPLACE]: to0xHex(colors.colorFloorMaterialRPLBackground),
  [GuideIntentType.MOVE]: to0xHex(colors.colorWhite),
};

const FloorMaterialBorderIntentColors: Record<GuideIntentType | "cs", number> =
  {
    cs: to0xHex(colors.colorWhite),
    [GuideIntentType.ADD]: to0xHex(colors.colorWhite),
    [GuideIntentType.REMOVE]: to0xHex(colors.colorFloorMaterialREMBorder),
    [GuideIntentType.REPLACE]: to0xHex(colors.colorWhite),
    [GuideIntentType.MOVE]: to0xHex(colors.colorWhite),
  };

const FloorMaterialReplacedBorderColor = to0xHex(
  colors.colorFloorMaterialReplacedBorder
);
const FloorMaterialReplacedBackgroundColor = to0xHex(
  colors.colorFloorMaterialReplacedBackground
);

/**
 * @see {@link https://www.figma.com/design/5CSqLJAPFBt1Zl8Gro79Cd/Floorplan-Assets?node-id=3630-7384&m=dev}
 */
const drawFloorMaterialGraphics = (props: DrawFloorMaterialProps) => {
  const { g, options } = props;
  const {
    materialAreaShape: { type, shape },
    intent = "cs",
    replaced,
  } = options;
  if (!shape || shape.length === 0) {
    return;
  }

  g.clear();

  const borderColor = replaced
    ? FloorMaterialReplacedBorderColor
    : intent === GuideIntentType.REMOVE
    ? FloorMaterialBorderIntentColors[intent]
    : FloorMaterialBorderColors[type];
  g.beginFill(borderColor);
  const cleansedUpdatedPoints = filterOverlappingVertices(shape);
  const path = flattenPoints(cleansedUpdatedPoints);
  g.drawPolygon(path).endFill();

  const backgroundColor = replaced
    ? FloorMaterialReplacedBackgroundColor
    : FloorMaterialBackgroundIntentColors[intent];
  g.beginFill(backgroundColor);
  const insidePolygon = offsetPolygon({
    points: cleansedUpdatedPoints,
    padding: 4,
  });
  const insidePath = flattenPoints(insidePolygon);
  g.drawPolygon(insidePath).endFill();
};

export const drawFloorMaterial: Partial<
  Record<InteriorFloorMaterialTypes, DrawFloorMaterialFn>
> = {
  [InteriorFloorMaterialTypes.TILE]: drawFloorMaterialGraphics,
  [InteriorFloorMaterialTypes.WOOD]: drawFloorMaterialGraphics,
  [InteriorFloorMaterialTypes.BRICK]: drawFloorMaterialGraphics,
  [InteriorFloorMaterialTypes.CARPET]: drawFloorMaterialGraphics,
  [InteriorFloorMaterialTypes.VINYL]: drawFloorMaterialGraphics,
  [InteriorFloorMaterialTypes.CONCRETE]: drawFloorMaterialGraphics,
  [InteriorFloorMaterialTypes.NO_MATERIAL]: drawFloorMaterialGraphics,
};

export const unionAndSubtractFloorMaterialShapes = <
  T extends FloorMaterialAreaShapes
>(
  materialAreaShapes: T[]
): T[] => {
  const _materialAreaShapes = cloneDeep(materialAreaShapes);
  let intersectsCount = 0;

  do {
    intersectsCount = 0;
    for (let i = 0; i < _materialAreaShapes.length; i++) {
      const fromAreaShape = _materialAreaShapes[i];
      const mergedIndex: number[] = [];
      for (let j = i + 1; j < _materialAreaShapes.length; j++) {
        const toAreaShape = _materialAreaShapes[j];
        if (
          calcPolygonIntersection({
            shape1: fromAreaShape.shape,
            shape2: toAreaShape.shape,
          }).length !== 0
        ) {
          if (fromAreaShape.type === toAreaShape.type) {
            mergedIndex.push(j);
            intersectsCount++;
            const unionShape = calcPolygonUnion(
              fromAreaShape.shape,
              toAreaShape.shape
            );
            fromAreaShape.shape = unionShape;
          } else {
            const subtractShape = calcPolygonSubtract(
              fromAreaShape.shape,
              toAreaShape.shape
            );
            fromAreaShape.shape = subtractShape[0];
            if (subtractShape.length > 1) {
              for (let k = 1; k < subtractShape.length; k++) {
                _materialAreaShapes.push({
                  ...fromAreaShape,
                  shape: subtractShape[k],
                } as T);
              }
            }
          }
        }
      }

      if (mergedIndex.length > 0) {
        const sortedIndex = mergedIndex.sort((a, b) => b - a);
        sortedIndex.forEach((index) => {
          _materialAreaShapes.splice(index, 1);
        });
      }
    }
  } while (intersectsCount > 0);

  const result = _materialAreaShapes
    .filter((item) => item.shape && isValidPolygon(item.shape))
    .map((item) => ({ ...item, shape: fixShapeCoords(item.shape) }));

  return result;
};

interface DrawMaterialHelperBackgroundProps {
  g: PixiGraphics;
  options: {
    type: InteriorFloorMaterialTypes;
  };
}

/**
 * @see {@link https://www.figma.com/design/5CSqLJAPFBt1Zl8Gro79Cd/Floorplan-Assets?node-id=3622-9136&m=dev}
 */
export const drawMaterialHelperBackground = (
  props: DrawMaterialHelperBackgroundProps
) => {
  const {
    g,
    options: { type },
  } = props;

  const { width, height, radius } = MATERIAL_HELPER_OPTION;
  const startX = -width / 2;
  const startY = -height / 2;
  const endX = width / 2;
  const endY = height / 2;

  g.clear();
  g.beginFill(FloorMaterialBorderColors[type]);
  g.moveTo(startX, startY);
  g.lineTo(endX - radius, startY);
  g.arcTo(endX, startY, endX, startY + radius, radius);
  g.lineTo(endX, endY - radius);
  g.arcTo(endX, endY, endX - radius, endY, radius);
  g.lineTo(startX + radius, endY);
  g.arcTo(startX, endY, startX, endY - radius, radius);
  g.lineTo(startX, startY);
  g.endFill();
};

export const toFloorMaterialsEventData = (args: {
  shapeData: FloorMaterialAreaShapes[];
  roomId: RoomAndSpace["id"];
  roomPlan: RoomPlan | undefined;
  existingItems?: InteriorFloorMaterial[];
}): PlaceFloorMaterialsEventData => {
  const { shapeData, roomId, existingItems, roomPlan } = args;

  const newItems = [];
  const length = shapeData.length;
  for (let i = 0; i < length; i++) {
    const item = shapeData[i];
    newItems.push({
      id: !item.id ? uuid() : item.id,
      index: getNextGuideItemIndex({
        existingItems: [...(existingItems || []), ...newItems],
        category: GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL,
        roomId,
      }),
      category: GuideItemTypeCategory.INTERIOR_FLOOR_MATERIAL,
      type: item.type,
      coords: { x: 0, y: 0 },
      shape: item.shape,
      realArea: getPolygonSQFT(item.shape, { roomPlan }),
      roomId,
    });
  }
  return newItems;
};

export const toFloorMaterialAreaShapes = (
  materialItems: InteriorFloorMaterial[],
  intent?: GuideIntentType
): FloorMaterialAreaShapes[] => {
  const result = materialItems.map((item) => ({
    type: item.type,
    shape: item.shape,
    id: item.id,
    intent,
  }));

  return result;
};

export const calcSelectableBaseShape = (originBaseShape: Points) => {
  const cleansedUpdatedPoints = filterOverlappingVertices(originBaseShape);

  const _offsetPolygon = offsetPolygon({
    points: cleansedUpdatedPoints,
    padding: BASE_SHAPE_HIT_AREA_PADDING,
  });
  return fixShapeCoords(_offsetPolygon);
};

export const calcMarginedBaseShape = (originBaseShape: Points) => {
  const cleansedUpdatedPoints = filterOverlappingVertices(originBaseShape);

  const _offsetPolygon = offsetPolygon({
    points: cleansedUpdatedPoints,
    margin: BASE_SHAPE_HIT_AREA_PADDING,
  });

  return fixShapeCoords(_offsetPolygon);
};

export const calcSelectableBaseShapeWithOffset = (originBaseShape: Points) => {
  const cleansedUpdatedPoints = filterOverlappingVertices(originBaseShape);

  const _offsetPolygon = offsetPolygon({
    points: cleansedUpdatedPoints,
    padding: BASE_SHAPE_HIT_AREA_PADDING - 1,
  });
  return fixShapeCoords(_offsetPolygon);
};

export const calcMouseMovableShape = (originBaseShape: Points) => {
  const cleansedUpdatedPoints = filterOverlappingVertices(originBaseShape);
  return cleansedUpdatedPoints;
};

/**
 * @see {@link https://www.figma.com/design/5CSqLJAPFBt1Zl8Gro79Cd/Floorplan-Assets?node-id=3619-2294&m=dev}
 */
export const drawMaterialResizeIndicator = (
  g: PixiGraphics,
  options: { type: InteriorFloorMaterialTypes }
) => {
  const { type } = options;

  g.clear();
  g.beginFill(FloorMaterialBorderColors[type]);
  g.drawCircle(0, 0, MATERIAL_INDICATOR_RADIUS);
  g.endFill();

  const triangleCoords1 = [
    {
      x: 0,
      y:
        MATERIAL_INDICATOR_TRIANGLE_HEIGHT + MATERIAL_INDICATOR_TRIANGLE_OFFSET,
    },
    {
      x: -MATERIAL_INDICATOR_TRIANGLE_WIDTH / 2,
      y: MATERIAL_INDICATOR_TRIANGLE_OFFSET,
    },
    {
      x: MATERIAL_INDICATOR_TRIANGLE_WIDTH / 2,
      y: MATERIAL_INDICATOR_TRIANGLE_OFFSET,
    },
  ];
  const triangleCoords2 = [
    {
      x: 0,
      y: -(
        MATERIAL_INDICATOR_TRIANGLE_HEIGHT + MATERIAL_INDICATOR_TRIANGLE_OFFSET
      ),
    },
    {
      x: -MATERIAL_INDICATOR_TRIANGLE_WIDTH / 2,
      y: -MATERIAL_INDICATOR_TRIANGLE_OFFSET,
    },
    {
      x: MATERIAL_INDICATOR_TRIANGLE_WIDTH / 2,
      y: -MATERIAL_INDICATOR_TRIANGLE_OFFSET,
    },
  ];

  const trianglePolygon1 = flattenPoints(triangleCoords1);
  const trianglePolygon2 = flattenPoints(triangleCoords2);

  g.beginFill(MATERIAL_INDICATOR_TRIANGLE_COLOR);
  g.drawPolygon(trianglePolygon1);
  g.drawPolygon(trianglePolygon2);
  g.endFill();

  g.interactive = true;
  g.cursor = "pointer";
};

export const floorMaterialIndicatorData = (
  shape: Coords[]
): { position: Coords; rotationRad: number }[] => {
  const segmentCount = shape.length - 1;
  const result: { position: Coords; rotationRad: number }[] = [];
  for (let i = 0; i < segmentCount; i++) {
    const segment = {
      p1: shape[i],
      p2: shape[i + 1],
    } as Line;
    const position = getSegmentMidPoint(segment);
    const rotationRad = getSegmentAngleInRad(segment);
    result.push({ position, rotationRad });
  }
  return result;
};

export const removeSlopeOfMaterialShape = (params: {
  shape: Coords[];
  baseShape: Coords[];
  movingCoords?: Coords;
}): Coords[] => {
  const { shape, baseShape, movingCoords } = params;
  const result: Coords[] = [];
  const _shape = [...shape];

  for (let i = 0; i < _shape.length - 1; i++) {
    const segment: Line = { p1: _shape[i], p2: _shape[i + 1] };
    if (isLineVertical(segment) || isLineHorizontal(segment)) {
      result.push(_shape[i]);
    } else {
      const isBaseShapeIncludesP1 = isPolygonLinesIncludeCoords(
        baseShape,
        segment.p1
      );
      const isBaseShapeIncludesP2 = isPolygonLinesIncludeCoords(
        baseShape,
        segment.p2
      );
      if (
        (isBaseShapeIncludesP1 && isBaseShapeIncludesP2) ||
        (!isBaseShapeIncludesP1 && !isBaseShapeIncludesP2)
      ) {
        if (!movingCoords) {
          result.push(_shape[i]);
        } else {
          if (isSegmentMoreHorizontal(segment)) {
            if (isEqualCoords(_shape[i], movingCoords)) {
              _shape[i].x = _shape[i + 1].x;
            } else {
              _shape[i + 1].x = _shape[i].x;
            }
          } else {
            if (isEqualCoords(_shape[i], movingCoords)) {
              _shape[i].y = _shape[i + 1].y;
            } else {
              _shape[i + 1].y = _shape[i].y;
            }
          }
          result.push(_shape[i]);
        }
      } else {
        if (movingCoords) {
          if (isSegmentMoreHorizontal(segment)) {
            if (isEqualCoords(_shape[i], movingCoords)) {
              _shape[i].x = _shape[i + 1].x;
            } else {
              _shape[i + 1].x = _shape[i].x;
            }
          } else {
            if (isEqualCoords(_shape[i], movingCoords)) {
              _shape[i].y = _shape[i + 1].y;
            } else {
              _shape[i + 1].y = _shape[i].y;
            }
          }
        } else {
          if (isSegmentMoreHorizontal(segment)) {
            if (isBaseShapeIncludesP1) {
              _shape[i + 1].x = _shape[i].x;
            } else {
              _shape[i].x = _shape[i + 1].x;
            }
          } else {
            if (isBaseShapeIncludesP1) {
              _shape[i + 1].y = _shape[i].y;
            } else {
              _shape[i].y = _shape[i + 1].y;
            }
          }
        }
        result.push(_shape[i]);
      }
    }
  }

  if (result.length > 0) {
    result.push(result[0]);
  }

  return result;
};

/**
 * @see {@link https://www.figma.com/design/5CSqLJAPFBt1Zl8Gro79Cd/Floorplan-Assets?node-id=3618-1596&m=dev}
 */
export const drawFloorMaterialCursor = (g: PixiGraphics) => {
  g.clear();
  g.lineStyle(MATERIAL_CURSOR_THICKNESS, MATERIAL_CURSOR_COLOR);
  g.moveTo(-MATERIAL_CURSOR_LENGTH / 2, 0);
  g.lineTo(MATERIAL_CURSOR_LENGTH / 2, 0);
  g.moveTo(0, -MATERIAL_CURSOR_LENGTH / 2);
  g.lineTo(0, MATERIAL_CURSOR_LENGTH / 2);
};

export const getSnappingPoint = (
  coords: Coords,
  baseShape: Coords[],
  otherItems: FloorMaterialAreaShapes[] = []
): Coords => {
  const wallSegments = getPolygonSegmentsWithinDistanceFromCoords(
    baseShape,
    coords,
    WALL_SNAPPING_LENGTH
  );
  otherItems.forEach((item) => {
    const segments = getPolygonSegmentsWithinDistanceFromCoords(
      item.shape,
      coords,
      WALL_SNAPPING_LENGTH
    );
    wallSegments.push(...segments);
  });
  if (wallSegments.length === 0) {
    return coords;
  } else if (wallSegments.length === 1) {
    const result = calculateCastPointOfLineFromCoords(wallSegments[0], coords);
    return result;
  } else {
    const intersections = intersectionsBetweenLine(
      wallSegments[0],
      wallSegments[1]
    );
    if (intersections.length > 0) {
      return intersections[0];
    } else {
      const result = calculateCastPointOfLineFromCoords(
        wallSegments[0],
        coords
      );
      return result;
    }
  }
};

export const createTextureSprite = ({
  position,
  texture,
  width,
  height,
}: {
  position: Coords;
  texture: Texture;
  width: number;
  height: number;
}): Sprite => {
  const sprite = new Sprite(texture);
  sprite.position.set(position.x, position.y);
  sprite.width = width;
  sprite.height = height;
  return sprite;
};

export const formatSQFTValue = (value: number) => {
  return value.toFixed(2);
};
