import { ColorSource } from "@pixi/core";
import { Graphics as PixiGraphics } from "@pixi/graphics";
import {
  getMeasurementActiveTextBoxColor,
  getMeasurementDisabledLineColor,
  getMeasurementLineColor,
  MEASUREMENT_INACTIVE_TEXT_BOX_COLOR,
  MEASUREMENT_LINE_ALPHA,
  MEASUREMENT_LINE_DISABLED_ALPHA,
  MEASUREMENT_LINE_EDGE_HEIGHT,
  MEASUREMENT_LINE_ERROR_ALPHA,
  MEASUREMENT_LINE_ERROR_COLOR,
  MEASUREMENT_LINE_OFFSET,
  MEASUREMENT_LINE_WIDTH,
  MEASUREMENT_TEXT_BOX_PADDING,
  MEASUREMENT_TEXT_BOX_TEXT_ERROR_COLOR,
  MEASUREMENT_TEXT_PADDING,
} from "components/HBFloorPlan/FloorPlan/components/FloorPlanMeasurements/FloorPlanMeasurements.constants";
import {
  FloorPlanMeasurementValues,
  Line,
} from "components/HBFloorPlan/HBFloorPlan.types";
import { Coords } from "pages/Guides/types";
import { TextMetrics, TextStyle } from "pixi.js";
import { TFunction } from "react-i18next";
import { EPSILON, WALL_THICKNESS } from "shared/floorPlan/constants";
import {
  getSegmentAngleInRad,
  getSegmentLength,
  getSegmentMidPoint,
  lineIntersectsLine,
} from "shared/floorPlan/utils/line.utils";

export const getMeasurementTextRotation = (line: Line): number => {
  const { p1, p2 } = line;

  const textRotation = p1.x - p2.x > EPSILON ? Math.PI : 0;

  return textRotation;
};

const TEXT_BOX_RADIUS = 4;

export const drawMeasurementLine = (
  g: PixiGraphics,
  length: number,
  options: { alpha: number; color: ColorSource }
) => {
  const { alpha, color } = options;
  const startX = (-1 * length) / 2;
  const endX = length / 2;

  g.clear();

  g.lineStyle(MEASUREMENT_LINE_WIDTH, color, alpha)
    .moveTo(startX, 0)
    .lineTo(endX, 0);

  g.lineStyle(MEASUREMENT_LINE_WIDTH, color, alpha)
    .moveTo(startX, -1 * MEASUREMENT_LINE_EDGE_HEIGHT)
    .lineTo(startX, MEASUREMENT_LINE_EDGE_HEIGHT)
    .moveTo(endX, -1 * MEASUREMENT_LINE_EDGE_HEIGHT)
    .lineTo(endX, MEASUREMENT_LINE_EDGE_HEIGHT);
};

export const drawTextBox = (
  g: PixiGraphics,
  options: {
    x: number;
    y: number;
    width: number;
    height: number;
    backgroundColor: number;
  }
) => {
  const { x, y, width, height, backgroundColor } = options;

  g.clear();
  g.beginFill(backgroundColor);
  g.drawRoundedRect(x, y, width, height, TEXT_BOX_RADIUS);
  g.endFill();
};

export const getWallMeasurementText = (props: {
  t: TFunction;
  index: number;
  measurement: FloorPlanMeasurementValues;
}) => {
  const { t, index, measurement } = props;

  const wallNumber = index + 1;
  const wallIdentifierText = t("Wall {{wallNumber}}", {
    wallNumber,
  });

  const hasNoValue = !measurement?.value;

  if (hasNoValue) {
    return wallIdentifierText;
  }

  const { value1, value2 } =
    convertGuideMeasurementValuesToFloorPlanMeasurementValues(
      measurement.value
    );

  const { unit1, unit2 } = measurement;

  const value1Text = value1 ? `${value1}${unit1}` : "";
  const value2Text = value2 ? `${value2}${unit2}` : "";
  const text = `${value1Text} ${value2Text}`;

  return text;
};

export const getKitchenLayoutMeasurementText = (props: {
  segment: Line;
  measurementUnit: number;
}) => {
  const { segment, measurementUnit } = props;
  const measurement = Math.round(getSegmentLength(segment) / measurementUnit);
  const { value1, value2 } =
    convertGuideMeasurementValuesToFloorPlanMeasurementValues(measurement);

  const unit1 = getMeasurementUnit1();
  const unit2 = getMeasurementUnit2();

  const value1Text = value1 ? `${value1}${unit1}` : "";
  const value2Text = value2 ? `${value2}${unit2}` : "";
  const text = `${value1Text} ${value2Text}`;

  return text;
};

const getWallPointsDelta = (wall: Line): { dx: number; dy: number } => {
  const { p1, p2 } = wall;
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;

  return { dx, dy };
};

export const getSegmentNormalUnitVector = (
  segment: Line,
  inverse: boolean = false
): {
  ndx: number;
  ndy: number;
} => {
  const { dx, dy } = getWallPointsDelta(segment);
  const length = getSegmentLength(segment);
  const dxn = dx / length;
  const dyn = dy / length;

  const dynMultiplier = inverse ? -1 : 1;
  const dxnMultiplier = inverse ? 1 : -1;

  const ndx = dynMultiplier * dyn;
  const ndy = dxnMultiplier * dxn;

  return {
    ndx,
    ndy,
  };
};

export const getMeasurementContainerRotation = (wall: Line): number => {
  const { dx, dy } = getWallPointsDelta(wall);
  const containerRotation = Math.atan2(dy, dx);
  return containerRotation;
};

export const getMeasurementUnit1 = (): string => {
  return "'";
};

export const getMeasurementUnit2 = (): string => {
  // eslint-disable-next-line quotes
  return '"';
};

export const getMeasurementInputLabel1 = (t: TFunction): string => {
  return t("feet");
};

export const getMeasurementInputLabel2 = (t: TFunction): string => {
  return t("inches");
};

export const converSqInchesToSqFeet = (inches: number) => {
  return inches / 144;
};

export const convertValueToFeetInches = (
  value: number
): { feet: number; inches: number } => {
  const feet = Math.floor(value / 12);
  const inches = value - 12 * feet;

  return {
    feet,
    inches,
  };
};

const convertFeetInchesToValue = (feet: number, inches: number): number => {
  return feet * 12 + inches;
};

export const convertGuideMeasurementValuesToFloorPlanMeasurementValues = (
  measurementValue: number
): { value1: number; value2: number } => {
  //NOTE(mate): Should be localised converters in the future
  const { feet, inches } = convertValueToFeetInches(measurementValue);

  return {
    value1: feet,
    value2: inches,
  };
};

export const convertFloorPlanMeasurementValuesToGuideMeasurementValues = (
  value1: number,
  value2: number
): number => {
  //NOTE(mate): Should be localised converters in the future
  const value = convertFeetInchesToValue(value1, value2);

  return value;
};

export const getWallLengthToMeasurementsValueUnit = (
  wallLength: number,
  value1: number,
  value2: number
): number => {
  const value = convertFeetInchesToValue(value1, value2);
  const unit = wallLength / value;

  return unit;
};

export const getWindowMeasurementText = (value: number): string => {
  const { value1, value2: _value2 } =
    convertGuideMeasurementValuesToFloorPlanMeasurementValues(value);
  const unit1 = getMeasurementUnit1();
  const unit2 = getMeasurementUnit2();
  const value2 = Math.round(_value2);

  const value1Text = value1 ? `${value1}${unit1}` : "";
  const value2Text = `${value2}${unit2}`;
  const text = `${value1Text} ${value2Text}`;

  return text;
};

export const getMeasurementTextGraphicParams = (params: {
  active: boolean;
  text: string;
  textStyle: TextStyle;
  error?: boolean;
}) => {
  const { active, text, textStyle, error } = params;
  const textMeasure = TextMetrics.measureText(text, textStyle);
  const width = textMeasure.width + 2 * MEASUREMENT_TEXT_PADDING;
  const height = textMeasure.lineHeight + 2 * MEASUREMENT_TEXT_BOX_PADDING;
  const x = (-1 * textMeasure.width) / 2 - MEASUREMENT_TEXT_PADDING;
  const y = (-1 * textMeasure.height) / 2 - MEASUREMENT_TEXT_BOX_PADDING;
  const backgroundColor = active
    ? getMeasurementActiveTextBoxColor()
    : error
    ? MEASUREMENT_TEXT_BOX_TEXT_ERROR_COLOR
    : MEASUREMENT_INACTIVE_TEXT_BOX_COLOR;

  return {
    width,
    height,
    x,
    y,
    backgroundColor,
  };
};

export const getMeasurementLineGraphicParams = (params: {
  disabled: boolean;
  error?: boolean;
}) => {
  const { disabled, error } = params;
  const color = disabled
    ? getMeasurementDisabledLineColor()
    : error
    ? MEASUREMENT_LINE_ERROR_COLOR
    : getMeasurementLineColor();

  const alpha = disabled
    ? MEASUREMENT_LINE_DISABLED_ALPHA
    : error
    ? MEASUREMENT_LINE_ERROR_ALPHA
    : MEASUREMENT_LINE_ALPHA;

  return { color, alpha };
};

const getWallMeasurementLine = (params: {
  wall: Line;
  measurementOffset?: number;
}): Line => {
  const { wall, measurementOffset = MEASUREMENT_LINE_OFFSET } = params;

  const wallRotationRad = getSegmentAngleInRad(wall);

  const dx = Math.sin(wallRotationRad) * measurementOffset;
  const dy = -Math.cos(wallRotationRad) * measurementOffset;

  return {
    p1: {
      x: wall.p1.x + dx,
      y: wall.p1.y + dy,
    },
    p2: {
      x: wall.p2.x + dx,
      y: wall.p2.y + dy,
    },
  } as Line;
};

export const getIntersectionsWithConnectedWalls = (params: {
  wall: Line;
  prevWall: Line;
  nextWall: Line;
}) => {
  const { wall, prevWall, nextWall } = params;
  const wallMeasurementLine = getWallMeasurementLine({ wall });
  const isPrevIntersected = lineIntersectsLine({
    line1: wallMeasurementLine,
    line2: prevWall,
  });
  const isNextIntersected = lineIntersectsLine({
    line1: wallMeasurementLine,
    line2: nextWall,
  });
  return {
    isPrevIntersected,
    isNextIntersected,
  };
};

export const getMeasurementLength = ({
  wall,
  isPrevIntersected,
  isNextIntersected,
}: {
  wall: Line;
  isPrevIntersected: boolean;
  isNextIntersected: boolean;
}) => {
  const intersections =
    (isPrevIntersected ? 1 : 0) + (isNextIntersected ? 1 : 0);
  return (
    getSegmentLength(wall) -
    WALL_THICKNESS * (1 - intersections) -
    MEASUREMENT_LINE_WIDTH
  );
};

export const getWallMidPointWithIntersections = ({
  wall,
  isPrevIntersected,
  isNextIntersected,
}: {
  wall: Line;
  isPrevIntersected: boolean;
  isNextIntersected: boolean;
}): Coords => {
  let midPoint = getSegmentMidPoint(wall);
  const length = getSegmentLength(wall);

  if (isPrevIntersected) {
    const direction = {
      x: wall.p2.x - wall.p1.x,
      y: wall.p2.y - wall.p1.y,
    };
    const unitDirection = {
      x: direction.x / length,
      y: direction.y / length,
    };
    const offset = {
      x: (unitDirection.x * WALL_THICKNESS) / 2,
      y: (unitDirection.y * WALL_THICKNESS) / 2,
    };
    midPoint = {
      x: midPoint.x - offset.x,
      y: midPoint.y - offset.y,
    };
  }
  if (isNextIntersected) {
    const direction = {
      x: wall.p1.x - wall.p2.x,
      y: wall.p1.y - wall.p2.y,
    };
    const unitDirection = {
      x: direction.x / length,
      y: direction.y / length,
    };
    const offset = {
      x: (unitDirection.x * WALL_THICKNESS) / 2,
      y: (unitDirection.y * WALL_THICKNESS) / 2,
    };
    midPoint = {
      x: midPoint.x - offset.x,
      y: midPoint.y - offset.y,
    };
  }
  return midPoint;
};
