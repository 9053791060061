import { assign } from "@xstate/immer";
import { StateActionEvent } from "common/state/types";
import { createMachine, interpret } from "xstate";

export type ToastTypes = "tooManyRequests" | "basic";

type ToastContext = {
  id: string;
  type: ToastTypes;
  meta?: Record<string, unknown>;
};

type ToastPayload = Pick<ToastContext, "meta" | "type">;

interface Context {
  toasts: ToastContext[];
}

export const addToast = (data: ToastPayload) => {
  return { type: "ADD_TOAST", data };
};

export const removeToast = (data: { id: string }) => {
  return { type: "REMOVE_TOAST", data };
};

const systemSnackbarMachine = createMachine<Context>({
  id: "systemSnackbarMachine",
  initial: "ready",
  context: {
    toasts: [],
  },
  states: {
    ready: {},
  },
  on: {
    ADD_TOAST: {
      actions: assign((ctx, data: StateActionEvent<ToastPayload>) => {
        ctx.toasts.push({
          ...data.data,
          id: Math.random().toString(36).substring(2, 9),
        });
      }),
    },
    REMOVE_TOAST: {
      actions: assign((ctx, data: StateActionEvent<ToastContext>) => {
        ctx.toasts = ctx.toasts.filter((toast) => toast.id !== data.data.id);
      }),
    },
  },
  predictableActionArguments: true,
  preserveActionOrder: true,
});

export const systemSnackbarMachineActor = interpret(
  systemSnackbarMachine
).start();
