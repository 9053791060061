export enum KitchenApplianceTypes {
  RANGE_OVEN = "KA_RANGE_OVEN",
  COOKTOP = "KA_COOKTOP",
  RANGETOP = "KA_RANGETOP",
  WALL_OVEN = "KA_WALL_OVEN",
  KITCHEN_VENT = "KA_KITCHEN_VENT",
  REFRIGERATOR_FREEZER = "KA_REFRIGERATOR_FREEZER",
  REFRIGERATED_DRAWER = "KA_REFRIGERATED_DRAWER",
  FREEZER_DRAWER = "KA_FREEZER_DRAWER",
  DISHWASHER = "KA_DISHWASHER",
  WINE_COOLER = "KA_WINE_COOLER",
  BEVERAGE_COOLER = "KA_BEVERAGE_COOLER",
  WARMING_DRAWER = "KA_WARMING_DRAWER",
  TRASH_COMPACTOR = "KA_TRASH_COMPACTOR",
  ICE_MACHINE = "KA_ICE_MACHINE",
  MICROWAVE = "KA_MICROWAVE",
}
