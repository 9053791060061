import {
  ClickAwayListenerProps,
  Grid,
  Portal,
  Slide,
  Snackbar,
  SnackbarClasses,
  SnackbarCloseReason,
  SnackbarContentProps,
  SnackbarOrigin,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { ReactComponent as IconClose } from "assets/icons/buttons/close.svg";
import { ReactComponent as AlertIcon } from "assets/icons/snackbar/alert.svg";
import { ReactComponent as InformationIcon } from "assets/icons/snackbar/info.svg";
import { ReactComponent as SuccessIcon } from "assets/icons/snackbar/success.svg";
import { ReactComponent as WarningIcon } from "assets/icons/snackbar/warning.svg";
import clsx from "clsx";
import { useModalState } from "common/hooks/useModalState";
import { HBIconButton } from "components/HBIconButton/HBIconButton";
import { SECONDS } from "constants/time";
import {
  FC,
  FunctionComponent,
  ReactNode,
  SVGProps,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useDidUpdate } from "rooks";

import styles from "./HBSnackbar.module.scss";

export type SnackbarVariants =
  | "info"
  | "infoBlue"
  | "infoAlert"
  | "primaryDark"
  | "success"
  | "alert"
  | "warning";

const icons: Record<
  SnackbarVariants,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  info: InformationIcon,
  infoBlue: InformationIcon,
  infoAlert: InformationIcon,
  success: SuccessIcon,
  alert: AlertIcon,
  warning: WarningIcon,
  primaryDark: InformationIcon,
};

export const SNACKBAR_TRANSITION_DURATION = 0.3 * SECONDS;
const animationProps: TransitionProps = {
  easing: "ease-in-out",
  timeout: SNACKBAR_TRANSITION_DURATION,
};

interface Props {
  variant?: SnackbarVariants;
  size?: "standard" | "large";
  "data-testid"?: string;
  open: boolean;
  message?: string;
  icon?: ReactNode;
  closable?: boolean;
  position?: "bottom" | "top";
  autoHideDuration?: number;
  onClose?: (reason?: SnackbarCloseReason) => void;
  children?: ReactNode;
  action?: ReactNode;
  className?: string;
  rootClassName?: string;
  snackbarRootClassName?: string;
  iconClassName?: string;
  messageClassName?: string;
  containerClassName?: string;
  clickAwayListenerProps?: Partial<ClickAwayListenerProps>;
  showIcon?: boolean;
  closeOnOpenFalse?: boolean;
  portalContainerElement?: HTMLElement;
}

export const HBSnackbar: FC<Props> = ({
  variant = "info",
  size = "standard",
  "data-testid": DTI = "HBSnackbar",
  open,
  message,
  icon,
  closable = false,
  position = "bottom",
  autoHideDuration = 5000,
  onClose,
  children,
  action,
  className,
  rootClassName,
  snackbarRootClassName,
  iconClassName,
  containerClassName,
  messageClassName,
  clickAwayListenerProps,
  showIcon = true,
  closeOnOpenFalse = false,
  portalContainerElement,
}) => {
  const { t } = useTranslation();
  const [isOpen, { handleModalClose, handleModalOpen: handleOpen }] =
    useModalState(open);
  const handleClose = useCallback(
    (reason?: SnackbarCloseReason) => {
      handleModalClose();
      onClose?.(reason);
    },
    [handleModalClose, onClose]
  );
  useDidUpdate(() => {
    if (open) {
      handleOpen();
    } else if (closeOnOpenFalse) {
      handleClose();
    }
  }, [open, handleOpen, handleClose, closeOnOpenFalse]);
  const onSnackbarClose = useCallback(
    (_, reason: SnackbarCloseReason) => {
      handleClose(reason);
    },
    [handleClose]
  );

  const onClickClose = useCallback(() => {
    handleClose();
  }, [handleClose]);
  const contentProps: Partial<SnackbarContentProps> = useMemo(
    () => ({
      classes: {
        root: `${styles[variant]} ${styles[size]} ${rootClassName}`,
        message: styles.contentMessage,
      },
    }),
    [variant, size, rootClassName]
  );
  const classes: Partial<SnackbarClasses> = useMemo(
    () => ({ root: clsx(styles.root, snackbarRootClassName) }),
    [snackbarRootClassName]
  );

  const DefaultIcon = icons[variant];
  const anchorOrigin: SnackbarOrigin = useMemo(
    () => ({
      vertical: position,
      horizontal: "center",
    }),
    [position]
  );
  return (
    <Portal container={portalContainerElement}>
      <Snackbar
        className={clsx(styles.container, className)}
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={onSnackbarClose}
        anchorOrigin={anchorOrigin}
        TransitionComponent={Slide}
        TransitionProps={animationProps}
        ClickAwayListenerProps={clickAwayListenerProps}
        action={
          !action && closable ? (
            <HBIconButton
              className={styles.closeButton}
              onClick={onClickClose}
              title={t("Close")}
            >
              <IconClose />
            </HBIconButton>
          ) : (
            action
          )
        }
        message={
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            wrap="nowrap"
            className={clsx(styles.messageContainer, containerClassName)}
          >
            {showIcon && (
              <Grid item>
                {icon ?? <DefaultIcon className={iconClassName} />}
              </Grid>
            )}
            <Grid item className={styles.message}>
              {message && (
                <Typography
                  data-testid={`${DTI}__Snackbar--Message`}
                  variant="p"
                  component="p"
                  className={clsx(styles.paragraph, messageClassName)}
                >
                  {message}
                </Typography>
              )}
              {children && (
                <span data-testid={`${DTI}__Snackbar--Message`}>
                  {children}
                </span>
              )}
            </Grid>
          </Grid>
        }
        classes={classes}
        ContentProps={contentProps}
      />
    </Portal>
  );
};
