import { IS_DEV_ENV, IS_STAGING, KITCHEN_GUIDES_ENABLED } from "./env";
import { GuideItemTypeCategory } from "./guides/GuideItems.enums";
import { GuideType } from "./guides/GuideType";

export const ENABLED_GUIDES: Partial<Record<GuideType, boolean>> = {
  [GuideType.ELECTRICAL]: true,
  [GuideType.WINDOWS_AND_DOORS]: true,
  [GuideType.FLOOR_MATERIAL]: true,
  [GuideType.KITCHEN_SPECIFIC]: KITCHEN_GUIDES_ENABLED,
};

export const DISABLED_CATEGORIES: Partial<
  Record<GuideItemTypeCategory, boolean>
> = {
  [GuideItemTypeCategory.WINDOWS]: IS_DEV_ENV ? !IS_DEV_ENV : !IS_STAGING,
};
