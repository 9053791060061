import { Points } from "components/HBFloorPlan/HBFloorPlan.types";
import { GuideType } from "constants/guides/GuideType";
import { RoomType } from "constants/rooms/roomPieces";
import { FloorPlanItem } from "pages/Guides/components/FloorPlan/FloorPlanItems/FloorPlanItems.types";
import { RestrictPositionRuleFn } from "pages/Guides/components/FloorPlan/PixiFloorPlanItems/PixiFloorPlanItem/PixiFloorPlanItem.types";
import { GuideIntentType } from "pages/Guides/enums";
import { Coords, Size } from "pages/Guides/types";
import { ReactNode } from "react";
import { TFunction } from "react-i18next";
import { ImageItem } from "shared/types/ImageItem";
import { SvgIconComponent } from "shared/types/SvgIcon";

import { GuideItemsTypes } from "./GuideItems";
import { GuideItemTypeCategory } from "./GuideItems.enums";

export const isAllAllowed = (x: AllowedInRooms): x is AllAllowed => {
  return x === undefined;
};

export const isAllRestricted = (x: AllowedInRooms): x is AllRestricted => {
  return x?.length === 0;
};

// Note(pavel): add others when needed.
export type MeasurementUnit = "inch";

type AreaMeasurementUnit = "sqft";

interface Measurement<V extends number = number> {
  unit: MeasurementUnit;
  value: V;
}

export interface AreaMeasurement<V extends number = number> {
  unit: AreaMeasurementUnit;
  value: V;
}

export interface GuideItemDimensions {
  width?: Measurement;
  height?: Measurement;
  depth?: Measurement;
}

export enum PriceType {
  LABOR = "labor",
  MATERIAL = "material",
}

export type Price = Partial<{
  [PriceType.LABOR]: { amount: number; type: GuideLaborPriceType };
  [PriceType.MATERIAL]: { amount: number; type: GuideItemPriceType };
}>;

// Note(Andrei): We need to specify a key for data aggregation to track specific items.
// For example we need to store information about: Lighting Fixture, Recessed, Square, Standard, 6'
export type GuideItemCompositeKey = string;

export type GuideItemPriceType = "per_ft" | "per_sqft" | "per_item";
export type GuideLaborPriceType =
  | "per_ft"
  | "per_sqft"
  | "per_hour"
  | "per_item";

export type AllAllowed = undefined;
type AllRestricted = [];
export type AllowedInRooms = AllAllowed | AllRestricted | RoomType[];
export type AllowedIntents = AllAllowed | AllRestricted | GuideIntentType[];

export enum RuleType {
  STICK_TO_WALL = "stick_to_wall",
  STAY_INSIDE = "stay_inside",
}

export interface AbstractGuideItem {
  id: string;
  category: GuideItemTypeCategory;
  type: GuideItemsTypes;
  index: number;
  roomId?: string;
}

export interface GuideItem extends AbstractGuideItem {
  coords: Coords | undefined;
  dimensions?: GuideItemDimensions;
  realArea?: AreaMeasurement<number>;
  points?: Points;
  rotationRad?: number;
}

interface GuideItemTypeTextsConfig<Types extends string> {
  categoryName: (t: TFunction) => string;
  categoryDescription: (t: TFunction) => string;
  itemName: (t: TFunction) => string;
  itemNames: (t: TFunction) => Record<Types, string>;
  typeNames: (t: TFunction) => Record<Types, string>;
  typeDescriptions: ((t: TFunction) => Record<Types, string>) | undefined;
}

interface GuideItemTypeImagesConfig<Types extends string> {
  images: Record<Types, ImageItem>;
  defaultImage: ImageItem;
  categoryIcon: SvgIconComponent;
  icons?: Record<Types, SvgIconComponent>;
  modifiedIcons?: Record<string, SvgIconComponent>;
  symbols?: Record<Types, SvgIconComponent>;
  render?: (item: FloorPlanItem) => ReactNode;
  defaultIcon?: SvgIconComponent;
}

interface GuideItemTypeFloorPlanConfig<Types extends string> {
  sizes: Record<Types, Size>;
  rules?: Record<Types, RestrictPositionRuleFn>;
  ruleSets?: Record<Types, RuleType[]>;
}

export interface GuideItemTypeConfig<
  Category extends GuideItemTypeCategory,
  Types extends string
> {
  texts: GuideItemTypeTextsConfig<Types>;
  images: GuideItemTypeImagesConfig<Types>;
  floorPlan: GuideItemTypeFloorPlanConfig<Types>;
  guideType: GuideType;
  category: Category;
  types: Array<Types>;
  typesOrder: Record<Types, number>;
  allowedInRooms: AllowedInRooms;
  allowedIntents: AllowedIntents;
}
