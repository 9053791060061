import { useQuery } from "@tanstack/react-query";
import { getIntegrationResultById } from "api/homebase.api";
import { VENDOR_QUERIES } from "pages/Vendor/consts/VendorQueries.const";

export const useIntegrationResultById = (
  id: string | number,
  { enabled = true }: { enabled?: boolean } = {}
) => {
  return useQuery({
    queryKey: [VENDOR_QUERIES.INTEGRATION_RESULT_BY_ID, Number(id)],
    queryFn: () => getIntegrationResultById(id).then((res) => res.content),
    staleTime: Infinity,
    enabled: enabled && Boolean(id),
  });
};
