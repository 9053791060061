import { convertValueToFeetInches } from "components/HBFloorPlan/FloorPlan/components/FloorPlanMeasurements/utils/floorPlanMeasurements.utils";
import {
  OrchestratorGuideDoneEventData,
  OrchestratorPropertyContext,
} from "core/state/global/OrchestratorMachine/OrchestratorMachine.types";
import { GuideMachineId } from "pages/Guides/enums";
import { GuideContext } from "pages/Guides/GuidesMap.types";
import { RoomPlan } from "pages/RoomsAndSpaces/RoomsAndSpaces.types";
import { getFloorPlanAreaSizes } from "shared/floorPlan/utils/polygon.utils";

export const processAddRoomLayoutGuideDone = (
  oldContext: OrchestratorPropertyContext,
  doneData: OrchestratorGuideDoneEventData
): OrchestratorPropertyContext => {
  const { originalRoomId } = doneData;

  const result =
    doneData.result as GuideContext[GuideMachineId.ADD_ROOM_LAYOUT];

  return {
    ...oldContext,
    rooms: oldContext.rooms.map((room) => {
      if (room.id !== originalRoomId) {
        return room;
      }
      const sizes = getFloorPlanAreaSizes({
        wallMeasurements: result._wallMeasurementValues,
        points: result._originBaseShape,
      });

      const roomPlan: RoomPlan = {
        baseShape: result._baseShapeItem,
        wallMeasurements: result._wallMeasurementValues,
        originbaseShape: result._originBaseShape,
        size: sizes.size,
        realSize: sizes.realSize,
        // TODO(Andrei): We should probably add doows and windows from the CS stage
        // to a roomPlan object as well. Right now we're showing them only according
        // to the planning area guideResulsts information - meaning that we're not
        // showing them on a multiple hips.
        ceilingMeasurements: result._ceilingMeasurementValues?.map((value) => ({
          ...value,
          value: convertValueToFeetInches(value.value).feet,
        })),
      };
      return {
        ...room,
        roomPlan,
      };
    }),
  };
};
